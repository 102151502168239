.sec-installation {
  padding-bottom: 0;
  background-color: $pale-grey;
}

.installation {
  position: relative;
  &_btn {
    position: absolute;
    top: percentage(162/390);
    left: percentage(709/920);
    width: 40px;
    height: 40px;
    margin: 0;
    transform: translate(-50%,-50%);
    @include mq(md) {
      top: percentage(80/186);
      left: percentage(303/335);
    }
    a {
      display: block;
      height: 100%;
      transition: 0.2s;
      text-indent: -999em;
      border-radius: 50%;
      background-color: $linkColor;
      box-shadow: 0 2px 4px 0 rgba(0, 31, 45, 0.2);
      @include mq(min-md) {
        &:hover {
          border: 1px solid $very-light-blue;
          background-color: #fff;
          &::before,
          &::after {
            background-color: $linkColor;
          }
        }
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 14px;
        height: 2px;
        margin: auto;
        background-color: #fff;
      }
      &::after {
        transform: rotate(90deg);
      }
    }
  }
}

.remodal-overlay {
  .p-diagnosis_auto & {
    background: rgba($grey-blue,0.9);
  }
}

.remodalImg {
  max-width: 670px;
  margin: 60px 0 0;
  padding: 0;
  transform: none;
  text-align: left;
  background-color: transparent;
  &-wrapper {
    padding: 0px percentage(20/375) 0;
  }
  &-close {
    position: absolute;
    top: -50px;
    right: 0;
    left: auto;
    width: 40px;
    height: 40px;
    font-size: 1.4rem;
    transition: background-color 0.2s;
    text-indent: -999em;
    color: $baseColor;
    border: solid 1px $very-light-blue;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba($navy, 0.2);
    @include mq(min-md) {
      &:hover {
        background-color: $linkColor;
        &::before,
        &::after {
          background-color: #fff;
        }
      }
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 14px;
      height: 2px;
      margin: auto;
      transform: rotate(45deg);
      background-color: $linkColor;
    }
    &::after {
      transform: rotate(135deg);
    }
  }
  &_inr {
    position: relative;
    pointer-events: all;
  }
  &_panel {
    overflow: hidden;
    background-color: #fff;
  }
  &_head {
    display: flex;
    min-height: 50px;
    padding: 2px 20px;
    color: #fff;
    background-color: $grey-blue;
    align-items: center;
    .title {
      font-size: 2rem;
      font-weight: normal;
      line-height: 1.5;
    }
  }
  &_body {
    padding: 0 percentage(10/335) 30px;
  }
  &_foot {
    position: fixed;
    right: 0;
    bottom: 0px;
    left: 0;
    padding: 0 percentage(20/375);
  }
  &_footInr {
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 70px;
    margin: 0 auto;
    background-color: $pale-grey;
    align-items: center;
    justify-content: center;
  }
  .list-sort {
    &_item {
      width: 50%;
      min-height: 50px;
      border-bottom: 1px solid $pale-sky-blue;
      label {
        // display: flex;
        // align-items: center;
        display: flex;
        height: 100%;
        height: 100%;
        align-items: center;
      }
    }
  }
}

.diagnosisLead {
  text-align: center;
  + * {
    margin-top: 40px;
  }
}

.diagnosisFlow {
  margin-top: 40px;
  font-size: 1.6rem;
  color: $grey-blue;
  @include mq(md) {
    font-size: 1.2rem;
  }
  &_list {
    display: flex;
    width: 483px;
    max-width: 100%;
    margin: 0 auto;
    padding-left: 0;
    list-style: none;
    counter-reset: num;
    justify-content: space-between;
  }
  &_item {
    position: relative;
    // overflow: hidden;
    width: percentage(200/920);
    width: 6em;
    &::before {
      content: counter(num);
      position: relative;
      z-index: 10;
      display: flex;
      width: 50px;
      height: 50px;
      margin: 0 auto 10px;
      font-size: 2rem;
      counter-increment: num;
      color: #fff;
      border-radius: 50%;
      background-color: $grey-blue;
      align-items: center;
      justify-content: center;
    }
    &::after {
      content: "";
      position: absolute;
      z-index: 5;
      top: 24px;
      left: 50%;
      display: block;
      width: 100%;
      width: (483px/3);
      height: 2px;
      margin: auto;
      transform: translateX(-50%);
      background-color: $grey-blue;
    }
    &:first-child {
      &::after {
        transform: none;
      }
    }
    &:last-child {
      &::after {
        transform: translateX(-100%);
      }
    }
    &.-active,
    &.current {
      color: $turquoise-blue;
      &::before {
        background-color: $turquoise-blue;
      }
    }
  }
}

.diagnosisQ {
  &_ttl {
    position: relative;
    margin-bottom: 0;
    font-size: 2.8rem;
    line-height: 1.5;
    text-align: center;
    @include mq(md) {
      font-size: 2.4rem;
    }
    + * {
      margin-top: 30px;
    }
    &::before,
    &::after {
      content: "";
      display: block;
      width: 40px;
      height: 40px;
      margin: 0 auto 10px;
    }
    &::before {
      content: "Q";
      display: flex;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1;
      color: #fff;
      border-radius: 50%;
      background-color: #3c5058;
      align-items: center;
      justify-content: center;
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/diagnosis_auto/icon-balloon_diagnosis@2x.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
  .btnSet {
    &_list {
      @include mq(md) {
        flex-direction: column-reverse;
      }
    }
  }
}

.box-q1 {
  &_list {
    display: flex;
    margin: 0;
    padding-left: 0;
    font-size: 1.6rem;
    line-height: 1.8;
    list-style: none;
    @include mq(md) {
      font-size: 1.2rem;
    }
  }
  &_item {
    flex: 1;
    text-align: center;
  }
}

.box-q2 {
  $this: &;
  &_list {
    display: flex;
    max-width: 920px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    list-style: none;
    align-items: flex-end;
    @include mq(md) {
      max-width: 460px;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  &_item {
    flex: auto;
    text-align: center;
    @include mq(md) {
      width: 45%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    & + & {
      margin-left: 20px;
      @include mq(md) {
        margin-left: 0;
      }
    }
    &Name {
      max-width: 100%;
      margin-bottom: 10px;
      margin-left: auto;
      padding-right: percentage(43/192);
    }
    &:nth-child(1) {
      @include mq(md) {
        width: percentage(148/335);
      }
    }
    &:nth-child(2) {
      @include mq(md) {
        width: percentage(171/335);
        margin-left: percentage(16/335);
      }
      #{$this}_itemName {
        width: percentage(191/222);
        padding-right: percentage(55/222);
      }
    }
    &:nth-child(3) {
      @include mq(md) {
        width: percentage(176/335);
      }
      #{$this}_itemName {
        width: percentage(198/242);
        padding-right: percentage(55/242);
      }
    }
    &:nth-child(4) {
      @include mq(md) {
        width: percentage(143/335);
        margin-left: percentage(16/335);
      }
      #{$this}_itemName {
        width: percentage(197/197);
        padding-right: percentage(55/197);
      }
    }
  }
  &_tb {
    width: 100%;
    margin-top: 30px;
    font-size: 1.6rem;
    @include mq(md) {
      font-size: 1.4rem;
    }
    th {
      width: 20%;
      padding: 8px 17px;
      color: #fff;
      background-color: $grey-blue;
      @include mq(md) {
        padding: 10px 1px;
        font-weight: normal;
      }
    }
    td {
      padding: 20px 17px 15px;
      text-align: center;
      border-bottom: 1px solid $grey-blue;
      @include mq(md) {
        padding: 10px 0px;
      }
    }
    img {
      width: auto;
    }
  }
}

.box-q3 {
  &_list {
    display: flex;
    margin: 0;
    padding-left: 0;
    font-size: 1.6rem;
    line-height: 1.8;
    list-style: none;
    justify-content: space-between;
    @include mq(md) {
      flex-direction: column;
      font-size: 1.4rem;
    }
  }
  &_item {
    display: flex;
    flex-direction: column;
    width: percentage(440/920);
    text-align: center;
    @include mq(md) {
      width: 100%;
      & + & {
        margin-top: 20px;
      }
    }
    &Cap {
      margin-bottom: 25px;
      @include mq(md) {
        margin-bottom: 10px;
      }
    }
    &Img {
      display: flex;
      flex-direction: column;
      flex: auto;
      justify-content: center;
    }
  }
}

#-thanks------------------ {}

.sec-diagnosisAutoThanks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .container {
    height: 100%;
  }
}

.diagnosisAutoThanks {
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  &_img {
    width: 200px;
    margin: 0 auto 20px;
  }
  .ttl-section {
    &::before {
      // content: "";
      display: block;
      width: 50px;
      height: 50px;
      margin: 0 auto 10px;
      background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/diagnosis_auto/icon-akerun@2x.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
  .btnSet {
    margin-top: 30px;
  }
}