
// 製品
#-service_product_product------------------ {}

.sec-productFeatures,
.sec-productSupport {
  font-size: 1.6rem;
}

.main-header {
  .p-akerunpro &,
  .p-akerunctl & {
    position: relative;
    overflow: hidden;
    background-color: #d5e6ef;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 200px;
      display: block;
      width: percentage(760/1280);
      height: 300%;
      margin: auto;
      transition: width 0s;
      transform: rotate(-31deg);
      background-color: $pale-grey;
      @include mq(md) {
        right: auto;
        left: 10%;
      }
    }
    &.-animTarget {
      &.-ready {
        &::before {
          width: 0;
          @include mq(md) {
            left: 160%;
            width: 250%;
          }
        }
        .header-product {
          &_txt {
            .ttl-page {
              &,
              &_sub {
                opacity: 0;
              }
            }
          }
          &_img {
            &Akerunpro,
            &Akerunctl {
              &-1,
              &-2 {
                opacity: 0;
              }
            }
          }
        }
      }
      &.-action {
        &::before {
          width: percentage(760/1200);
          transition: width 1.2s 0.3s;
          @include mq(md) {
            left: 10%;
            width: 250%;
            transition: left 1.2s 0.3s;
          }
        }
        .header-product {
          &_txt {
            @for $i from 1 through 3 {
              *:nth-child(#{$i}) {
                animation: slideup 0.5s ease-out #{$i*0.2+0.5}s both;
              }
            }
          }
          &_img {
            &Akerunpro,
            &Akerunctl {
              &-1 {
                animation: fadein 2s ease-out 1.2s both;
              }
              &-2 {
                animation: fadein 2s ease-out 1.5s both;
              }
            }
          }
        }
      }
    }
  }
  .p-akerunctl & {
    background-color: #cfcfcf;
    &_panel {
      position: static;
      .header-product {
        position: static;
        @include mq(md) {
          position: relative;
          &::before {
            content: "";
            display: block;
            padding-top: percentage(524/335);
          }
        }
        &_txt {
          z-index: 10;
          top: percentage(122/460);
          width: percentage(352/1080);
          text-align: center;
          @include mq(md) {
            position: absolute;
            top: auto;
            right: 0;
            bottom: percentage(127/500);
            left: 0;
            width: percentage(264/335);
            margin-right: auto;
            margin-left: auto;
            padding: 0;
          }
          .ttl-page {
            font-size: 2rem;
            &_sub {
              font-weight: bold;
              @include mq(md) {
                font-size: 1.4rem;
              }
            }
          }
        }
        &_img {
          @include mq(md) {
            position: absolute;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.header-product {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include mq(md) {
    display: flex;
    flex-direction: column-reverse;
  }
  &_txt {
    position: absolute;
    // text-align-last: left;
    text-align: left;
    @include mq(md) {
      position: relative;
      padding: 11px 0 40px;
    }
    .ttl-page {
      &_sub {
        @include mq(md) {
          margin-top: 5px;
        }
      }
    }
  }
  &_img {
    padding-top: percentage(460/920);
    @include mq(md) {
      padding-top: percentage(395/335);
    }
    img {
      width: 100%;
    }
    &Akerunpro {
      &-1,
      &-2 {
        position: absolute;
      }
      &-1 {
        top: percentage(56/460);
        left: percentage(480/920);
        width: percentage(130/920);
        height: percentage(298/460);
        @include mq(md) {
          top: percentage(92/460);
          left: percentage(31/335);
          width: percentage(103/335);
          height: percentage(234/460);
        }
      }
      &-2 {
        top: percentage(10/460);
        left: percentage(670/920);
        width: percentage(165/920);
        height: percentage(446/460);
        @include mq(md) {
          top: percentage(44/460);
          left: percentage(173/335);
          width: percentage(130/335);
          height: percentage(351/460);
        }
      }
    }
    &Akerunctl {
      &-ttl {
        width: 100%;
      }
      &-1,
      &-2 {
        position: absolute;
        img {
          width: auto;
          height: 100%;
          @include mq(md) {
            width: 100%;
            height: auto;
          }
        }
      }
      &-1 {
        top: percentage(40/460);
        left: percentage(454/920);
        // width: percentage(334/1080);
        height: percentage(382/460);
        @include mq(md) {
          top: percentage(50/460);
          right: 0;
          left: percentage(45/335);
          left: 0;
          width: percentage(224/335);
          height: auto;
          margin: auto;
        }
      }
      &-2 {
        bottom: 0;
        left: 0;
        // width: percentage(410/920);
        height: percentage(280/460);
        @include mq(md) {
          right: 0;
          bottom: 3%;
          left: 0;
          width: 56%;
          height: auto;
          margin: auto;
        }
      }
    
    }
  }
}

.ttl-product {
  $this: &;
  + * {
    margin-top: 50px;
  }
  &_en,
  &_ja {
    display: block;
    text-align: center;
  }
  &_en {
    display: flex;
    font-size: 3.2rem;
    align-items: center;
    justify-content: center;
    line-height: 1.1;
    @include mq(md) {
      font-size: 2.8rem;
    }
    &::before,
    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 0.625em;
      margin: 0 25px;
      background-color: $linkColor;
    }
    &::before {
      margin-left: 0;
    }
    &::after {
      margin-right: 0;
    }
  }
  &_ja {
    margin-top: 12px;
    font-size: 1.9rem;
    line-height: 1.5;
    @include mq(md) {
      font-size: 1.8rem;
    }
  }
  &.-animTarget {
    &.-ready {
      #{$this}_ja,
      #{$this}_en {
        opacity: 0;
        &::before,
        &::after {
          opacity: 0;
        }
      }
    }
    &.-action {
      #{$this}_en {
        animation: slideup 0.5s ease-out 0.5s both;
        &::before,
        &::after {
          animation: fadein 1s ease 1s both;
        }
      }
      #{$this}_ja {
        animation: slideup 0.5s ease-out 0.7s both;
      }
    }
  }
}

.sec-productFeatures {
  padding-top: 20px;
}

.productFeatures {
  $this: &;
  &_list {
    margin-left: -20px;
  }
  &_panel,
  &_detail,
  &_figure {
    padding-left: 20px;
  }
  &_panel {
    position: relative;
    background-color: #d5e6ef;
  }
  &_item {
    margin-top: 180px;
    @include mq(md) {
      margin-top: 90px;
    }
    & + & {
      margin-top: 180px;
      @include mq(md) {
        margin-top: 75px;
      }
    }
    &Inr {
      position: relative;
      z-index: 1;
      display: flex;
      min-height: 346px;
      justify-content: space-between;
      @include mq(md) {
        display: block;
        padding-bottom: percentage(40/335);
      }
    }
    &Num {
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 100px;
      border-radius: 10px;
      background-color: $navy;
      @include mq(md) {
        width: 75px;
        height: 75px;
      }
      &,
      &:first-child {
        margin: -50px 0 0;
        @include mq(md) {
          margin-top: -37.5px;
        }
      }
    }
    &Img {
      position: relative;
      width: 50%;
      @include mq(md) {
        width: auto;
      }
      &::after {
        content: "";
        display: block;
        padding-top: percentage(368/460);
        @include mq(md) {
          content: none;
        }
      }
      &.-bottom {
        img {
          top: auto;
          max-height: 90%;
        }
      }
      &.-center {
        img {
          max-height: 82%;
        }
      }
      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // visibility: hidden;
        width: auto;
        // max-height: percentage(520/346);
        max-height: 130%;
        margin: auto;
        @include mq(md) {
          position: static;
          display: block;
          width: 85%;
          max-height: 100%;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
    &Txt {
      position: relative;
      display: flex;
      flex-direction: column;
      width: percentage(400/920);
      padding: 70px 0 20px;
      line-height: 1.8;
      justify-content: center;
      @include mq(md) {
        width: auto;
        margin-top: 20px;
      }
    }
    &Ttl {
      margin: 0;
      font-size: 2.8rem;
      line-height: 1.5;
      @include mq(md) {
        font-size: 2.4rem;
      }
      + * {
        margin-top: 20px;
      }
    }
    &Slash {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      display: block;
      overflow: hidden;
      width: 100%;
      height: 100%;
      pointer-events: none;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 50%;
        bottom: 0;
        left: auto;
        display: block;
        width: 100%;
        height: 700%;
        margin: auto;
        transform: rotate(-31deg);
        background-color: #f4f7f9;
      }
    }
    .btn {
      margin-top: 1.5em;
      justify-content: flex-start;
    }
    &:nth-child(even) {
      #{$this}_panel {
        background-color: #e4e4e4;
      }
      #{$this}_itemInr {
        flex-direction: row-reverse;
      }
      #{$this}_itemSlash {
        &::before {
          right: auto;
          bottom: 0;
          left: 50%;
          display: block;
          transform: rotate(31deg);
          background-color: #f7f7f7;
        }
      }
      &.-animTarget {
        &.-ready {
          #{$this}_itemSlash {
            &::before {
              left: 120%;
              @include mq(md) {
                left: 200%;
              }
            }
          }
        }
        &.-action {
          #{$this}_itemSlash {
            &::before {
              left: 50%;
            }
          }
        }
      }
    }
    &.-animTarget {
      &.-ready {
        #{$this}_itemImg img,
        #{$this}_itemNum,
        #{$this}_itemTtl,
        #{$this}_itemTxt p:not([class]) {
          opacity: 0;
        }
        #{$this}_itemSlash {
          &::before {
            right: 120%;
            transition: 0.4s ease-out 0.2s;
            @include mq(md) {
              right: 200%;
              transition: 0.8s ease-out 0.2s;
            }
          }
        }
      }
      &.-action {
        #{$this}_itemImg img {
          animation: fadein 2s ease-out 0.9s both;
        }
        #{$this}_itemNum {
          animation: fadein 0.5s ease-out 0.2s both;
        }
        #{$this}_itemTtl {
          // animation: slideup 0.5s ease-out 0.5s both;
        }
        #{$this}_itemTxt p:not([class]) {
          // animation: slideup 0.4s ease-out 0.8s both;
        }
        #{$this}_itemTxt {
          @for $i from 2 through 5 {
            *:nth-child(#{$i}) {
              animation: slideup 0.5s ease-out #{$i*0.2+0.3}s both;
            }
          }
        }
        #{$this}_itemSlash {
          &::before {
            right: 50%;
          }
        }
      }
    }
  }
  &.-grid1 & {
    &_item {
      display: block;
      &Img {
        width: auto;
      }
      &Txt {
        width: auto;
        margin-top: 20px;
      }
    }
  }
  &_detail {
    margin-top: 70px;
    @include mq(md) {
      margin-top: 30px;
    }
    &List {
    }
    &Item {
      display: flex;
      align-items: center;
      @include mq(md) {
        display: block;
      }
      & + #{$this}_detailItem {
        margin-top: 30px;
      }
    }
    &Img {
      width: percentage(260/920);
      @include mq(md) {
        width: auto;
      }
    }
    &Txt {
      width: percentage(630/920);
      margin-left: percentage(30/920);
      @include mq(md) {
        width: auto;
        margin-top: 24px;
        margin-left: 0;
      }
    }
    &Ttl {
      margin-bottom: 0;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.5;
      + * {
        margin-top: 1em;
      }
    }
    &.-animTarget {
      &.-ready {
        #{$this}_detailItem {
          opacity: 0;
        }
      }
      &.-action {
        #{$this}_detailItem {
          @for $i from 1 through 9 {
            &:nth-child(#{$i}) {
              animation: slideup 0.5s ease-out #{($i - 1)*0.3+0.1}s both;
            }
          }
        }
      }
    }
  }
  &_figure {
    padding-top: 30px;
    background-image: linear-gradient(to bottom, #f4f7f9, #fff);
    @include mq(md) {
      background: #fff;
    }
    &Img {
      text-align: center;
      img {
        width: auto;
      }
    }
    &.-animTarget {
      &.-ready {
        #{$this}_figureImg {
          picture {
            opacity: 0;
          }
        }
      }
      &.-action {
        #{$this}_figureImg {
          picture {
            animation: fadein 2s ease-out 0.3s both;
          }
        }
      }
    }
  }
}

.sec-product {
  padding-top: 60px;
  // padding-bottom: 100px;
  @include mq(md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}  

.productSupport {
  $this: &;
  // margin-top: 60px;
  &.-animTarget {
    &.-ready {
      #{$this}_item {
        opacity: 0;
      }
    }
    &.-action {
      #{$this}_item {
        @for $i from 1 through 9 {
          &:nth-child(#{$i}) {
            animation: slideup 0.5s ease-out #{$i*0.5+0.5}s both;
          }
        }
      }
    }
  }
  &_ttl {
    font-size: 3.2rem;
    text-align: center;
  }
  &_wrap {
  }
  &_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -14px -28px;
    @include mq(md) {
      display: block;
    }
  }
  &_item {
    width: percentage(1/3);
    padding: 0 14px 28px;
    @include mq(md) {
      width: auto;
    }
    &Img {
      img {
        width: 100%;
      }
    }
    &Txt {
      margin-top: 15px;
    }
    &Ttl {
      margin-bottom: 0;
      font-size: 1.6rem;
      font-weight: bold;
      + * {
        margin-top: 6px;
      }
    }
  }
}

.productSpec {
  margin-top: 200px;
  @include mq(md) {
    margin-top: 150px;
  }
  &_wrap {
    display: flex;
    justify-content: space-between;
    @include mq(md) {
      display: block;
    }
    & + & {
      margin-top: 80px;
    }
  }
  &_img {
    width: percentage(185/838);
    text-align: center;
    @include mq(md) {
      width: percentage(140/335);
      margin: 0 auto;
    }
  }
  &_txt {
    width: percentage(606/838);
    @include mq(md) {
      width: auto;
      margin-top: 40px;
    }
  }
  &_name {
    margin-bottom: 0;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.5;
    @include mq(md) {
      font-size: 3rem;
    }
    + * {
      margin-top: 10px;
    }
  }
  &_tabel {
    width: 100%;
    border-top: 1px solid $pale-sky-blue;
    &,
    tbody,
    tr,
    th,
    td {
      @include mq(md) {
        display: block;
      }
    }
    tr {
      @include mq(md) {
        padding: 10px 0;
        border-bottom: 1px solid $pale-sky-blue;
      }
    }
    th,
    td {
      padding: 5px 0;
      font-size: 1.2rem;
      text-align: left;
      vertical-align: top;
      vertical-align: middle;
      border-width: 1px;
      border-style: none none solid;
      border-color: $pale-sky-blue;
      @include mq(md) {
        padding: 0;
        border-style: none;
      }
    }
    th {
      padding-right: 1em;
      font-weight: normal;
      line-height: 1.5;
      color: $grey-blue;
      @include mq(md) {
        padding-left: 0;
      }
    }
    td {
      width: percentage(470/606);
      line-height: 1.5;
      @include mq(md) {
        width: auto;
        margin-top: 5px;
      }
    }
  }
}

.sec-productRelated {
  padding-top: 80px;
  background-color: $pale-grey;
}