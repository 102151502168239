// ▼ Marketoのフォーム共通で使えそうなもの

.sec-marketo{
  // padding-bottom: 100px;
  background-color: $pale-grey;
  // @include mq(md) { padding-bottom: 80px;}
  &.-white {
    background-color: #fff;
  }
}

.form-marketo_inr{
  max-width: 768px;
  margin-right: auto;
  margin-left: auto;
}

.mktoForm{
  padding: 0!important;
  // .mktoFormCol, .mktoFieldWrap, .mktoLabel, .mktoGutter{
  //   float: none;
  // }
  >style{display: none;}
  *{float: none!important;}
  .mktoAsterix{display: none!important;}
  .mktoOffset{display: none!important;}
  // .mktoHtmlText{display: none!important;}
  .mktoGutter{display: none!important;}
  .mktoLabel{padding-top: 0;}
  // style+.mktoFormRow{display: none;}
  > .mktoFormRow {
    .mktoError{
      position: relative;
      right: auto!important;
      bottom: auto!important;
      .mktoErrorArrowWrap{display: none;}
      .mktoErrorMsg{
        max-width: none;
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        box-shadow: none;
        text-shadow: none;
      }
    }//reset
  }
}

.mktoForm{
  >.mktoFormRow{
    &:not(:last-child){
      margin-bottom: 20px;
    }
    //   &+.mktoFormRow{
    //     margin-top: 20px;
    //   }
    >.mktoFormCol{
      &:not(:first-child){
        margin-top: 25px;
      }
    }
    .mktoFieldWrap{
      position: relative;
      &.mktoRequiredField{
        >label{
          &:before{
            content: '必須';
            display: inline-block;
            width: 44px;
            margin-right: 1em;
            font-size: 1.2rem;
            line-height: 16px;
            text-align: center;
            vertical-align: middle;
            color:#fff;
            border-radius: 2px;
            background: #ff5757 0% 0% no-repeat padding-box;
            background-color: #ff5757;
          }
        }
      }
      >label{
        position: relative;
        display: block;
        margin-bottom: .64em;
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 1;
      }
    }
    input[type="text"],input[type="tel"],input[type="email"],input[type="url"],input[type="date"],[type="range"],select,textarea{
      box-sizing: border-box;
      width: 100%;
      height: auto!important;
      margin: 0!important;
      padding: 0;
      padding: 0 1em;
      line-height: 43px;
      border: 1px solid #d0e0e7;
      border-radius: 4px;
      background: #fff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 8px #00000005;
      @include mq(md) {
        font-size: 1.6rem;
      }
    }
    input[type="range"] {
      overflow: hidden;
      // width: 100%;
      // height: 5px !important;
      padding: 0;
      border: 1px solid #e4edf1;
      border-radius: 30px;
      outline: none;
      background-color: #e4edf1;
      appearance: none;
      &::-webkit-slider-thumb {
        position: relative;
        display: block;
        width: 22px;
        height: 22px;
        cursor: pointer;
        border: 2px solid $linkColor;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: -1000px 0 0 989px $linkColor;
        appearance: none;
      }
      &::range-progress {
        height: 5px;
        background-color: $linkColor;
      }
    }
    .mktoRangeField {
      margin-top: 2.5em;
      .mktoRangeValue {
        @include mq(md) {
          display: block;
        }
      }
      .mktoRangeValueText {
        display: flex;
        flex-direction: column;
        width: 32px;
        height: 27px;
        padding: 0.2em 0.3em;
        border-style: none;
        border-radius: 4px;
        background: $linkColor;
        box-shadow: 0px 2px 3px rgba(#000,0.2);
        text-shadow: none;
        align-items: center;
        justify-content: center;
      }
      .mktoRangeValueArrow {
        border-style: none;
        background-color: $linkColor;
      }
    }
    .mktoRadioList,
    .mktoCheckboxList {
      $this: &;
      margin: 1.2em -2em -1.5em;
      padding: 0;
      font-size: 1.4rem;
      line-height: 1.2;
      @include mq(md) {
        margin-right: -0.8em;
        margin-left: -0.8em;
      }
      > label {
        position: relative;
        display: inline-block;
        margin: 0em 2em 1.5em;
        padding-left: 21px;
        @include mq(md) {
          margin-right: 0.8em;
          margin-left: 0.8em;
        }
        @include mq(min-md) {
          &:hover {
            &::before {
              border-color: $linkColor;
            }
          }
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
        &::before {
          content: "";
          display: block;
          width: 16px;
          min-width: 16px;
          height: 16px;
          margin-right: 0.5em;
          transition: 0.1s;
          border: 1px solid $pale-sky-blue;
          border-radius: 4px;
          background-color: #fff;
        }
        &::after {
          top: 0px;
          bottom: 2px;
          left: 6px;
          display: block;
          width: 4.5px;
          height: 6.5px;
          transition: opacity 0.1s;
          transform: translate(0.5em,-0.15em) rotate(45deg);
          transform: translate(7px,-2px) rotate(45deg);
          transform: rotate(45deg);
          opacity: 0;
          border-width: 2px;
          border-style: none solid solid none;
          border-color: #fff;
        }
      }
      .mktoField {
        display: none;
        &:checked {
          + label {
            &::before {
              border-color: $linkColor;
              background-color: $linkColor;
            }
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
    .mktoRadioList {
      > label {
        padding-left: 28px;
        &::before {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
        &::after {
          left: 8px;
        }
      }
    }
    .mktoDateField {
      position: relative;
      min-height: 43px;
      background-color: #fff;
      appearance: none;
    }
    .mktoDateButton {
      right: 10px;
      bottom: 0;
    }
    .mktoFieldDescriptor {
      .mktoTextField {
        min-width: 100%;
      }
    }
    select.mktoField {
      min-width: 100%;
      min-height: 45px;
      padding: 0 2em 0 1em;
      appearance: none;
      background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/download/arw-select@2x.png);
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 14px 14px;
      @include mq(md) {
        font-size: 1.6rem;
      }
      &::-ms-expand {
        display: none;
      }
    }
    textarea.mktoField {
      padding: 1em 1em;
      line-height: 1.8;
      @include mq(md) {
        padding: 0.5em 0.8em;
        font-size: 1.6rem;
      }
    }
    .mktoError{
      .mktoErrorMsg{
        margin-top: 5px;
        font-size: 1.2rem;
        line-height: 1.5;
        color: #ff5757;
      }
    }
  }
  .mktoHtmlText {
    a {
      &[target="_blank"], &[target="_new"] {
        font-weight: normal;
        text-decoration: none;
        color: $navy !important;
        &:hover {
          text-decoration: underline;
        }
        &::after {
          content: "";
          display: inline-block;
          width: 1em;
          height: 1em;
          margin-left: 0.5em;
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/icon-blank_bk@2x.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
      }
    }
  }
  > .mktoFormRow {
    .mktoFieldWrap.mktoRequiredField {
      > label[id$="consent_to_privacy_policy"] {
        text-align: center;
        justify-content: center;
        &::before {
          content: none;
          margin-right: 0;
        }
        > div {
          text-align: center !important;
        }
        &.mktoLabel {
          display: none;
        }
        + .mktoHasWidth {
          + .mktoCheckboxList {
            margin-right: 0;
            margin-left: 0;
            text-align: center;
            &::after {
              content: "同意する";
              vertical-align: top;
            }
            > label[id$="consent_to_privacy_policy"] {
              margin: 0.2em -4.5em 30px 0;
              padding-right: 5em;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
  // 送信ボタン
  >.mktoButtonRow{
    display: block;
    margin-top: 40px;
    .mktoButtonWrap.mktoArrowButton,
    .mktoButtonWrap.mktoSimple {
      @include btn(grad);
      margin-left: 0!important;
      .mktoButton {
        width: 330px;
        max-width: 100%;
        height: 60px;
        font-size: 1.4rem;
        &:hover {
          border-style: none;
        }
      }
    }
  }
  .fb-submit{
    position: relative;
    z-index: 0;
    display: block;
    overflow: hidden;
    margin-top: 21px;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.4rem;
    cursor: pointer;
    text-align: left;
    text-indent: -999em;
    color:#fff;
    border-radius: 30px;
    background: transparent linear-gradient(90deg, #00c1cd 0%, #0092d1 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #001f2d33;
    @include mq(min-md) {
      &:hover {
        color: #fff;
        &::after {
          opacity: 1;
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.8s;
      opacity: 0;
      background: linear-gradient(to left, #00c1cd 0%, #0092d1 100%);
      background: linear-gradient(to right, #4db3df 0%, #4db3df 100%);
    }
    i {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;
      font-size: 1.4rem;
      font-style: normal;
      line-height: 1.5;
      text-align: center;
      align-items: center;
      justify-content: center;
      &::before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        margin-right: 0.5em;
        text-indent: 0;
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/btn-quote@2x.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
      &::after {
        content: "送信する";
        text-indent: 0;
      }
    }
  }//?????????????????????  
}
