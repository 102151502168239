.sec-c-thanks{
  background-color: $pale-grey;
}

.c-thanks{
  &_img{
    text-align: center;
    img{
      width: auto;
      max-height: 44px;
    }
  }
}