
// -------------------- sec-seminar --------------------
.sec-seminar {
  background: #f3f7f9;

  &.detail {
    padding-top: 130px;
    padding-bottom: 0px;

    @include mq(md) {
      padding-top: 27px;

      .container { margin: 0;}
    }
  }

  .seminar {
    // ---------- seminar__lead ----------
    &__lead {
      font-size: 2rem;

      @include mq(md) { font-size: 1.8rem;}

      + .seminar__list { margin-top: 40px;}
    }

    // ---------- seminar__list ----------
    &__list {
      &.colm02 {
        display: flex;
        flex-wrap: wrap;

        @include mq(md) { display: block;}

        .seminar__list-block {
          width: calc(50% - 15px);

          @include mq(md) {
            width: 100%;

            &:not(:first-child) { margin-top: 20px;}
          }

          &:nth-of-type(even) { margin-left: auto;}

          &:nth-of-type(2) {
            margin-top: 0;

            @include mq(md) { margin-top: 20px;}
          }

          &:nth-of-type(n + 3) {
            margin-top: 30px;

            @include mq(md) { margin-top: 20px;}
          }
        }

        .seminar__list-block_contents {
          .text {
            width: calc(100% - (123px + 20px));
            margin-left: 20px;

            @include mq(md) {
              width: 50%;
              margin-left: 20px;
            }
          }
        }
      }

      // ----- seminar__list-block -----
      &-block {
        display: flex;
        flex-direction: column;
        padding: 30px;
        background: white;

        @include mq(md) { padding: 20px;}

        + .seminar__list-block { margin-top: 20px;}

        // ----- seminar__list-block_contents -----
        &_contents {
          display: flex;
          margin: auto 0;
          align-items: center;

          &.-info {
            @include mq(md) {
              display: block;

              .pic { width: 100%;}

              .text { margin-top: 20px;}
            }

            + .-info {
              margin-top: 20px;
              align-items: center;
            }
          }

          .pic {
            width: 123px;

            @include mq(md) { width: 40%;}
          }

          .text {
            width: calc(100% - (123px + 30px));
            margin-left: 30px;

            @include mq(md) {
              width: 100%;
              margin-left: 0;
            }

            .batch {
              display: flex;

              @include mq(md) { margin-bottom: 5px;}

              p {
                margin: 0;
                padding: 0 10px;
                font-size: 1.2rem;
                line-height: 22px;
                color: #0092d1;
                background: #f3f7f9;

                + p { margin-left: 10px;}

                &.end {
                  color: #001f2d;
                  background: #e4edf1;
                }
              }
            }

            h2 {
              margin: 0;
              font-size: 2rem;

              @include mq(md) { font-size: 1.8rem;}
            }

            .copy {
              margin-top: 5px;

              @include mq(md) { margin-top: 3px;}
            }
          }

          .info {
            font-size: 1.4rem;

            dl {
              display: flex;
              margin: 0;

              + dl { margin-top: 5px;}

              dt {
                width: 40px;
                font-weight: bold;
              }

              dd {
                margin-left: 0;
                color: #698591;

                @include mq(md) { width: calc(100% - 40px);}
              }
            }

            + .seminar__list-btn {
              max-width: 160px;
              margin-top: 0;
              margin-left: auto;

              @include mq(md) {
                width: auto;
                margin-top: 30px;
                margin-right: auto;
              }
            }
          }
        }
      }

      &-btn {
        margin-top: 30px;

        @include mq(md) { margin-top: 20px;}

        a {
          position: relative;
          z-index: 0;
          display: flex;
          overflow: hidden;
          min-width: 160px;
          max-width: 100%;
          min-height: 40px;
          padding: 3px 20px;
          line-height: 1.2;
          cursor: pointer;
          transition: box-shadow 0.5s 0.3s;
          text-decoration: none;
          color: #fff;
          border-style: none;
          border-radius: 30px;
          outline: none;
          background-color: #0092d1;
          box-shadow: 0px 2px 4px 1px rgba(0, 31, 45, 0.2);
          justify-content: center;
          align-items: center;

          @include mq(min-md) {
            &:hover {
              color: #0092d1;
              box-shadow: 0px 2px 10px 1px rgba(0, 31, 45, 0.3);

              &::before {
                width: 100%;
                padding-top: 100%;
                background-color: #fff;
              }
            }
          }

          &[href=""] {
            pointer-events: none;
            background-color: $grey-blue;
          }

          &::before {
            content: "";
            position: absolute;
            z-index: -1;
            width: 0;
            height: 0;
            transition: 0.3s;
            border-radius: 50%;
            background-color: #0092d1;
          }
        }
      }
    }

    // ---------- seminar__detail ----------
    &__detail {
      padding: 70px 76px;
      background: white;

      @include mq(md) { padding: 0 0 100px;}

      &-pic {
        margin-bottom: 40px;

        @include mq(md) { margin-bottom: 20px;}
      }

      &-batch {
        display: flex;

        @include mq(md) {
          margin-right: 20px;
          margin-left: 20px;
        }

        p {
          margin: 0;
          padding-right: 10px;
          padding-left: 10px;
          font-size: 1.2rem;
          line-height: 22px;
          color: #0092d1;
          background: #f3f7f9;

          + p { margin-left: 10px;}

          &.end { color: #001f2d;}
        }
      }

      &-tit {
        margin-top: 5px;
        font-size: 3.2rem;
        font-weight: 500;
        line-height: 1.5;

        @include mq(md) {
          margin-right: 20px;
          margin-left: 20px;
          font-size: 2.4rem;
        }
      }

      &-body {
        margin-top: 40px;

        @include mq(md) {
          margin-top: 30px;
          margin-right: 20px;
          margin-left: 20px;
        }

        blockquote {
          display: flex;
          flex-wrap: wrap;
          margin: 20px 0 50px;
          padding: percentage(20/768) percentage(30/768);
          background-color: $pale-grey;
          @include mq(md) {
            padding: percentage(20/335) percentage(20/335) percentage(10/335);
          }

          p {
            margin: 0;

            @include mq(md) { margin-bottom: 10px;}

            &:nth-of-type(odd) { width: 40px;}
            &:nth-of-type(even) { width: calc(100% - 40px);}
          }
        }

        h2 {
          margin-top: 60px;
          padding-bottom: 0.4em;
          font-size: 2rem;
          font-weight: bold;
          border-bottom: 2px solid $grey-blue;
          @include mq(md) {
            margin-top: 40px;
          }
          + * {
            margin-top: 1em;
          }
        }

        h3 {
          margin-top: 1em;
          margin-bottom: 0;
          font-size: 2rem;

          + *:not(p) {
            margin-top: 1em;
          }

          + p { margin-top: 0;}
        }
      }
    }
  }
}

.breadcrumb.seminar {
  background: #698591;
}

.seminarMore{
  &__ttl{
    font-size: 2rem;
    text-align: center;
  }
  .seminar__list-block{
    padding: 0!important;
  }
  a{
    display: block;
    padding: 30px;
    transition: .5s all;
    text-decoration: none;
    color:$baseColor;
    border-radius: 10px;
    box-shadow: 0px 0px 6px #001f2d4d;

    @include mq(md) { padding: 20px;}
    &:hover{
      // box-shadow: none;
    }
  }
  .seminar{
    &__list {
      &.colm02 {
        display: flex;
        flex-wrap: wrap;
    
        @include mq(md) { display: block;}
    
        .seminar__list-block {
          width: calc(50% - 15px);
    
          @include mq(md) {
            width: 100%;
    
            &:not(:first-child) { margin-top: 20px;}
          }
    
          &:nth-of-type(even) { margin-left: auto;}
    
          &:nth-of-type(2) {
            margin-top: 0;
    
            @include mq(md) { margin-top: 20px;}
          }
    
          &:nth-of-type(n + 3) {
            margin-top: 30px;
    
            @include mq(md) { margin-top: 20px;}
          }
        }
    
        .seminar__list-block_contents {
          .text {
            width: calc(100% - (123px + 20px));
            margin-left: 20px;
    
            @include mq(md) {
              width: 50%;
              margin-left: 20px;
            }
          }
        }
      }
    
      // ----- seminar__list-block -----
      &-block {
        padding: 30px;
        background: white;
    
        @include mq(md) { padding: 20px;}
    
        + .seminar__list-block { margin-top: 20px;}
    
        // ----- seminar__list-block_contents -----
        &_contents {
          display: flex;
          align-items: center;
    
          &.-info {
            @include mq(md) {
              display: block;
    
              .pic { width: 100%;}
    
              .text { margin-top: 20px;}
            }
    
            + .-info {
              margin-top: 20px;
              align-items: center;
            }
          }
    
          .pic {
            width: 123px;
    
            @include mq(md) { width: 40%;}
          }
    
          .text {
            width: calc(100% - (123px + 30px));
            margin-left: 30px;
    
            @include mq(md) {
              width: 100%;
              margin-left: 0;
            }
    
            .batch {
              display: flex;
    
              @include mq(md) { margin-bottom: 5px;}
    
              p {
                margin: 0;
                padding: 0 10px;
                font-size: 1.2rem;
                line-height: 22px;
                color: #0092d1;
                background: #f3f7f9;
    
                + p { margin-left: 10px;}
    
                &.end {
                  color: #001f2d;
                  background: #e4edf1;
                }
              }
            }
    
            h2 {
              margin: 0;
              font-size: 2rem;
    
              @include mq(md) { font-size: 1.8rem;}
            }
    
            .copy {
              margin-top: 5px;
    
              @include mq(md) { margin-top: 3px;}
            }
          }
    
          .info {
            font-size: 1.4rem;
    
            dl {
              display: flex;
              margin: 0;
    
              + dl { margin-top: 5px;}
    
              dt {
                width: 40px;
                font-weight: bold;
              }
    
              dd {
                margin-left: 0;
                color: #698591;
    
                @include mq(md) { width: calc(100% - 40px);}
              }
            }
    
            + .seminar__list-btn {
              max-width: 160px;
              margin-top: 0;
              margin-left: auto;
    
              @include mq(md) {
                width: auto;
                margin-top: 30px;
                margin-right: auto;
              }
            }
          }
        }
      }
    
      &-btn {
        margin-top: 30px;
    
        @include mq(md) { margin-top: 20px;}
    
        a {
          position: relative;
          z-index: 0;
          display: flex;
          overflow: hidden;
          min-width: 160px;
          max-width: 100%;
          min-height: 40px;
          padding: 3px 20px;
          line-height: 1.2;
          cursor: pointer;
          transition: box-shadow 0.5s 0.3s;
          text-decoration: none;
          color: #fff;
          border-style: none;
          border-radius: 30px;
          outline: none;
          background-color: #0092d1;
          box-shadow: 0px 2px 4px 1px rgba(0, 31, 45, 0.2);
          justify-content: center;
          align-items: center;
    
          @include mq(min-md) {
            &:hover {
              color: #0092d1;
              box-shadow: 0px 2px 10px 1px rgba(0, 31, 45, 0.3);
    
              &::before {
                width: 100%;
                padding-top: 100%;
                background-color: #fff;
              }
            }
          }
    
          &::before {
            content: "";
            position: absolute;
            z-index: -1;
            width: 0;
            height: 0;
            transition: 0.3s;
            border-radius: 50%;
            background-color: #0092d1;
          }
        }
      }
    }
  }
}
