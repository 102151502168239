.sec-contactTop {
  background-color: $pale-grey;
}

.contactTop {
  &_wrap {
    // overflow: hidden;
  }
  &_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -30px;
  }
  &_item {
    width: percentage(1/2);
    padding: 0 15px 30px;
    @include mq(md) {
      width: 100%;
    }
    &Inr {
      display: flex;
      flex-direction: column;
      min-height: 292px;
      padding: 0px 20px 42px;
      text-align: center;
      background-color: #fff;
      @include mq(md) {
        min-height: auto;
      }
    }
    &Body {
      flex: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: percentage(20/405) 0;
      @include mq(md) {
        padding: 20px 0;
      }
    }
    &Foot {
      display: block;
    }
    &Icon {
      img {
        width: percentage(100/420);
        @include mq(md) {
          width: percentage(100/295);
        }
      }
    }
    &Ttl {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 2rem;
      @include mq(md) {
        font-size: 1.8rem;
      }
      + * {
        margin-top: 20px;
        @include mq(md) {
          margin-top: 15px;
        }
      }
    }
    .btn {
      // @include btn(grad);
      a {
        width: 295px;
      }
    }
  }
}
