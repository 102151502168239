@charset "UTF-8";
/*------------------------------------------------------*/
/*  既存ページから5511のスタイルを移行
/*------------------------------------------------------*/
#lp-5511 {
  overflow-x: auto;
  min-width: 980px;

  .akerun_lp_template {
    #text_title {
      font-family: "NotoSansJp-Light";
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      color: #414556;
    }
    .sub-heading {
      margin-bottom: 100px;
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      color: #414556;
    }
    .sub-heading {
      margin-bottom: 90px;
      font-size: 40px;
      font-weight: bold;
      line-height: 1;
      text-align: center;
      color: #414556;

      span {
        margin-left: 35%;
        font-size: 1vw;
        line-height: 2.4;
        color: #414556;
      }
    }
    .link-button {
      display: inline-block;
      padding: 16px 40px;
      font-family: "NotoSansJp-Light";
      font-size: 22px;
      font-weight: normal;
      line-height: 1;
      transition: 0.2s;
      text-decoration: none;
      letter-spacing: 3px;
      color: #fff;
      border: 1px solid $linkColor;
      border-radius: 100px;
      background: $linkColor;

      &:hover {
        color: $linkColor;
        background: #fff;
      }
    }
    .under_line {
      display: inline-block;
      margin-bottom: 150px;
      border-bottom: solid 3px #414556;
    }

    &.hero2 {
      overflow: hidden;
      margin-top: 0;

      .hero-main {
        background-color: #f2f7fb;
        background-image: url(/wp-content/themes/akerun/_assets/images/landing/5511/main_visual_bg.png);
        background-repeat: no-repeat;
        background-position: top center;

        .hero_inner {
          position: relative;
          width: 980px;
          height: 865px;
          margin: 0 auto;

          .hero_right_column {
            position: absolute;
            top: 115px;
            right: 0;

            .hero_logos {
              margin-top: 37px;
              text-align: center;

              .hero_logo {
                display: inline-block;
                width: 148px;
                margin-right: 20px;
              }
            }

            .hero_text {
              font-size: 28px;
              font-weight: 400;
              line-height: 1.9;
              letter-spacing: 3px;
              color: #fff;
            }
          }

          .hero_left_column {
            position: absolute;
            top: 0;
            bottom: unset;
            left: -50px;
            width: 63%;
            margin-top: 50px;
          }

          .form-link {
            position: static;
            bottom: 0;
            left: 0;
            width: 100%;
            padding-top: 510px;
            text-align: center;
          }
        }
      }
    }

    &.introduction {
      position: relative;
      box-sizing: content-box;
      width: 730px;
      min-width: 730px;
      margin-top: 0;
      padding: 50px 114px;
      border: 1px solid #1e73be;

      .heading {
        font-family: "NotoSansJp-Light";
      }

      .image {
        position: static;
        right: 0px;
        width: 250px;
        margin: -30px 0 30px 0;
      }

      .text-list {

        position: absolute;
        top: 170px;
        right: 80px;
        margin-top: 0;
        .item {
          margin-bottom: 25px;
          padding-left: 3.2em;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.75;
          color: #414556;
          background: url(/wp-content/themes/akerun/_assets/images/landing/5511/icn_check2.svg)
            left center no-repeat;
          background-size: 2em;
        }
      }

      .heading {
        margin-bottom: 100px;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        color: #414556;
      }

      .image2 {
        position: absolute;
        top: 220px;
        right: 100px;
        width: 350px;
      }

      .text-list2 {
        top: 140px;
        right: 80px;
        margin-top: 0;

        .item {
          margin-bottom: 25px;
          padding-left: 3.2em;
          font-size: 16px;
          font-weight: 500;
          line-height: 2.5;
          color: #414556;
          background: url(/wp-content/themes/akerun/_assets/images/landing/5511/icn_check2_blue.svg)
            left center no-repeat;
          background-position-y: 7px;
          background-size: 2em;
        }
      }
    }

    &.request {
      min-width: 980px;
      max-width: 1920px;
      margin: 80px auto;
      padding: 86px 40px 63px;
      text-align: center;
      background: url(/wp-content/themes/akerun/_assets/images/landing/5511/obi_bg.jpg)
        center 90% no-repeat;
      background-size: cover;

      .main {
        margin-bottom: 55px;

        a {
          margin-bottom: 55px;
        }

        .texts {
          display: inline-block;
          width: 510px;
          vertical-align: top;
        }
      }

      .textwidget .form-link {
        position: static;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;

        a {
          font-size: 22px;
          font-weight: normal;
          transition: 0.2s;

          &:hover {
            font-size: 22px;
            font-weight: normal;
            transition: 0.2s;
          }
        }
      }
    }

    &.achievements {
      padding-top: 80px;
      text-align: center;

      .data-list {
        margin-top: 70px;
        margin-bottom: 100px;

        .item {
          display: inline-block;
          margin-right: 40px;

          &:first-child {
            width: 216px;
          }

          &:nth-child(2) {
            width: 230px;
          }

          &:last-child {
            width: 251px;
            margin-right: 0;
          }
        }
      }
    }

    &.solution {
      padding-top: 160px;
      text-align: center;

      .heading {
        margin-bottom: 80px;
        padding-bottom: 80px;
        font-family: "NotoSansJp-Light";
        font-size: 40px;
        color: #414556;
        background: none;
        background-size: 100px;
      }

      .solution-item {
        display: flex;
        margin-bottom: 66px;
        text-align: left;
        align-items: center;

        .texts {
          width: 500px;
          padding-left: 40px;
          background: url(/wp-content/themes/akerun/_assets/images/landing/5511/icn_check.svg)
            left 10px no-repeat;
          background-size: 25px;

          .title {
            margin-bottom: 29px;
            font-size: 28px;
          }

          .description {
            font-size: 15px;
            line-height: 2.3;
          }
        }

        &.left-text {
          margin-left: 100px;

          .texts {
            width: 500px;
            padding-left: 40px;
          }

          .image {
            width: 350px;
            text-align: center;

            .margin-left {
              width: 272px;
              margin-left: -50px;
            }
          }
        }

        &.right-text {
          margin-left: 140px;

          .texts {
            width: 410px;
            padding-left: 40px;
          }

          .image {
            width: 330px;
            margin-right: 100px;
          }
        }
      }
    }

    &.form {
      max-width: 1280px;
      margin: 80px auto;
      padding-top: 80px;
      text-align: center;

      .heading {
        margin-bottom: 80px;
        padding: 20px 0;
        font-size: 20px;
        font-weight: bold;
        color: white;
        background: url(/wp-content/themes/akerun/_assets/images/landing/5511/obi_bg.jpg)
          center 90% no-repeat;
      }
    }
  }

  #pgc-5511-2-0 {
    background-color: #f2f7fb;
  }

  #pg-5511-0,
  #pg-5511-1,
  #pg-5511-2,
  #pg-5511-3,
  #pg-5511-4,
  #pg-5511-5,
  #pg-5511-6,
  #pg-5511-7,
  #pg-5511-8,
  #pg-5511-9,
  #pg-5511-11,
  #pg-5511-12,
  #pg-5511-13,
  #pg-5511-14,
  #pg-5511-15,
  #pl-5511 .panel-grid-cell .so-panel {
    margin-bottom: 30px;
  }

  #pg-5511-0 .panel-grid-cell,
  #pg-5511-1 .panel-grid-cell,
  #pg-5511-2 .panel-grid-cell,
  #pg-5511-3 .panel-grid-cell,
  #pg-5511-4 .panel-grid-cell,
  #pg-5511-5 .panel-grid-cell,
  #pg-5511-6 .panel-grid-cell,
  #pg-5511-7 .panel-grid-cell,
  #pg-5511-8 .panel-grid-cell,
  #pg-5511-9 .panel-grid-cell,
  #pg-5511-11 .panel-grid-cell,
  #pg-5511-12 .panel-grid-cell,
  #pg-5511-13 .panel-grid-cell,
  #pg-5511-14 .panel-grid-cell,
  #pg-5511-15 .panel-grid-cell,
  #pg-5511-16 .panel-grid-cell {
    float: none;
  }

  #pl-5511 .panel-grid-cell .so-panel:last-child {
    margin-bottom: 0px;
  }

  #pg-5511-0,
  #pg-5511-1,
  #pg-5511-2,
  #pg-5511-3,
  #pg-5511-4,
  #pg-5511-5,
  #pg-5511-6,
  #pg-5511-7,
  #pg-5511-8,
  #pg-5511-9,
  #pg-5511-11,
  #pg-5511-12,
  #pg-5511-13,
  #pg-5511-14,
  #pg-5511-15,
  #pg-5511-16 {
    margin-right: -15px;
    margin-left: -15px;
  }

  #pg-5511-0 .panel-grid-cell,
  #pg-5511-1 .panel-grid-cell,
  #pg-5511-2 .panel-grid-cell,
  #pg-5511-3 .panel-grid-cell,
  #pg-5511-4 .panel-grid-cell,
  #pg-5511-5 .panel-grid-cell,
  #pg-5511-6 .panel-grid-cell,
  #pg-5511-7 .panel-grid-cell,
  #pg-5511-8 .panel-grid-cell,
  #pg-5511-9 .panel-grid-cell,
  #pg-5511-11 .panel-grid-cell,
  #pg-5511-12 .panel-grid-cell,
  #pg-5511-13 .panel-grid-cell,
  #pg-5511-14 .panel-grid-cell,
  #pg-5511-15 .panel-grid-cell,
  #pg-5511-16 .panel-grid-cell {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 768px) {
  #lp-5511 {
    overflow-x: hidden;
    min-width: 100%;
    .akerun_lp_template {
      #text_title {
        font-size: 4.5vw;
        font-weight: bold;
        text-align: center;
        color: #414556;
      }

      .sub-heading {
        margin: 50px 0;
        font-size: 5vw;
        text-align: center;

        span {
          font-size: 4vw;
        }
      }
      .under_line {
        display: inline-block;
        margin-bottom: 80px;
        border-bottom: solid 2px #414556;
      }
      .link-button {
        font-size: 4vw;
      }

      &.hero2 {
        .hero-main {
          padding-bottom: 20%;
          background-size: cover;

          .hero_inner {
            width: 100%;
            height: 100%;
            padding: 40px 4% 0;

            .hero_right_column {
              .hero_logos .hero_logo {
                width: 120px;
              }

              .hero_text {
                font-size: 5.5vw;
                font-weight: bold;
                line-height: 1em;
                text-align: center;
                letter-spacing: 3px;
                color: #fff;
              }
            }

            .hero_left_column {
              position: static;
              width: 120%;
              margin-top: -30px;
              margin-left: -10%;
              padding-bottom: 25%;
            }

            .hero_right_column {
              position: static;
              top: 0;
              right: 0;
              .hero_logos {
                min-width: 320px;
                margin-top: 20px;
                text-align: center;
              }
            }

            .form-link {
              position: static;
              bottom: 0;
              left: 0;
              margin-top: -70px;
              padding-top: 0;
              text-align: center;

              a {
                font-size: 4vw;
                font-weight: normal;
                transition: 0.2s;

                &:hover {
                  font-size: 4vw;
                  font-weight: normal;
                  transition: 0.2s;
                }
              }
            }
          }
        }
      }
      &.introduction {
        box-sizing: border-box;
        width: 95%;
        min-width: auto;
        margin: 0 10px;
        padding: 10px 4%;
        border: 1px solid #1e73be;

        .text-list {
          position: static;
          margin-top: 30px;
          margin-right: 0;
          margin-bottom: 30px;

          .item {
            margin-bottom: 25px;
            padding-left: 2.2em;
            font-size: 3.5vw;
            font-weight: 500;
            line-height: 1.75;
            color: #414556;
            background: url(/wp-content/themes/akerun/_assets/images/landing/5511/icn_check2.svg)
              left center no-repeat;
            background-position: inherit;
            background-size: 1.8em;
          }
        }

        .heading {
          margin: 50px 0;
          font-size: 5vw;
          text-align: center;
        }

        .image {
          position: static;
          width: 50%;
          margin: auto;
        }

        .image2 {
          position: static;
          width: 300px;
          margin: auto;
        }

        .text-list2 .item {
          font-size: 3.5vw;
          background-position-y: 4px;
        }
      }
      &.achievements {
        margin-top: 0;

        .data-list {
          margin-top: 70px;
          margin-bottom: 0;

          .item {
            width: 80% !important;
            margin-right: 0;
            margin-bottom: 60px;
            &:nth-child(2) {
              width: 220px;
            }

            &:last-child {
              width: 300px;
              margin-right: 0;
            }
          }
        }
      }

      &.request {
        box-sizing: border-box;
        min-width: auto;
        margin: 40px 0 0;
        padding: 60px 4% 50px;

        .main {
          margin-bottom: 30px;

          .texts {
            width: auto;
          }
        }

        .textwidget .form-link a {
          padding: 16px 40px;
          font-size: 4vw;
          font-weight: normal;
          transition: 0.2s;

          &:hover {
            font-size: 4vw;
            font-weight: normal;
            transition: 0.2s;
          }
        }
      }

      &.solution {
        padding-top: 80px;
        .heading {
          margin-top: -40px;
          margin-bottom: 40px;
          padding-bottom: 40px;
          font-size: 5.5vw;
          font-weight: bold;
          color: #414556;
          background: none;
          background-size: 100px;
        }

        .solution-item {
          flex-direction: column;
          margin-bottom: 80px;
          margin-left: 0;

          .texts {
            padding-bottom: 40px;
            background-position-y: 5px;
            background-size: 20px;

            .title {
              font-size: 5vw;
            }

            .description {
              font-size: 3vw;
              line-height: 3;
            }
          }

          &.left-text {
            margin-left: 0;

            .texts {
              width: 100%;
            }

            .image .margin-left {
              margin-left: 0;
            }
          }

          &.right-text {
            margin-left: 0;

            .texts {
              width: 100%;
            }

            .image {
              order: 2;
              margin: 0;
            }
          }
        }
      }

      &.form {
        .heading {
          margin-bottom: 40px;
          font-family: "NotoSansJp-Light";
          font-size: 4.5vw;
        }
      }
    }
    #pgc-5511-2-0 {
      margin-top: 0;
      padding: 20px;
      background-color: #f2f7fb;
    }
  }
}
