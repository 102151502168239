// ▼ Kintoneのフォーム共通で使えそうなもの

.ui.stackable.grid {
  >.row {
    &:not(:first-child){
      margin-top: 20px;
    }
    > .column {
      @include mq(md) {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
  }
}


.sec-kintone {
  background-color: $pale-grey;
}  

.form-kintone {
  &_lead {
    text-align: center;
    + * {
      margin-top: 40px;
    }
  }
  &_explain {
    font-size: 1.6rem;
    text-align: center;
    + * {
      margin-top: 40px;
    }
  }
}

.form-kintone_inr{
  max-width: 768px;
  margin-right: auto;
  margin-left: auto;
  .fb-title{display: none;}
  .el-form-item{
    width: 100%!important;
    margin-top: 20px;
  }  
  .ql-editor{
    text-align: left;
    .ql-align-center {
      text-align: center;
    }
    .ql-align-right {
      text-align: right;
    }
    .ql-align-justify {
      text-align: justify;
    }
    a{
      font-size: 1.4rem;
      text-decoration: none;
      color:$baseColor;
      &[target="_blank"], &[target="_new"] {
        &::after {
          content: "";
          display: inline-block;
          width: 1em;
          height: 1.2rem;
          margin-left: 0.5em;
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/icon-blank_bk@2x.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
      }
      &[href="https://photosynth.co.jp/privacy/"] {
        display: block;
        width: fit-content;
        margin: 0 auto;
        text-align: center;
      }
      u{
        text-decoration: none;
        &:hover{text-decoration: underline;}
      }
    }//?????????????
    img {
      width: auto;
    }
  }
  .help-content{display: none;}
  // 送信ボタン（テキストの書き換え含む「回答」→「送信する」）
  .fb-submit{
    position: relative;
    z-index: 0;
    display: block;
    overflow: hidden;
    width: 330px;
    max-width: 100%;
    height: 60px;
    margin-top: 21px;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.4rem;
    cursor: pointer;
    text-align: left;
    text-indent: -999em;
    color:#fff;
    border-radius: 30px;
    background: transparent linear-gradient(90deg, #00c1cd 0%, #0092d1 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #001f2d33;
    @include mq(min-md) {
      &:hover {
        color: #fff;
        &::after {
          opacity: 1;
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.8s;
      opacity: 0;
      background: linear-gradient(to left, #00c1cd 0%, #0092d1 100%);
      background: linear-gradient(to right, #4db3df 0%, #4db3df 100%);
    }
    i {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;
      font-size: 1.4rem;
      font-style: normal;
      line-height: 1.5;
      text-align: center;
      align-items: center;
      justify-content: center;
      &::before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        margin-right: 0.5em;
        text-indent: 0;
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/btn-quote@2x.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
      &::after {
        content: "送信する";
        text-indent: 0;
      }
    }
  }//?????????????????????
}

// ラベルや入力項目
.ui.form {
  &::after {
    content: "";
    display: block;
    float: none;
    clear: both;
  }
  .el-form-item{
    // width: 100%!important;
    &.required{
      >label{
        &:before{
          content: '必須';
          display: inline-block;
          width: 44px;
          margin-right: 1em;
          font-size: 1.2rem;
          line-height: 16px;
          text-align: center;
          vertical-align: middle;
          color:#fff;
          border-radius: 2px;
          background: #ff5757 0% 0% no-repeat padding-box;
          background-color: #ff5757;
        }
        &::after {
          content: none !important;
        }
      }
    }
    &.field {
      >label{
        position: relative;
        display: block;
        // margin-bottom: .64em;
        font-size: 1.4rem;
        line-height: 1.5;
        + * {
          margin-top: 0.64em;
          @include mq(md) {
            margin-top: 5px;
          }
        }
      }
    }
    >div{
      width: 100%!important;
      .el-input {
        // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.02);
      }
    }
    hr {
      display: none;
    }
  }
}

.ui.form {
  font-size: 1.4rem;
  text-align: center;
  .field {
    text-align: left;
  }
  input[type="text"],input[type="tel"],input[type="email"],textarea{
    box-sizing: border-box;
    width: 100%;
    margin: 0!important;
    padding: 0;
    padding: 0 1em;
    line-height: 43px;
    border: 1px solid #d0e0e7;
    border-radius: 4px;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #00000005;
  }
  .el-form-item__error{
    margin-top: 5px;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #ff5757;
  }
  .help-content {
    margin-top: 5px;
    font-size: 1.2rem;
    color: $navy;
  }
  .field>label {
    font-size: 1.4rem;
    font-weight: normal;
  }
}

// セレクトボックス
.el-select {
  .el-input {
    position: relative;
    &::after {
      // content: "";
      position: absolute;
      top: 0;
      right: 15px;
      bottom: 0;
      display: block;
      width: 1em;
      height: 1em;
      margin: auto;
      pointer-events: none;
      background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/arw-select_b@2x.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
    &__suffix {
      position: absolute;
      top: 0;
      right: 10px;
      width: 24px;
      height: 100%;
      transition: transform 0.2s;
      pointer-events: none;
    }
    .el-select__caret {
      &,
      &.is-reverse {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 8px;
        height: 8px;
        margin: auto;
        transform: translateY(-1px) rotate(45deg);
        border-width: 2px;
        border-style: none solid solid none;
        border-color: $linkColor;
        &::before,
        &::after {
          content: none;
        }
      }
    }
    &.is-focus {
      .el-input__suffix {
        transform: rotate(-180deg);
      }
    }
  }
}

// セレクトボックスのドロップダウン
.el-select-dropdown {
  position: absolute;
  z-index: 1001;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 5px 0;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  @include mq(md) {
    width: 280px;
    margin: 5px 15px;
  }
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #409eff;
  background-color: #fff;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
  background-color: #f5f7fa;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected:after {
  content: "\E6DA";
  position: absolute;
  right: 20px;
  font-family: element-icons;
  font-size: 12px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-select-dropdown .el-scrollbar.is-empty .el-select-dropdown__list {
  padding: 0;
}

.el-scrollbar__wrap {
  // overflow-y: scroll;
  overflow: auto;
}

.el-select-dropdown__empty {
  margin: 0;
  padding: 10px 0;
  font-size: 14px;
  text-align: center;
  color: #999;
}

.el-select-dropdown__wrap {
  max-height: 274px;
  margin: 0 !important;
}

.el-select-dropdown__list {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px 0;
  list-style: none;
}

.el-select-dropdown__item {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 34px;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 34px;
  line-height: 1.5;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #606266;
  > span {
    // display: block;
    // line-height: 1.5;
  }
}

.el-select-dropdown__item, .el-select .el-tag, .el-table {
  -webkit-box-sizing: border-box;
}

.el-select-dropdown__item.is-disabled {
  cursor: not-allowed;
  color: #c0c4cc;
}

.el-select-dropdown__item.is-disabled:hover {
  background-color: #fff;
}

.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  background-color: #f5f7fa;
}

.el-select-dropdown__item.selected {
  font-weight: 700;
  color: #409eff;
}


// チェックボックス
.el-checkbox {
  $this: &;
  display: flex;
  width: fit-content;
  font-size: 1.2rem;
  line-height: 1.2;
  @include mq(min-md) {
    &:hover {
      #{$this}__input {
        &::before {
          border-color: $linkColor;
        }
      }
    }
  }
  &__inner {
    display: none;
  }
  &__input {
    position: relative;
    display: inline-block;
    height: 16px;
    padding-left: 21px;
    vertical-align: middle;
    body .ui.form & {
      position: relative;
      margin-top: 0;
    }
    input {
      display: none;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
    &::before {
      content: "";
      display: block;
      width: 16px;
      min-width: 16px;
      height: 16px;
      margin-right: 0.5em;
      transition: 0.1s;
      border: 1px solid $pale-sky-blue;
      border-radius: 4px;
      background-color: #fff;
    }
    &::after {
      top: 0px;
      bottom: 2px;
      left: 6px;
      display: block;
      width: 4.5px;
      height: 6.5px;
      transition: opacity 0.1s;
      transform: translate(0.5em,-0.15em) rotate(45deg);
      transform: translate(7px,-2px) rotate(45deg);
      transform: rotate(45deg);
      opacity: 0;
      border-width: 2px;
      border-style: none solid solid none;
      border-color: #fff;
    }
    &.is-checked {
      &::before {
        border-color: $linkColor;
        background-color: $linkColor;
      }
      &::after {
        opacity: 1;
      }
    }
  }
  &__label {
    padding-left: 0;
    line-height: inherit;
    body .ui.form & {
      margin-left: 0;
    }
  }
}

// 数値入力
.el-input-number {
  display: flex;
  align-items: center;
  justify-content: center;
  &__decrease,
  &__increase {
    position: relative;
    display: block;
    width: 45px;
    height: 45px;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
    background-color: $linkColor;
    flex-shrink: 0;
    box-shadow: 0 2px 4px 0 rgba($navy, 0.2);
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 12px;
      height: 2px;
      margin: auto;
      background-color: #fff;
    }
    &::after {
      transform: rotate(90deg);
    }
    i {
      display: none;
    }
  }
  &__decrease {
    order: 1;
    &::after {
      content: none;
    }
  }
  &__increase {
    order: 3;
  }
  .el-input {
    order: 2;
    margin: 0 10px;
    &__inner {
      text-align: center;
    }
  }
}

// ファイルアップロード

.el-upload {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  outline: 0;
  .el-button {
    display: flex;
    overflow: hidden;
    min-width: 120px;
    max-width: 100%;
    min-height: 30px;
    padding: 3px 10px;
    line-height: 1.2;
    cursor: pointer;
    transition: box-shadow 0.5s 0.3s;
    text-decoration: none;
    color: $navy;
    border-style: none;
    border-radius: 30px;
    outline: none;
    background-color: #fff;
    box-shadow: 0px 2px 4px 1px rgba($navy, 0.2);
    justify-content: center;
    align-items: center;
    @include mq(min-md) {
      &:hover {
        box-shadow: 0px 2px 10px 1px rgba($navy, 0.3);
      }
    }
  }
}

.el-upload__input {
  display: none;
}

.el-upload__tip {
  margin-top: 7px;
  font-size: 12px;
  color: #606266;
}

.el-upload iframe {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity = 0);
}

.el-upload--picture-card {
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  line-height: 146px;
  vertical-align: top;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  background-color: #fbfdff;
  i {
    font-size: 28px;
    color: #8c939d;
  }
  &:hover {
    color: #409eff;
    border-color: #409eff;
  }
}

.el-upload:focus {
  color: #409eff;
  border-color: #409eff;
  .el-upload-dragger {
    border-color: #409eff;
  }
}

.el-upload-dragger {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 360px;
  height: 180px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  .el-icon-upload {
    margin: 40px 0 16px;
    font-size: 67px;
    line-height: 50px;
    color: #c0c4cc;
  }
  + .el-upload__tip {
    text-align: center;
  }
  ~ .el-upload__files {
    margin-top: 7px;
    padding-top: 5px;
    border-top: 1px solid #dcdfe6;
  }
  .el-upload__text {
    font-size: 14px;
    text-align: center;
    color: #606266;
    em {
      font-style: normal;
      color: #409eff;
    }
  }
  &:hover {
    border-color: #409eff;
  }
  &.is-dragover {
    border: 2px dashed #409eff;
    background-color: rgba(32, 159, 255, 0.06);
  }
}

.el-upload-list {
  width: 300px;
  max-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.el-upload-list__item {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.8;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  color: #606266;
  border-radius: 4px;
  .el-progress {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .el-progress__text {
    position: absolute;
    top: -13px;
    right: 0;
  }
  .el-progress-bar {
    margin-right: 0;
    padding-right: 0;
  }
  &:first-child {
    margin-top: 10px;
  }
  .el-icon-upload-success,
  .el-icon-close {
    position: absolute;
    top: 0px;
    right: 5px;
    bottom: 0;
    display: block;
    width: 16px;
    height: 16px;
    margin: auto;
  }
  .el-icon-upload-success {
    display: block;
    border: 1px solid $linkColor;
    border-radius: 50%;
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 3px;
      left: 1px;
      display: block;
      width: 4.5px;
      height: 6.5px;
      margin: auto;
      transition: opacity 0.1s;
      transform: rotate(45deg);
      border-width: 2px;
      border-style: none solid solid none;
      border-color: $linkColor;
    }
  }
  .el-icon-close {
    display: none;
    cursor: pointer;
    opacity: .5;
    color: #606266;
    &:hover {
      opacity: 1;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 85%;
      height: 1px;
      margin: auto;
      background-color: $navy;
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }
  .el-icon-close-tip {
    position: absolute;
    top: 5px;
    right: 5px;
    display: none;
    font-size: 12px;
    cursor: pointer;
    opacity: 1;
    color: #409eff;
  }
  &:hover {
    background-color: #fff;
    .el-icon-close {
      display: inline-block;
    }
    .el-progress__text {
      display: none;
    }
  }
  &.is-success {
    .el-upload-list__item-status-label {
      display: block;
    }
    .el-upload-list__item-name {
      &:focus, &:hover {
        cursor: pointer;
        color: #409eff;
      }
    }
    &:focus:not(:hover) .el-icon-close-tip {
      display: inline-block;
    }
    &:active .el-icon-close-tip, &:focus .el-upload-list__item-status-label, &:hover .el-upload-list__item-status-label, &:not(.focusing):focus .el-icon-close-tip {
      display: none;
    }
  }
}

.el-upload-list.is-disabled .el-upload-list__item:hover .el-upload-list__item-status-label {
  display: block;
}

.el-upload-list__item-name {
  display: block;
  overflow: hidden;
  margin-right: 40px;
  padding-left: 4px;
  -webkit-transition: color .3s;
  transition: color .3s;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #606266;
  [class^=el-icon] {
    height: 100%;
    margin-right: 7px;
    line-height: inherit;
    color: #909399;
  }
}

.el-upload-list__item-status-label {
  // position: absolute;
  // top: 0;
  // right: 5px;
  display: none;
  line-height: inherit;
}

.el-upload-list__item-delete {
  position: absolute;
  top: 0;
  right: 10px;
  display: none;
  font-size: 12px;
  color: #606266;
  &:hover {
    color: #409eff;
  }
}

.el-upload-list--picture-card {
  display: inline;
  margin: 0;
  vertical-align: top;
  .el-upload-list__item {
    display: inline-block;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    background-color: #fff;
    .el-icon-check, .el-icon-circle-check {
      color: #fff;
    }
    .el-icon-close {
      display: none;
    }
    &:hover {
      .el-upload-list__item-status-label {
        display: none;
      }
      .el-progress__text {
        display: block;
      }
    }
  }

  .el-upload-list__item-name {
    display: none;
  }

  .el-upload-list__item-thumbnail {
    width: 100%;
    height: 100%;
  }

  .el-upload-list__item-status-label {
    position: absolute;
    top: -6px;
    right: -15px;
    width: 40px;
    height: 24px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    text-align: center;
    background: #13ce66;
    -webkit-box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
    i {
      margin-top: 11px;
      font-size: 12px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  .el-upload-list__item-actions {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 20px;
    cursor: default;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    text-align: center;
    opacity: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    &:after {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    span {
      display: none;
      cursor: pointer;
      + span {
        margin-left: 15px;
      }
    }
    .el-upload-list__item-delete {
      position: static;
      font-size: inherit;
      color: inherit;
    }
    &:hover {
      opacity: 1;
      span {
        display: inline-block;
      }
    }
  }

  .el-progress {
    top: 50%;
    bottom: auto;
    left: 50%;
    width: 126px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    .el-progress__text {
      top: 50%;
    }
  }
}

.el-upload-list--picture {
  .el-upload-list__item {
    z-index: 0;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 92px;
    margin-top: 10px;
    padding: 10px 10px 10px 90px;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    background-color: #fff;
    .el-icon-check, .el-icon-circle-check {
      color: #fff;
    }
    &:hover {
      .el-upload-list__item-status-label {
        top: -2px;
        right: -12px;
        background: 0 0;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      .el-progress__text {
        display: block;
      }
    }
    &.is-success .el-upload-list__item-name {
      margin-top: 0;
      line-height: 70px;
      i {
        display: none;
      }
    }
  }

  .el-upload-list__item-thumbnail {
    position: relative;
    z-index: 1;
    display: inline-block;
    float: left;
    width: 70px;
    height: 70px;
    margin-left: -80px;
    vertical-align: middle;
    background-color: #fff;
  }

  .el-upload-list__item-name {
    display: block;
    margin-top: 20px;
    i {
      position: absolute;
      top: 10px;
      left: 9px;
      font-size: 70px;
      line-height: 1;
    }
  }

  .el-upload-list__item-status-label {
    position: absolute;
    top: -7px;
    right: -17px;
    width: 46px;
    height: 26px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    text-align: center;
    background: #13ce66;
    -webkit-box-shadow: 0 1px 1px #ccc;
    box-shadow: 0 1px 1px #ccc;
    i {
      margin-top: 12px;
      font-size: 12px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  .el-progress {
    position: relative;
    top: -7px;
  }
}

.el-upload-cover {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: default;
  &:after {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.el-upload-cover__label {
  position: absolute;
  top: -6px;
  right: -15px;
  width: 40px;
  height: 24px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  text-align: center;
  background: #13ce66;
  -webkit-box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
  i {
    margin-top: 11px;
    font-size: 12px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    color: #fff;
  }
}

.el-upload-cover__progress {
  position: static;
  display: inline-block;
  width: 243px;
  vertical-align: middle;
  + .el-upload__inner {
    opacity: 0;
  }
}

.el-upload-cover__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.el-upload-cover__interact {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.72);
  .btn {
    display: inline-block;
    margin-top: 60px;
    font-size: 14px;
    cursor: pointer;
    -webkit-transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    vertical-align: middle;
    color: #fff;
    span {
      -webkit-transition: opacity .15s linear;
      transition: opacity .15s linear;
      opacity: 0;
    }
    &:not(:first-child) {
      margin-left: 35px;
    }
    &:hover {
      -webkit-transform: translateY(-13px);
      transform: translateY(-13px);
      span {
        opacity: 1;
      }
    }
    i {
      display: block;
      margin: 0 auto 5px;
      font-size: 24px;
      line-height: inherit;
      color: #fff;
    }
  }
}

.el-upload-cover__title {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 36px;
  margin: 0;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #303133;
  background-color: #fff;
}

.el-upload-cover + .el-upload__inner {
  position: relative;
  z-index: 1;
  opacity: 0;
}

// 同意するボタン
body .ui.form {
  [data-vv-name="PRIVACY"] {
    justify-content: center;
    .field {
      width: 216px;
      margin: 0 auto;
    }
    .el-checkbox {
      position: relative;
      display: flex;
      width: 100%;
      min-height: 40px;
      font-size: 1.4rem;
      transition: 0.2s;
      border: 1px solid $pale-sky-blue;
      border-radius: 50px;
      background-color: #fff;
      align-items: center;
      justify-content: center;
      @include mq(min-md) {
        &:hover {
          color: $linkColor;
        }
      }
      &__input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        width: 20px;
        height: 20px;
        margin: auto;
        &::before {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        &::after {
          left: 8px;
        }
      }
    }
    + .el-form-item__error {
      margin: 0 0 20px;
      text-align: center;
    }
  }
}

// 確認ページ
.ui.raised.segment {
  padding: 0;
  box-shadow: none;
  .ui.celled.grid {
    > * {
      padding-right: 0;
      padding-left: 0;
    }
    h3 {
      width: 100%;
      padding-bottom: 8px;
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.5;
      border-bottom: 2px solid $grey-blue;
    }
    .w100 {
      font-size: 1.6rem;
      line-height: 1.8;
    }
    >.row {
      font-size: 1.4rem;
      line-height: 1.5;
      letter-spacing: normal;
      border-top: 1px solid $pale-sky-blue;
      box-shadow: none;
      @include mq(md) {
        display: block;
        padding: 10px 0;
      }
      &:last-child {
        border-bottom: 1px solid $pale-sky-blue;
      }
      .column {
        padding: 10px 0;
        box-shadow: none;
        @include mq(md) {
          display: block;
          padding: 0;
        }
        &:first-child {
          padding-right: 20px;
          @include mq(md) {
            padding-right: 0;
          }
          .label {
            font-weight: normal;
            color: $grey-blue;
          }
        }
        &[class*="two wide"] {
          width: percentage(276/768) !important;
          @include mq(md) {
            width: auto !important;
          }
        }
        &[class*="fourteen wide"] {
          width: percentage(437/768) !important;
          @include mq(md) {
            width: auto !important;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.ui.segment {
  > .ui.stackable.grid {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
    margin-top: 40px;
    .fb-submit {
      margin-top: 0;
    }
    .fb-back {
      margin-top: 20px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

// サンクスページ

.sec-thanks {
  background-color: $pale-grey;
}

.form-thanks {
  &_head {
    .ttl-section {
      &::before {
        content: "";
        display: block;
        width: 80px;
        height: 60px;
        margin: 0 auto 20px;
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/estimate/icon-quote@2x.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        @include mq(md) {
          margin-bottom: 10px;
        }
      }
    }
  }
  .btnSet {
    .btn {
      width: 290px;
      max-width: 100%;
      @include mq(md) {
        width: auto;
      }
      a {
      }
    }
  }
  .p-diagnosis_self_thanks & {
    &_head {
      .ttl-section {
        &::before {
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/diagnosis_self/icon-diagnosisSelf@2x.png);
        }
      }
    }
  }
  .p-request_thanks & {
    &_head {
      .ttl-section {
        &::before {
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/request/icon-request@2x.png);
        }
      }
    }
  }
}

