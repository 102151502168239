@charset "utf-8";

// ボタン
.btn {
  @include btn(blue);
  &.-blue {
    @include btn(blue);
  }
  &.-white {
    @include btn(white);
  }
  &.-grad {
    @include btn(grad);
  }
  &.-lg {
    font-size: 2rem;
    > a:not(.btn_outer) {
      min-width: 240px;
      min-height: 62px;
      padding: 3px 10px;
    }
  }
}

// ボタンを横に並べるとき
.btnSet {
  margin-top: 60px;
  &:first-child {
    margin-top: 0;
  }
  &_list {
    display: flex;
    margin: -10px -15px;
    padding-left: 0;
    justify-content: center;
    @include mq(md) {
      flex-direction: column;
    }
  }
  .btn {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    max-width: 50%;
    padding: 10px 15px;
    justify-content: flex-start;
    align-items: center;
    @include mq(md) {
      max-width: none;
      align-items: center;
    }
    a {
      width: 295px;
      max-width: 100%;
    }
    &_caption {
      margin-top: 10px;
      font-size: 1.2rem;
      line-height: 1.5;
      text-align: center;
      text-align: left;
    }
  }
}

// コンテンツ部分の基本的なタイトル
.ttl-section {
  margin-bottom: 0;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: $navy;
  @include mq(md) {
    font-size: 2.8rem;
  }
  + * {
    margin-top: 40px;
  }
  & + &_sub {
    margin-top: 20px;
  }
  &_sub {
    margin-bottom: 0;
    font-size: 1.4rem;
    text-align: center;
    + * {
      margin-top: 5px;
    }
  }
}

// WPで使うページネーション
.nav-pageNav {
  position: relative;
  margin: 68px auto 0px;
  padding: 40px 0 0 0;
  padding: 0;
  @include mq(md) {
    margin-bottom: 60px;
    + * {
      margin-top: 25px;
    }
  }
  + * {
    margin-top: 40px;
  }
  .pagination {
    @include btn();
    display: flex;
    margin: 0 -5px;
    justify-content: center;
    flex-wrap: wrap;
    @include mq(md) {
      padding-bottom: 70px;
    }
    .page-numbers:not(.btn_outer) {
      position: relative;
      display: flex;
      min-width: 40px;
      height: 40px;
      margin-right: 8px;
      margin-left: 8px;
      padding: 0;
      font-weight: normal;
      border-radius: 30px;
      justify-content: center;
      align-items: center;
      @include mq(md) {
        margin-right: 15px;
        margin-left: 15px;
      }
      // &:not(span):hover,
      &.current {
        text-decoration: none;
        color: #fff;
        background-color: $grey-blue;
      }
      &.dots {
        min-width: auto;
        border-style: none;
        box-shadow: none;
        @include mq(md) {
          margin-right: 6px;
          margin-left: 6px;
        }
      }
      &.first,
      &.last,
      &.prev,
      &.next {
        @include mq(md) {
          position: absolute;
          bottom: 0;
          margin: 0;
        }
      }
      &.prev,
      &.first {
        &::before,
        &::after {
        }
      }
      &.next,
      &.last {
        &::before,
        &::after {
        }
      }
      &.prev,
      &.next {
        min-width: 132px;
        &::before,
        &::after {
        }
      }
      &.first,
      &.last {
        min-width: 100px;
        &::before,
        &::after {
        }
      }
      &.prev {
        margin-right: 50px;
        @include mq(md) {
          left: 0px;
          // right: calc(50% + 5px);
          margin-right: 0;
        }
        &::before {
        }
        &:hover {
          &::before {
          }
        }
      }
      &.first {
        @include mq(md) {
          right: calc(50% + 75px);
          margin-right: 0;
        }
        &::before {
        }
        &:hover {
          &::before {
          }
        }
      }
      &.next {
        margin-left: 50px;
        @include mq(md) {
          right: 0px;
          // left: calc(50% + 5px);
          margin-left: 0;
        }
        &::after {
        }
        &:hover {
          &::after {
          }
        }
      }
      &.last {
        @include mq(md) {
          left: calc(50% + 75px);
        }
        &::after {
        }
        &:hover {
          &::after {
          }
        }
      }
    }
  }
}

// リード文
.txt-lead {
  font-size: 2rem;
  line-height: 1.8;
  @include mq(md) {
    font-size: 1.8rem;
  }
  + * {
    margin-top: 60px;
  }
}

// 画像とテキストを左右入れ替えて順番に表示する
.productServiceLead {
  $this: &;
  & + & {
    margin-top: 60px;
  }
  &_lead {
    font-size: 2rem;
    line-height: 1.8;
    @include mq(md) {
      font-size: 1.8rem;
    }
    + * {
      margin-top: 60px;
    }
  }
  .ttl-section {
    + * {
      margin-top: 60px;
    }
  }
  &_ttl {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
  }
  &_list {
    #{$this}_item {
      &:nth-child(odd) {
        flex-direction: row-reverse;
      }
    }
    &.-noswap {
      #{$this}_item {
        flex-direction: row-reverse;
        .btn {
          justify-content: flex-end;
        }
      }
    }
    &.-swap3 {
      #{$this}_item {
        &:nth-child(even) {
          flex-direction: row-reverse;
        }
        &:nth-child(odd) {
          flex-direction: row;
        }
        &:first-child {
          flex-direction: row-reverse;
        }
        .btn {
          justify-content: flex-end;
        }
      }
    }
    &.-img1txt2 {
      #{$this}_item {
        &Img {
          width: 35%;
          @include mq(md) {
            width: auto;
          }
        }
        &Txt {
          width: 58%;
          @include mq(md) {
            width: auto;
          }
        }
      }
    }
  }
  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq(md) {
      display: block;
    }
    & + & {
      margin-top: 30px;
      @include mq(md) {
        margin-top: 40px;
      }
    }
    &Img {
      width: 50%;
      @include mq(md) {
        width: auto;
      }
    }
    &Txt {
      width: percentage(400/920);
      font-size: 1.6rem;
      line-height: 1.8;
      @include mq(md) {
        width: auto;
        margin-top: 20px;
      }
    }
    &Ttl {
      margin-bottom: 0;
      font-size: 2.8rem;
      line-height: 1.5;
      @include mq(md) {
        font-size: 2.4rem;
      }
      + * {
        margin-top: 20px;
      }
    }
    .btn {
      justify-content: flex-start;
      margin-top: 1.5em;
    }
  }
  &.-grid1 & {
    &_item {
      display: block;
      &Img {
        width: auto;
      }
      &Txt {
        width: auto;
        margin-top: 20px;
      }
    }
  }
}

// 関連ページのリンク表示
.relatedList {
  $this: &;
  & + & {
    margin-top: 65px;
    @include mq(md) {
      margin-top: 60px;
    }
  }
  + .btn {
    @include btn();
    margin-top: 80px;
  }
  &_ttl {
    margin-bottom: 0;
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.5;
    @include mq(md) {
      font-size: 2.8rem;
    }
    + * {
      margin-top: 35px;
    }
  }
  &_wrap {
    + .btn {
      margin-top: 30px;
    }
  }
  &_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -14px -28px;
    @include mq(md) {
      margin: 0px -9.5px -30px;
    }
  }
  &_item {
    width: percentage(1/3);
    padding: 0 14px 28px;
    @include mq(md) {
      width: percentage(1/2);
      padding: 0px 9.5px 30px;
    }
    &Inr {
      @include mq(md) {
      }
    }
    &Link {
      display: block;
      transition: 0.2s;
      text-decoration: none;
      color: $baseColor;
      @include mq(min-md) {
        &:hover {
          color: $linkColor;
          p:not([class]) {
            text-decoration: underline;
          }
        }
      }
    }
    &Img {
      position: relative;
      overflow: hidden;
      // background-color: #fff;
      &::before {
        content: "";
        display: block;
        padding-top: percentage(723/1083);
      }
      + * {
        margin-top: 10px;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        transform: translate(-50%,-50%);
      }
    }
    &Txt {}
    &Ttl {
      margin-bottom: 0;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.5;
      color: $linkColor;
      + * {
        margin-top: 6px;
      }
      &::after {
        content: "";
        display: inline-block;
        width: 1.2em;
        height: 1em;
        margin: 0.25em 0 0.25em 0.3em;
        margin-left: 0.3em;
        vertical-align: top;
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/arw-link@2x.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
  }
  @for $i from 1 through 9 {
    &.-upto#{$i}pc & {
      &_item {
        &:nth-child(n+#{$i+1}) {
          @include mq(min-md) {
            display: none;
          }
        }
      }
    }
    &.-upto#{$i}sp & {
      &_item {
        &:nth-child(n+#{$i+1}) {
          @include mq(md) {
            display: none;
          }
        }
      }
    }
  }
}