@charset "utf-8";

/* ローディング画面 */
#loading {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  // padding-left: 200px;
  // transition: all 1s;
  pointer-events: none;
  background-color: #fff;
  background-color: $pale-grey;
  align-items: center;
  justify-content: center;
  &.-loaded {
    animation: fadeout 1s ease-out 0s both;
    // visibility: hidden;
    // transition: all 0s;
    // opacity: 0;
  }
}

.spinner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 220px;
  font-size: 1.6rem;
  transform: translateZ(0);
  align-items: center;
  justify-content: center;
  color: $grey-blue;
  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0%;
    left: 0%;
    display: block;
    width: 100%;
    height: 100%;
    animation: load1 1.1s infinite linear;
    border-width: 8px;
    border-style: solid;
    border-color: $very-light-blue;
    border-left-color: $linkColor;
    border-radius: 50%;
  }
  &::after {
    z-index: 1;
    transform: rotate(-60deg);
    animation-name: load2;
    border-color: transparent transparent transparent $very-light-blue;
  }
  img {
    width: auto;
    max-width: 100%;
    height: 26px;
  }
}
@keyframes load1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    transform: rotate(-60deg);
  }
  100% {
    transform: rotate(300deg);
  }
}


/* ローディングアニメーション */
@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  } 100% {
    transform: scale(1.0);
    opacity: 0;
  }
}

// 旧スライド
.topImg {
  overflow: hidden;
  height: 100vh;
  @include mq(lg) {
    height: calc(100vh - 55px);
  }
  &_list {
    height: 100%;
    margin: 0;
    padding-left: 0;
    list-style: none;
  }
  .slick {
    &-list {
      height: 100%;
    }
    &-track {
      height: 100%;
    }
    &-slide {
      position: relative;
      height: 100%;
      img {
        object-fit: cover;
        max-width: 100%;
        height: 100vh;
        max-height: 100%;
        margin: 0 auto;
        @include mq(md) {
          object-position: 37% 50%;
        }
      }
    }
  }
}

// スライド
.topWrap {
  @include mq(md) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.topSlide {
  overflow: hidden;
  height: 100vh;
  @include mq(md) {
    // height: calc(100vh - 55px);
    height: auto;
  }
  .topBnr + & {
    @include mq(md) {
      // height: calc(100vh - 55px - 120px);
    }
  } 
  &_list {
    height: 100%;
    margin: 0;
    padding-left: 0;
    list-style: none;
  }
  &_item {
    height: 100%;
    // padding-left: 200px;
    @include mq(md) {
      min-height: 109vw;
    }
  }
  .slick {
    &-list {
      height: 100%;
    }
    &-track {
      height: 100%;
    }
    &-slide {
      position: relative;
      height: 100%;
    }
  }
}

.slideTxt {
  @include mq(md) {
    &_ttl,
    &_lead,
    &_catch {
    }
    &_ttl {
      font-size: 2.9rem;
      font-weight: bold;
      line-height: 1.2;
    }
    &_lead {
      font-size: 1.2rem;
      line-height: 1.8;
    }
    &_catch {
      font-size: 1.6rem;
      line-height: 1.5;
    }
  }
  @include mq(min-md) {
    text-align: center;
    &_ttl,
    &_lead,
    &_catch {
      span {
        display: inline-block;
      }
    }
    &_ttl {
      margin: 0;
      font-size: 4rem;
      // font-size: 5.6vh;
      font-weight: bold;
      line-height: 1.2;
      text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    }
    &_lead {
      margin-top: 1em;
      font-size: 1.5rem;
      // font-size: 2vh;
      font-weight: 500;
      line-height: 1.5;
      text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    }
    &_catch {
      // margin-top: 1em;
      font-size: 1.7rem;
      // font-size: 2vh;
      font-weight: 500;
      line-height: 1.5;
      text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    }
    .btn {
      margin-top: 35px;
    }
    .slick-cloned & {
      &_ttl {
        span {
          opacity: 0;
        }
      }
      .btn,
      &_lead {
        opacity: 0;
      }
    }
    .slick-current & {
      &_ttl,
      &_lead,
      &_catch {
        span {
          animation-duration: 1.2s;
          animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
          animation-fill-mode: both;
        }
      }
      &_ttl {
        span {
          animation-delay: 3s;
          @for $i from 1 through 25 {
            $f_str: random(4);
            $f_delay: random(10);
            &:nth-child(#{$i}) {
              animation-name: textArrive#{$f_str};
              // animation-delay: #{1 + ($f_delay * 0.05)}s;
            }
          }
        }
      }
      &_lead {
        // animation: slideup 1.5s ease-out 1.5s both;
        span {
          animation-delay: 3.5s;
          @for $i from 1 through 70 {
            $f_str: random(4);
            $f_delay: random(10);
            &:nth-child(#{$i}) {
              animation-name: textArrive#{$f_str};
              // animation-delay: #{1 + ($f_delay * 0.05)}s;
            }
          }
        }
      }
      &_catch {
        // animation: slideup 1.5s ease-out 1.5s both;
        span {
          animation-delay: 4s;
          @for $i from 1 through 30 {
            $f_str: random(4);
            $f_delay: random(10);
            &:nth-child(#{$i}) {
              animation-name: textArrive#{$f_str};
              // animation-delay: #{1 + ($f_delay * 0.05)}s;
            }
          }
        }
      }
      .btn {
        animation: slideup 1s ease-out 4.2s both;
      }
    }
  }
}

// スライドA
.slideA {
  $this: &;
  position: relative;
  background-color: $pale-grey;
  @include mq(md) {
    background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/top/bg-slideA_sp.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    &_bg {
      height: 100%;
      &Inr {
        position: relative;
        z-index: 10;
        height: 100%;
      }
      .slideTxt {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: percentage(70/750) 0;
        justify-content: center;
        &_ttl,
        &_lead {
          margin-top: 0;
          margin-right: percentage(17/375);
          margin-left: percentage(17/375);
        }
        &_ttl {
          margin-bottom: 0.5em;
        }
        .btn {
          margin-top: auto;
        }
      }
    }
    &_spimg {
      width: percentage(700/750);
      margin: 0 auto;
    }
    &_inr {
      position: absolute;
      right: 0;
      bottom: percentage(145/933);
      left: 0;
      display: none;
      width: 90%;
      width: percentage(700/750);
      margin: 0 auto;
      background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/top/slideB-products_sp.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      &::before {
        content: "";
        display: block;
        width: 100%;
        padding-top: percentage(864/1450);
      }
    }
    &_mov {
      display: none;
    }
    &_list {
      display: none;
    }
  }
  @include mq(min-lg) {
    &_bg {
      &Inr {
        margin-left: 200px;
      }
    }
    &_inr {
      margin-left: 200px;
    }
  }
  @include mq(min-md) {
    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
    }
    &::after {
      z-index: 5;
      bottom: 0;
      left: 0;
      height: percentage(399/1080);
      // margin-left: -20px;
      // background: linear-gradient(to top, #badcf2, #32515f);
      background: linear-gradient(to top, #405d6a 10%, rgba(#939b9f,1) 70%, rgba(#979797,0));
    }
    &_bg {
      position: absolute;
      width: 100%;
      height: percentage(681/1080);
      min-height: 300px;
      // background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/top/bg-slideA.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      &::before,
      &::after {
        // content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
      }
      &::before {
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/top/bg-pattern.png);
        background-repeat: repeat;
        // background-position: center center;
        // background-size: contain;
        background-size: 2px;
        mix-blend-mode: multiply;
      }
      &::after {
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/top/bg-ray.png);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
      }
      &Inr {
        position: relative;
        z-index: 50;
        display: flex;
        flex-direction: column;
        height: 100%;
        // margin-left: 200px;
        padding-bottom: 10%;
        text-align: center;
        color: $baseColor;
        align-items: center;
        justify-content: center;
      }
      .slideTxt {
        &_ttl,
        &_lead {
          text-shadow: none;
        }
      }
    }
    &_mov {
      position: absolute;
      z-index: 1;
      top: 0;
      // left: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
    }
    &_inr {
      position: relative;
      width: auto;
      height: 100%;
      // margin-left: 200px;
    }
    &_list {
      position: absolute;
      top: percentage(417/1080);
      right: 0;
      left: 0;
      width: 98vh;
      max-width: 80%;
      margin: 0;
      margin-right: auto;
      margin-left: auto;
      padding: 0;
      list-style: none;
      // background-color: rgba(#fff,0.5);
      &::after {
        content: "";
        display: block;
        padding-top: percentage(660/1105);
      }
    }
    &_item {
      position: absolute;
      bottom: 0;
      &.-akerunpro {
        z-index: 10;
        bottom: percentage(-26/1105);
        left: percentage(651/1105);
        width: percentage(182/1105);
      }
      &.-nfc {
        z-index: 9;
        bottom: percentage(8/1105);
        left: percentage(864/1105);
        width: percentage(241/1105);
      }
      &.-mobile {
        z-index: 8;
        left: percentage(0/1105);
        width: percentage(157/1105);
      }
      &.-laptop {
        z-index: 7;
        bottom: percentage(22/1105);
        left: percentage(27/1105);
        width: percentage(664/1105);
      }
    }
    &:not(.slick-current) & {
      &_mov,
      &_item {
        // opacity: 0;
      }
    }
    &.slick-current & {
      &_mov {
        animation: fadein 3s ease-in 2s both;
      }
      &_bg {
        &::after {
          animation: fadein 3s ease-in 3s both;
        }
      }
      &_item {
        animation-name: fadein;
        animation-duration: 2s;
        animation-timing-function: ease-out;
        animation-fill-mode: both;
        &.-laptop {
          animation-delay: 1s;
        }
        &.-mobile {
          animation-delay: 1.4s;
        }
        &.-nfc {
          animation-delay: 1.8s;
        }
        &.-akerunpro {
          animation-delay: 2.2s;
        }
      }
    }
  }
}

// スライドB
.slideB {
  @include mq(md) {
    height: 100%;
    background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/top/bg-slideB_sp.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    &_txt {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0 percentage(17/375);
      color: #fff;
      justify-content: center;
      &Inr {
        padding: 10% 0;
      }
      .slideTxt {
        &_ttl,
        &_lead,
        &_catch {
          margin-left: percentage(227/682);
        }
      }
      .btn {
        margin-top: 67px;
        margin-top: percentage(67/340);
      }
    }
    &_img {
      display: none;
    }
  }
  @include mq(xs) {
    &_txt {
      .slideTxt {
        &_ttl {
          // font-size: 2.4rem;
          font-size: 7.5vw;
        }
        &_catch {
          font-size: 4.27vw;
        }
      }
    }
  }
  @include mq(min-lg) {
    &_txt {
      &Inr {
        margin-left: 200px;
      }
    }
    &_img {
      margin-left: 200px;
    }
  }
  @include mq(min-md) {
    &_txt {
      position: absolute;
      z-index: 50;
      width: 100%;
      height: 100%;
      color: #fff;
      &Inr {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        // margin-left: 200px;
        padding-bottom: 10%;
        align-items: center;
        justify-content: center;
      }
      .btn {
        margin-top: 45px;
      }
    }
    &_img {
      position: relative;
      height: 100%;
      // margin-left: 200px;
      &Inr {
        width: 100%;
        height: 100%;
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/top/bg-slideB.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        img {
          display: none;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &Item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        &.-nfc {
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/top/slideB-nfc.png);
        }
        &.-card {
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/top/slideB-card.png);
          &.-old {
            background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/top/slideB-card0801.png);
          }
        }
      }
    }
    &.slick-cloned & {
      &_img{
        &Inr,
        &Item {
          opacity: 0;
        }
      }
    }
    &.slick-current & {
      &_img {
        &Inr {
          animation-name: fadein;
          animation-duration: 2s;
          animation-timing-function: ease-out;
          animation-delay: 1s;
          animation-fill-mode: both;
        }
        &Item {
          animation-duration: 1.2s;
          animation-timing-function: ease-out;
          animation-fill-mode: both;
          &.-nfc {
            // animation-name: fadein;
            // animation-duration: 0.7s;
            // animation-delay: 2.5s;
          }
          &.-card {
            animation-name: scalein;
            animation-duration: 1.2s;
            animation-delay: 2s;
          }
        }
      }
    }
  }
}

@keyframes scalein {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.sec-topContents {
  position: absolute;
  bottom: 0;
  width: 100%;
  @include mq(md) {
    &,
    &:last-child {
      padding-bottom: 78px;
    }
  }
}

.topContents {
  font-size: 1.4rem;
  letter-spacing: 1px;
  color: #fff;
  &_ttl {
    margin: 0;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 2px;
    + * {
      margin-top: 0;
    }
  }
  .btn {
    margin-top: 20px;
    letter-spacing: normal;
    justify-content: flex-start;
  }
}

.topFooter {
  $this: &;
  position: fixed;
  z-index: 50;
  top: calc(100% - 52px);
  // top: 100%;
  width: 100%;
  // margin-top: -52px;
  transition: 0.3s;
  @include mq(lg) {
    position: static;
  }
  @include mq(min-lg) {
    &.-active,
    &.current {
      overflow-y: auto;
      max-height: 100vh;
      transform: translateY(calc(-100% + 52px));
      &::-webkit-scrollbar {
        display: none;
      }
      #{$this}_btn {
        a {
          &::before {
            transform: translateY(-50%) rotate(-45deg);
            border-style: none none solid solid;
          }
          &::after {
            content: "Close";
          }
        }
      }
    }
  }
  &_btn {
    // position: absolute;
    right: 0;
    // bottom: 100%;
    left: 0;
    display: block;
    margin: auto;
    padding-left: 200px;
    @include mq(lg) {
      display: none;
    }
    a {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 68px;
      height: 42px;
      margin: 0 auto 10px;
      font-size: 1.4rem;
      line-height: 1;
      text-decoration: none;
      color: #fff;
      text-shadow: 0 2px 6px rgba(#000, 0.5);
      align-items: center;
      justify-content: center;
      &::before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        transform: rotate(-45deg);
        border-width: 2px;
        border-style: solid solid none none;
        border-color: #fff;
        filter: drop-shadow(0 1px 2px rgba(#000, 0.4));
      }
      &::after {
        content: "Footer";
        display: block;
        text-align: center;
      }
      span {
        display: none;
      }
    }
  }
}

.topBnr {
  position: absolute;
  z-index: 10;
  bottom: 20px;
  left: 220px;
  text-align: center;
  @include mq(md) {
    position: static;
    padding: 25px 20px 0;
  }
  @include mq(lg) {
    left: 20px;
  }
  a {
    display: block;
    width: 267px;
    max-width: 100%;
    @include mq(md) {
      width: auto;
    }
  }
  img {
    // width: auto;
  }
}

.sec-topMainLink {
  padding: 25px 0;
}

.topMainLink {
  &_list {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }
  &_item {
    display: block;
    & + & {
      margin-top: 25px;
    }
    a {
      display: block;
    }
  }
}

.sec-topMainBtn {
  padding: 25px 0;
  background-color: #d0d0d0;
  &:last-child {
    @include mq(md) {
      padding: 25px 0;
    }
  }
}

.topMainBtn {
  .btnSet {
    &_list {
      margin: -10px -15px;
    }
    .btn {
      a {
        width: 335px;
        min-height: 48px;
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
  }
}

@keyframes textArrive1 {
  0% {
    transform: translate3d(0, -40px, 200px) rotate3d(1, 1, 0.5, 165deg);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0) rotate3d(1, 1, 0.5, 0deg);
    opacity: 1;
  }
}

@keyframes textLeave2 {
  0% {
    transform: translate3d(0, 0, 0) rotate3d(1, -1, -0.5, 0deg);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -40px, 200px) rotate3d(1, -1, -0.5, 165deg);
    opacity: 0;
  }
}

@keyframes textArrive2 {
  0% {
    transform: translate3d(0, 40px, 200px) rotate3d(1, -1, -0.5, 165deg);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0) rotate3d(1, -1, -0.5, 0deg);
    opacity: 1;
  }
}

@keyframes textLeave3 {
  0% {
    transform: translate3d(0, 0, 0) rotate3d(1, 0.75, 0.75, 0deg);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 40px, 200px) rotate3d(1, 0.75, 0.75, 165deg);
    opacity: 0;
  }
}

@keyframes textArrive3 {
  0% {
    transform: translate3d(0, -40px, 200px) rotate3d(1, 0.75, 0.75, 165deg);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0) rotate3d(1, 0.75, 0.75, 0deg);
    opacity: 1;
  }
}

@keyframes textLeave4 {
  0% {
    transform: translate3d(0, 0, 0) rotate3d(-0.5, 1, 0.5, 0deg);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -40px, 200px) rotate3d(-0.5, 1, 0.5, 165deg);
    opacity: 0;
  }
}

@keyframes textArrive4 {
  0% {
    transform: translate3d(0, 40px, 200px) rotate3d(-0.5, 1, 0.5, 165deg);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0) rotate3d(-0.5, 1, 0.5, 0deg);
    opacity: 1;
  }
}
