// お申し込みページiframe外用（iframeで埋め込んでいるkintoneフォームは_request_iframe.scss）

.p-request_0804,
.p-request,
.p-request_thanks {
  .l-main {
    .main-header {
      padding-left: 0;
      background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/request/mv-application_sp.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      @include mq(min-md) {
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/request/mv-application_pc.jpg);
        &.-old {
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/request/mv-application_old_pc.jpg);
        }
      }
      &.-normal {
      }
      .main-header_panel {
        min-height: 460px;
      }
      .ttl-page {
        line-height: 1.2;
        text-align: center;
        color: #fff;
      }
    }
  }
}

.p-request_0804,
.p-request {
  .l-main {
    @include mq(lg) {
      padding-top: 0;
    }
    [class^="sec-"] {
      padding-left: 0;
    }
    .breadcrumb {
      @include mq(min-lg) {
        padding-left: 30px;
      }
      @include mq(lg) {
        top: 0;
      }
    }
  }
  .l-footer {
    padding-left: 0;
  }
}

.sec-request {
  background-color: $pale-grey;
  .main-header + & {
    padding-top: 80px;
    @include mq(md) {
      padding-top: 60px;
    }
  }
}

.form-request {
  &_inr {
    max-width: 868px;
    margin-right: auto;
    margin-left: auto;
  }
}