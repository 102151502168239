// Akerunとは／Akerunが叶える未来

// ヘッダー
.main-header {
  .p-message &,
  .p-history & {
    position: relative;
    overflow: hidden;
    background-color: #ededed;
    &.-animTarget {
      &.-ready {
        .ttl-page {
          &::after {
            transform: scaleX(0);
          }
          span {
            opacity: 0;
          }
        }
        .header-philosophy {
          &_img {
            opacity: 0;
          }
        }
      }
      &.-action {
        .ttl-page {
          &::after {
            animation: stretch 0.7s ease-out 0s both;
          }
          span {
            animation: fadein 2s ease-out 0.5s both;
          }
        }
        .header-philosophy {
          &_img {
            animation: fadein 3s ease-out 1s both;
          }
        }
      }
    }
    &_panel {
      position: static;
    }
    .ttl-page {
      overflow: hidden;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 1.2;
      @include mq(md) {
        font-size: 2.1rem;
      }
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 6px;
        margin-top: 10px;
        transform-origin: left;
        background: linear-gradient(to right, #55bdca 0%, #3f93cc 100%);
        @include mq(md) {
          height: 3px;
          margin-top: 5px;
        }
      }
      span {
        display: inline-block;
      }
    }
  }
  .p-message & {
    .header-philosophy {
      &_txt {
        @include mq(md) {
          bottom: 50%;
        }
      }
      &_img {
        top: percentage(-81/284);
        right: percentage(-88/920);
        width: percentage(717/920);
        @include mq(md) {
          top: percentage(0/196);
          right: percentage(-145/750);
          width: percentage(804/750);
        }
      }
    }
  }
  .p-history & {
    .header-philosophy {
      &_txt {
        @include mq(md) {
          bottom: 45%;
        }
      }
      &_img {
        top: percentage(56/284);
        right: percentage(55/920);
        width: percentage(529/920);
        @include mq(md) {
          top: percentage(96/196);
          right: percentage(39/750);
          width: percentage(527/750);
        }
      }
    }
  }
}

.header-philosophy {
  // position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include mq(md) {
    display: flex;
    flex-direction: column-reverse;
  }
  .-ready & {
    &_img {
      opacity: 0;
    }
  }
  .-action & {
    &_img {
      animation: slideup 3s ease-out 1s both;
    }
  }
  &::after {
    content: "";
    display: block;
    width: 100%;
    padding-top: percentage(284/920);
    @include mq(md) {
      padding-top: percentage(197/335);
    }
  }
  &_txt {
    position: absolute;
    z-index: 0;
    text-align: left;
  }
  &_img {
    position: absolute;
    z-index: 10;
    img {
      width: 100%;
    }
  }
}

// Akerunとは
#-message------------------ {}

.message {
  $this: &;
  &_block {
    margin-top: 50px;
    @include mq(md) {
      &:first-child {
        margin-top: 0;
      }
    }
    .container {
      position: relative;
    }
    &.-no1 {
      #{$this}_txt {
        position: relative;
        z-index: 10;
        min-height: 770px;
      }
      #{$this}_img {
        position: absolute;
        top: 100px;
        right: 0;
        width: 500px;
        @include mq(md) {
          top: 15%;
          right: -20px;
          width: percentage(278/335);
          max-width: 350px;
          @include mq(md) {
            &::after {
              content: "";
              position: absolute;
              z-index: 1;
              top: 0;
              left: 0;
              display: block;
              width: 100%;
              height: 100%;
              background-color: rgba(#fff,0.5);
            }
          }
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          &.-anim0 {
            position: relative;
          }
        }
      }
    }
    &.-no2 {
      #{$this}_wrap {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        @include mq(md) {
          display: block;
        }
      }
      #{$this}_txt {
        position: relative;
        z-index: 10;
        right: 0;
        width: 524px;
        max-width: 100%;
      }
      #{$this}_img {
        position: relative;
        width: 345px;
        margin: 0;
        margin-right: 30px;
        @include mq(md) {
          position: absolute;
          z-index: 0;
          top: 15%;
          right: 0;
          // left: 0;
          width: percentage(250/335);
          max-width: 250px;
          margin: auto;
          &::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(#fff,0.6);
          }
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          &.-anim0 {
            position: relative;
          }
        }
      }
    }
    &.-animTarget {
      @include mq(md) {
        &.-ready {
          #{$this}_img {
            opacity: 0;
          }
        }
        &.-action {
          #{$this}_img {
            animation: fadein 3s ease-out 2s both;
          }
        }
      }
      @include mq(min-md) {
        &.-ready {
          &.-no1 {
            #{$this}_img {
              img {
                opacity: 0;
              }
            }
          }
          &.-no2 {
            #{$this}_img {
              img {
                opacity: 0;
              }
            }
          }
        }
        &.-action {
          &.-no1 {
            #{$this}_img {
              img {
                &.-anim0 {
                  animation: fadein 2s ease-out 1s both;
                }
                @for $i from 1 through 3 {
                  &.-anim#{$i} {
                    animation: fadein 1.1s linear #{$i * 0.4 + 1.2}s both;
                  }
                }
              }
            }
          }
          &.-no2 {
            #{$this}_img {
              img {
                &.-anim0 {
                  animation: fadein 1s ease-out 1.5s both;
                }
                &.-anim1 {
                  animation: slideup 0.5s cubic-bezier(0,.8,.4,2) 2s both;
                }
              }
            }
          }
        }
      }
      &.-ready {
        #{$this}_ttl {
          opacity: 0;
        }
        #{$this}_txt {
          p {
            opacity: 0;
          }
        }
      }
      &.-action {
        #{$this}_ttl {
          animation: slideup 1s ease-out 0s both;
        }
        #{$this}_txt {
          p {
            @for $i from 1 through 20 {
              &:nth-child(#{$i}) {
                animation: slideup 1s ease-out #{$i * 0.2 + 0.2}s both;
              }
            }
          }
        }
      }
    }
    &:nth-child(even) {
      #{$this}_ttl {
        flex-direction: row-reverse;
        &::after {
          background-color: #00bfcd;
        }
      }
    }
  }
  &_wrap {
    margin-top: 50px;
    @include mq(md) {
      margin-top: 40px;
    }
  }
  &_ttl {
    display: flex;
    margin-right: -5px;
    margin-bottom: 0;
    margin-left: -5px;
    align-items: center;
    @include mq(md) {
      margin: 0 -3px;
    }
    &::after {
      content: "";
      display: block;
      height: 5px;
      margin: 0 5px;
      background-color: $linkColor;
      flex: auto;
      @include mq(md) {
        height: 2px;
        margin: 0 3px;
      }
    }
    &_inr {
      display: inline-block;
      margin: 0 5px;
      padding: 16px 40px;
      font-size: 3.6rem;
      font-weight: bold;
      line-height: 1.2;
      color: #fff;
      border-width: 8px;
      border-style: solid;
      border-image-source: linear-gradient(to right, #00c0cd, $linkColor);
      border-image-slice: 1;
      background-image: linear-gradient(to right, #55bdca, #3f93cc);
      @include mq(md) {
        margin: 0 3px;
        padding: 13px 18px;
        font-size: 2rem;
        border-style: none;
      }
    }
  }
  &_txt {
    font-size: 1.6rem;
    line-height: 2.5;
    @include mq(md) {
      line-height: 1.8;
    }
    .-txtL {
      line-height: 34px;
      @include mq(md) {
        font-size: 1.8rem;
      }
    }
    p {
      + p {
        margin-top: 1.5em;
      }
    }
  }
}

.sec-messageLink {
  padding-bottom: 0;
  background-color: #f0f5f8;
  @include mq(md) {
    padding: 35px 0 0;
  }
}

.messageLink {
  $this: &;
  &_block {
    margin-top: 60px;
    @include mq(md) {
      margin-top: 35px;
    }
  }
  &_img {
    &Inr {
      position: relative;
      z-index: 10;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0 0 5px 0 rgba(#000, 0.1);
    }
    &.-ready {
      opacity: 0;
    }
    &.-action {
      animation: slideup 1s ease-out 0.5s both;
    }
  }
  &_txt {
    margin-top: -110px;
    padding: 150px 0 40px;
    font-size: 1.6rem;
    color: #fff;
    background: linear-gradient(to right, #55bdca, #3f93cc);
    @include mq(md) {
      margin-top: -80px;
      padding-top: 100px;
    }
    .btn {
      justify-content: flex-end;
      margin-top: 30px;
      margin-right: percentage(30/800);
      @include mq(md) {
        justify-content: center;
      }
    }
    &.-ready {
      .container {
        * {
          opacity: 0;
        }
      }
    }
    &.-action {
      .container {
        @for $i from 1 through 9 {
          *:nth-child(#{$i}) {
            animation: slideup 0.5s ease-out #{$i * 0.2 + 0.5}s both;
          }
        }
      }
    }
  }
  &_ttl {
    padding-bottom: 20px;
    font-size: 3rem;
    font-weight: 500;
    line-height: 1.5;
    border-bottom: 1px solid #fff;
    @include mq(md) {
      padding-bottom: 10px;
      font-size: 1.8rem;
    }
    &Sub {
      font-size: 2.4rem;
      line-height: 1.7;
      @include mq(md) {
        font-size: 1.7rem;
      }
    }
  }
  &_btn {
    padding: 70px 0;
    @include mq(md) {
      padding: 50px 0;
    }
    .btn {
      font-size: 1.8rem;
    }
    a {
      width: 485px;
    }
    &.-ready {
      .btn {
        opacity: 0;
      }
    }
    &.-action {
      .btn {
        animation: slideup 0.5s ease-out 0s both;
      }
    }
  }
}

// Akerunが叶える未来
#-history------------------ {}

.sec-history {
  overflow: hidden;
  color: #fff;
  background-color: #091720;
  background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/history/bg-history.jpg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  @include mq(md) {
    background-size: auto;
  }
}

.history {
  $this: &;
  &_block {
    position: relative;
    &:nth-child(even) {
      text-align: right;
      @include mq(min-md) {
        #{$this}_item {
          flex-direction: row-reverse;
          margin-right: -50px;
          margin-left: 0;
          &:nth-child(odd) {
            flex-direction: row;
            margin-right: 0;
            margin-left: -110px;
            #{$this}_img {
              margin-right: percentage(40/840);
              margin-left: 0;
            }
          }
        }
        #{$this}_img {
          margin-right: 0;
          margin-left: percentage(40/840);
        }
        #{$this}_body {
          margin-right: 0;
          margin-left: 60px;
        }
      }
      #{$this}_progress {
        flex-direction: row-reverse;
        @include mq(md) {
          margin-right: 0;
          margin-left: -20px;
        }
      }
      #{$this}_mv {
        right: auto;
        left: percentage(-70/840);
      }
    }
    &.-animTarget {
      &.-ready {
        #{$this}_progress {
          &Num,
          &Txt {
            opacity: 0;
          }
          &::after {
            transform: scaleX(0);
          }
        }
        #{$this}_ttl {
          &::before {
            left: -101%;
          }
          &Inr {
            clip-path: inset(0 100% 0 0);
          }
        }
        #{$this}_mv {
          &::before {
            right: -101%;
            left: auto;
          }
          img {
            clip-path: inset(0 0 0 100%);
          }
        }
        #{$this}_body {
          &::after {
            opacity: 0;
          }
        }
      }
      &.-action {
        #{$this}_progress {
          &Num {
            animation: fadein 1.5s ease-out 0s both;
          }
          &Txt {
            animation: fadein 1.5s ease-out 0.5s both;
          }
          &::after {
            transform-origin: left;
            animation: stretch 2s ease-out 1s both;
          }
        }
        #{$this}_ttl {
          &::before {
            left: 0;
          }
          &Inr {
            clip-path: inset(0);
          }
        }
        #{$this}_mv {
          &::before {
            right: 0;
            left: auto;
          }
          img {
            clip-path: inset(0);
          }
        }
        #{$this}_body {
          &::after {
            animation: fadein 1s ease-out 1.5s both;
          }
        }
        #{$this}_lead {
          animation: slideup 1s ease 2s both;
        }
        #{$this}_list {
          animation: slideup 1s ease 2.5s both;
        }
      }
      &:nth-child(even) {
        &.-ready {
          #{$this}_ttl {
            &::before {
              right: -101%;
              left: auto;
            }
            &Inr {
              clip-path: inset(0 0 0 100%);
            }
          }
          #{$this}_mv {
            &::before {
              right: auto;
              left: -101%;
            }
            img {
              clip-path: inset(0 100% 0 0);
            }
          }
        }
        &.-action {
          #{$this}_progress {
            &::after {
              transform-origin: right;
            }
          }
          #{$this}_ttl {
            &::before {
              right: 0;
              left: auto;
            }
            &Inr {
              clip-path: inset(0);
            }
          }
          #{$this}_mv {
            &::before {
              right: auto;
              left: 0;
            }
            img {
              clip-path: inset(0);
            }
          }
        }
      }
    }
  }
  &_head {
    position: relative;
  }
  &_progress {
    position: relative;
    z-index: 20;
    display: flex;
    font-size: 2.7rem;
    line-height: 1.5;
    align-items: flex-start;
    @include mq(md) {
      margin-right: -20px;
      font-size: 1.4rem;
    }
    &::after {
      content: "";
      display: block;
      height: 2px;
      margin-top: 1.2em;
      background-color: #fff;
      flex: auto;
      @include mq(md) {
        height: 1px;
        margin-top: 1.1em;
      }
    }
    &Num {
      margin: 0;
      font-size: 13rem;
      font-weight: bold;
      line-height: 1;
      @include mq(md) {
        font-size: 6.5rem;
      }
    }
    &Txt {
      margin: 0 10px;
      margin-top: 0.5em;
      font-weight: bold;
      @include mq(md) {
        margin: 0 5px;
        margin-top: 0.4em;
      }
    }
  }
  &_ttl {
    position: relative;
    z-index: 30;
    display: inline-block;
    overflow: hidden;
    margin: 0;
    font-size: 3.6rem;
    font-weight: bold;
    line-height: 1.2;
    transform-origin: left;
    @include mq(md) {
      font-size: 2rem;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      transition: 0.5s 1s;
      background: linear-gradient(to right, #063448, #4095cc);
    }
    &Inr {
      position: relative;
      display: block;
      display: flex;
      overflow: hidden;
      min-height: 2.6em;
      padding: 0.2em 1.4em;
      transition: 0.5s 1.4s;
      align-items: center;
      @include mq(md) {
        padding: 0.2em 0.8em;
      }
    }
    &Sub {
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 1.5;
      @include mq(md) {
        font-size: 1.8rem;
      }
      + * {
        margin-top: 2em;
        @include mq(md) {
          margin-top: 1em;
        }
      }
    }
  }
  &_mv {
    position: absolute;
    z-index: 10;
    top: percentage(50/840);
    top: 70px;
    right: percentage(-70/840);
    overflow: hidden;
    width: percentage(798/840);
    @include mq(md) {
      // top: 90%;
      top: 50px;
      right: -20px;
      width: 100%;
      max-width: 600px;
      // transform: translateY(-50%);
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      transition: 0.5s 1.5s;
      background: linear-gradient(to left, #063448, #4095cc);
    }
    img {
      transition: 0.5s 1.8s;
    }
  }
  &_body {
    position: relative;
    margin-top: percentage(48/840);
    margin-right: 60px;
    padding: percentage(160/780) 50px 10%;
    font-size: 1.6rem;
    line-height: 2.1;
    text-align: left;
    @include mq(md) {
      margin-right: -20px;
      margin-left: -20px;
      padding-top: percentage(65/375);
      padding-right: 20px;
      padding-bottom: 20%;
      padding-left: 20px;
      line-height: 1.8;
    }
    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 80%;
      background: linear-gradient(to top, rgba(#1c4663,0), #1c4663);
    }
    .-txtL {
      font-weight: bold;
      line-height: 1.5;
      @include mq(md) {
        font-size: 1.8rem;
      }
    }
  }
  &_lead {
    position: relative;
    z-index: 20;
    display: block;
    #{$this}_txt {
      @include mq(md) {
        margin-top: 0;
      }
    }
  }
  &_list {
    position: relative;
    z-index: 20;
    display: block;
    margin-top: 4.3em;
    @include mq(md) {
      margin-top: 2em;
    }
  }
  &_item {
    display: flex;
    margin-left: -50px;
    @include mq(md) {
      display: block;
      margin: 0;
    }
    @include mq(min-md) {
      &:nth-child(odd) {
        flex-direction: row-reverse;
        margin-right: -110px;
        margin-left: 0;
        #{$this}_img {
          margin-right: 0;
          margin-left: percentage(40/840);
        }
      }
    }
    & + & {
      margin-top: 4.3em;
    }
  }
  &_img {
    width: 330px;
    max-width: 100%;
    margin-right: percentage(40/840);
    flex-shrink: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
    @include mq(md) {
      width: 258px;
      margin: 0 auto;
    }
    &Cap {
      font-size: 1.6rem;
      line-height: 1.5;
      @include mq(md) {
        font-size: 1.1rem;
      }
    }
  }
  &_txt {
    flex: auto;
    @include mq(md) {
      margin-top: 35px;
    }
  }
  &_ftBtn {
    .btn.-lg {
      > a {
        min-width: percentage(485/840);
        @include mq(md) {
          width: 335px;
        }
      }
    }
    &.-animTarget {
      &.-ready {
        .btn {
          opacity: 0;
        }
      }
      &.-action {
        .btn {
          animation: slideup 1s ease-out 0.5s both;
        }
      }
    }
  }
}

// コンテンツ下部の主要ページへのリンク
#-link------------------ {}

.philosophyLink {
  $this: &;
  &.-animTarget {
    &.-ready {
      #{$this}_item {
        opacity: 0;
      }
    }
    &.-action {
      #{$this}_item {
        @for $i from 1 through 9 {
          &:nth-child(#{$i}) {
            animation: slideup 0.5s ease-out #{$i*0.3+0.1}s both;
          }
        }
      }
    }
  }
  &_ttl {
    font-size: 3.2rem;
    text-align: center;
  }
  &_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -14px -28px;
    @include mq(md) {
      display: block;
    }
  }
  &_item {
    display: flex;
    flex-direction: column;
    width: percentage(1/3);
    padding: 0 14px 28px;
    @include mq(md) {
      width: auto;
    }
    &Img {
      img {
        width: 100%;
      }
    }
    &Txt {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      flex: auto;
      font-size: 1.6rem;
      p:not([class]) {
        margin-bottom: 0;
      }
    }
    &Ttl {
      margin-bottom: 0;
      font-size: 1.6rem;
      font-weight: bold;
      + * {
        margin-top: 6px;
      }
    }
    &Btn {
      margin-top: auto;
      text-align: right;
      a {
        text-decoration: none;
        &::after {
          content: "→";
          display: inline-block;
          width: 1.2em;
          height: 1em;
          margin-left: 0.1em;
          vertical-align: top;
          color: $linkColor;
        }
      }
    }
  }
}
