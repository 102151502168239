@charset "utf-8";

.clearfix {
  @include clearfix();
}

.container {
  @include cwLayout();
  &-inr {
    margin-right: percentage(76/920);
    margin-left: percentage(76/920);
  }
}

.-pc {
  @include mq(md) {
    display: none !important;
  }
}

.-sp {
  display: none !important;
  @include mq(md) {
    display: block !important;
  }
}

.-ib {
  display: inline-block;
}

.-txtS {
  font-size: 0.8em;
}

.-txtL {
  font-size: 1.375em;
}

.-animTarget,
.-ready,
.-action {}

.pageList {
  border-collapse: collapse;
  th,
  td {
    text-align: left;
    border: 1px solid $pale-sky-blue;
  }
  td {
    &:first-child {
      white-space: nowrap;
    }
  }
}