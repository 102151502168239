@charset "utf-8";

// ---------------------------------------------------------------------------
// Media Queries
// ---------------------------------------------------------------------------
// @mixin mq($breakpoint: medium, $rule: max, $addition: false) {
//   $breakpoint: map_get($mq-breakpoints, $breakpoint);
//   @if $rule == min and $addition {
//     $breakpoint: $breakpoint + .0625;
//   }
//   @media (#{$rule}-width: $breakpoint) {
//     @content;
//   }
// }

// media queries
$xs:  375px;
$sm:  480px;
$md:  768px;
$lg:  1087px;
$xl:  1199px;

@mixin mq($canvas) {
  @if $canvas == min-lg {
    @media only screen and (min-width: $lg + 1) { @content; }
  }
  @else if $canvas == min-md {
    @media only screen and (min-width: $md + 1) { @content; }
  }
  @else if $canvas == xs {
    @media only screen and (max-width: $xs) { @content; }
  }
  @else if $canvas == sm {
    @media only screen and (max-width: $sm) { @content; }
  }
  @else if $canvas == md {
    @media only screen and (max-width: $md) { @content; }
  }
  @else if $canvas == lg {
    @media only screen and (max-width: $lg) { @content; }
  }
  @else if $canvas == xl {
    @media only screen and (max-width: $xl) { @content; }
  }
}

// ---------------------------------------------------------------------------
// Clearfix
// ---------------------------------------------------------------------------
@mixin clearfix() {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

// ---------------------------------------------------------------------------
// 横幅をコンテンツ幅にして中央寄せ
// ---------------------------------------------------------------------------
@mixin cwLayout() {
  box-sizing: content-box;
  // max-width: $contentsW + $contentsP * 2;
  // max-width: $contentsW;
  /* max-width: 920px; */
  margin-right: auto;
  margin-left: auto;
  // padding-right: $contentsP;
  padding-right: percentage($contentsP / ($contentsW + $contentsP * 2));
  // padding-left: $contentsP;
  padding-left: percentage($contentsP / ($contentsW + $contentsP * 2));
  @include mq(lg) {
    max-width: $contentsW + $contentsP / 3 * 2;
    padding-right: $contentsP / 2;
    padding-left: $contentsP / 2;
  }
  @include mq(md) {
    // margin: 0 percentage(20/375);
    margin: 0 20px;
    padding-right: 0;
    padding-left: 0;
  }
  > * {
    box-sizing: border-box;
  }
}

// ---------------------------------------------------------------------------
// Hover時に要素を透明化
// ---------------------------------------------------------------------------
@mixin hoverOpacityEf($time: .5s, $opacity: .5) {
  a {
    transition: $time;
    &:hover {
      opacity: $opacity;
    }
  }
}


// ---------------------------------------------------------------------------
// ボタン
// ---------------------------------------------------------------------------
@mixin btn($btnColor: white) {
  display: flex;
  // align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  a.btn_outer .btn_inner,
  button:not(.btn_outer),
  > a:not(.btn_outer) {
    position: relative;
    z-index: 0;
    display: flex;
    overflow: hidden;
    min-width: 160px;
    max-width: 100%;
    min-height: 40px;
    padding: 3px 10px;
    line-height: 1.2;
    cursor: pointer;
    transition: box-shadow 0.5s 0.3s;
    text-decoration: none;
    color: $linkColor;
    border-style: none;
    border-radius: 100px;
    outline: none;
    background-color: #fff;
    box-shadow: 0px 2px 4px 1px rgba($navy, 0.2);
    justify-content: center;
    align-items: center;
    @include mq(min-md) {
      &:hover {
        color: #fff;
        box-shadow: 0px 2px 10px 1px rgba($navy, 0.3);
        &::before {
          width: 100%;
          padding-top: 100%;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 0;
      height: 0;
      transition: 0.3s;
      border-radius: 50%;
      background-color: $linkColor;
    }
  }
  @if $btnColor == blue {
    a.btn_outer .btn_inner,
    button:not(.btn_outer),
    > a:not(.btn_outer) {
      color: #fff;
      background-color: $linkColor;
      @include mq(min-md) {
        &:hover {
          color: $linkColor;
        }
      }
      &::before {
        background-color: #fff;
      }
    }
  }
  @elseif $btnColor == grad {
    a.btn_outer .btn_inner,
    button:not(.btn_outer),
    > a:not(.btn_outer) {
      color: #fff;
      background: linear-gradient(to right, #00c1cd 0%, #0092d1 100%);
      @include mq(min-md) {
        &:hover {
          color: #fff;
          &::after {
            opacity: 1;
          }
        }
      }
      &::before {
        content: none;
      }
      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        transition: opacity 0.8s;
        opacity: 0;
        background: linear-gradient(to left, #00c1cd 0%, #0092d1 100%);
        background: linear-gradient(to right, #4db3df 0%, #4db3df 100%);
      }
    }
  }
}