@charset "UTF-8";
/*------------------------------------------------------*/
/*  既存ページから3331MVスタイルを移行
/*------------------------------------------------------*/
.page3331_mv {
  width: 100%;
  min-width: 1150px;
  margin: 0 auto;

  #pl-3331 {
    .index-main {
      position: relative;
      overflow: hidden;
      height: 500px;
      margin-bottom: 152px;
      text-align: center;
      color: #fff;
      background: url("/wp-content/themes/akerun/_assets/images/landing/3331/bg2.jpg") center top no-repeat;
      background-size: cover;

      &__bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out 0s;
        opacity: 0;
        background: #000;
        filter: alpha(Opacity=0);

        &.active {
          opacity: 0.2;
          filter: alpha(Opacity=20);
        }
      }

      &__logo {
        position: absolute;
        top: 15px;
        left: 18%;
        width: 128px;
      }

      &__caption {
        position: relative;
        margin-bottom: 17px;
        padding-top: 80px;
        font-family: 'NotoSansJp-Thin';
        font-size: 41px;
        line-height: 50px;
        text-align: center;
      }

      &__img-laurel {
        position: absolute;
        top: 50px;
        right: 128px;
        width: 166px;
      }

      &__img-sa {
        position: absolute;
        top: 189px;
        right: 248px;
        width: 64px;
      }

      &__img-tc {
        position: absolute;
        top: 189px;
        right: 179px;
        width: 64px;
      }

      &__img-tp {
        position: absolute;
        top: 189px;
        right: 110px;
        width: 64px;
      }

      &__lead {
        position: relative;
        margin-bottom: 37px;
        font-size: 15px;
        text-align: center;
      }

      &__btn__play {
        position: relative;
        width: 78px;
        height: 78px;
        margin: 0 auto 28px;
        cursor: pointer;

        .index__movie__arrow {
          position: absolute;
          top: 24px;
          left: 31px;
          width: 21px;
        }
      }

      &__btn__dl {
        position: relative;
        width: 375px;
        height: 120px;
        margin: 0 auto;
        cursor: pointer;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out 0s;
        -webkit-transition-delay: 0s;
        -o-border-radius: 5px;
        border-radius: 5px;
        background: linear-gradient(to right, #00c1cd 0%, #0092d1 100%);
  
        &:hover {
          background: linear-gradient(#4dd4dc 0%, #4db3df 100%);
        }

        &__txt {
          margin-bottom: 12px;
          padding-top: 21px;
          font-size: 23px;
          line-height: 30px;
          text-align: center;
          color: #fff;
        }
        &__en {
          width: 65px;
          margin: 0 auto;
          img{
            display: block;
            max-width: 100%;
            height: auto;
          }
        }
        &__icon {
          position: absolute;
          right: 9px;
          bottom: 9px;
          width: 17px;
          height: 17px;
          font-size: 0;
          line-height: 0;
        }
      }
    }
    #pg-3331-0,
    #pg-3331-1,
    #pg-3331-2,
    #pg-3331-3,
    #pg-3331-4,
    #pg-3331-5,
    #pl-3331 .panel-grid-cell .so-panel {
      margin-bottom: 30px;
    }
    #pg-3331-0 .panel-grid-cell,
    #pg-3331-2 .panel-grid-cell,
    #pg-3331-3 .panel-grid-cell,
    #pg-3331-4 .panel-grid-cell,
    #pg-3331-5 .panel-grid-cell,
    #pg-3331-6 .panel-grid-cell {
      float: none;
    }
    #pgc-3331-1-0,
    #pgc-3331-1-1 {
      width: 50%;
    }
    #pg-3331-1 .panel-grid-cell {
      float: left;
    }
    #pl-3331 .panel-grid-cell .so-panel:last-child {
      margin-bottom: 0px;
    }
    #pg-3331-0,
    #pg-3331-1,
    #pg-3331-2,
    #pg-3331-3,
    #pg-3331-4,
    #pg-3331-5,
    #pg-3331-6 {
      margin-right: -15px;
      margin-left: -15px;
    }
    #pg-3331-0 .panel-grid-cell,
    #pg-3331-1 .panel-grid-cell,
    #pg-3331-2 .panel-grid-cell,
    #pg-3331-3 .panel-grid-cell,
    #pg-3331-4 .panel-grid-cell,
    #pg-3331-5 .panel-grid-cell,
    #pg-3331-6 .panel-grid-cell {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .modal {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
  }
  .modal-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background: #000;
    filter: alpha(opacity=90);
  }
  .modal-inner,
  .modal-player {
    width: 800px;
    height: 450px;
  }
  .modal-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -225px;
    margin-left: -400px;
  }
}

@media only screen and (max-width: 768px) {
  .page3331_mv {
    min-width: 100%;
    #pl-3331 {

      .index-main {
        height: 100%;
        margin-bottom: 68px;
        padding-bottom: 30px;

        &__logo {
          top: 15px;
          left: 15px;
          width: 23.7%;
        }
        &__lead {
          margin-bottom: 60%;
        }
        &__caption {
          padding-top: 70px;
          font-size: 33px;
          line-height: 40px;
        }
        &__btn__play {
          display: none;
        }
        &__img-laurel {
          top: 260px;
          right: 0;
          left: 0;
          width: 161px;
          margin: auto;
        }
        &__imgblock{
          position: absolute;
          top: 395px;
          display: flex;
          width: 100%;
          justify-content: center;
        }
        &__img-sa {
          position: static;
          width: 64px;
        }
        &__img-tc {
          position: static;
          width: 64px;
          margin: 0 5px;
        }
        &__img-tp {
          position: static;
          width: 64px;
        }
        &__btn__dl {
          width: 76%;
          height: 90px;
          margin-top: 10%;
          &__txt{
            margin-bottom: 8px;
            padding-top: 12px;
            font-size: 17px;
            line-height: 24px;
          }
        }
      }


      #pg-3331-0 .panel-grid-cell,
      #pg-3331-1 .panel-grid-cell,
      #pg-3331-2 .panel-grid-cell,
      #pg-3331-3 .panel-grid-cell,
      #pg-3331-4 .panel-grid-cell,
      #pg-3331-5 .panel-grid-cell,
      #pg-3331-6 .panel-grid-cell {
        float: none;
        width: auto;
      }
      #pgc-3331-1-0 {
        margin-bottom: 30px;
      }
      #pl-3331 .panel-grid,
      #pl-3331 .panel-grid-cell {
      }
    }
  }
}
