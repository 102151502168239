@charset "utf-8";

.l-main {
  @include mq(lg) {
    padding-top: 55px;
  }
  .main-header,
  .main-body {
    padding-left: 200px;
    @include mq(lg) {
      padding-left: 0;
    }
  }
  .breadcrumb {
    position: absolute;
    z-index: 950;
    top: 0;
    left: 0;
    width: 100%;
    @include mq(min-lg) {
      padding: 24px 30px 24px 230px;
    }
    @include mq(lg) {
      position: fixed;
      top: 55px;
      left: 0;
      display: none;
    }
  }
}

.breadcrumb {
  font-size: 1.2rem;
  color: $baseColor;
  @include mq(lg) {
    padding: 0.25em 10px;
    color: #fff;
    background-color: rgba($grey-blue,0.9);
    a {
      color: #fff !important;
    }
  }
  &.-black {
    &,
    a {
      @include mq(min-lg) {
        color: $baseColor;
      }
    }
  }
  &.-grey {
    @include mq(min-lg) {
      position: relative;
      color: #fff;
      background-color: $grey-blue;
      a {
        color: #fff;
      }
    }
  }
  &.-foot {
    @include mq(min-lg) {
      display: none;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    letter-spacing: -0.4em;
    li {
      display: inline-block;
      letter-spacing: normal;
      + li {
        &::before {
          content: "/";
          margin: 0 0.3em;
        }
      }
    }
  }
  a {
    text-decoration: underline;
    color: $baseColor;
    @include mq(md) {
      color: #fff;
    }
  }
}

.main-header {
  &.-normal {
    position: relative;
    overflow: hidden;
    color: $baseColor;
    background: linear-gradient(to right, $very-light-blue, $pale-grey);
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -30px;
      display: block;
      width: 770px;
      height: 770px;
      margin-top: -385px;
      border-radius: 50%;
      background-color: $pale-grey;
      box-shadow: 0 0 20px 0 rgba($pale-sky-blue, 0.15);
      @include mq(lg) {
        left: -10%;
      }
      @include mq(md) {
        left: -117px;
        width: 407px;
        height: 407px;
        margin-top: -203.5px;
      }
    }
    .main-header_panel {
      min-height: 287px;
      @include mq(md) {
        min-height: 303px;
      }
    }
    .ttl-page {
      margin: 0;
      font-size: 1.6rem;
      font-weight: 500;
      color: $linkColor;
      &::after {
        content: "";
        display: block;
        width: 80px;
        height: 2px;
        margin-top: 20px;
        background-color: $navy;
      }
      &_en {
        margin: 0;
        font-size: 4.2rem;
        font-weight: normal;
        line-height: 1.1;
        @include mq(md) {
          font-size: 3.5rem;
        }
        + * {
          margin-top: 10px;
        }
      }
    }
    .brackets {
      font-size: 4.2rem;
      font-weight: normal;
      color: $baseColor;
      @include mq(md) {
        font-size: 3.5rem;
        line-height: 1.2;
      }
      &::after {
        content: none;
      }
      &_inr {
        position: relative;
        z-index: 5;
        display: inline-block;
        &::before,
        &::after {
          // content: "";
          position: absolute;
          z-index: -1;
          display: block;
          width: 30px;
          height: 100%;
          text-indent: -999em;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
        &::before {
          // content: "“";
          top: -0.1em;
          left: -0.1em;
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/ttl-brackets_before@2x.png);
          background-position: top center;
        }
        &::after {
          // content: "”";
          right: -0.1em;
          bottom: -0.1em;
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/ttl-brackets_after@2x.png);
          background-position: bottom center;
        }
      }
    }
  }
  &_inr {
    position: relative;
    max-width: none;
    height: 100%;
    .container {
      height: 100%;
    }
  }
  &_panel {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    // text-align: center;
    justify-content: center;
  }
}

.ttl-page {
  font-size: 4.2rem;
  font-weight: normal;
  line-height: 1.5;
  @include mq(md) {
    font-size: 3.5rem;
  }
  &_sub {
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 1.5;
    @include mq(md) {
      font-size: 1.8rem;
    }
    &.-lg {
      margin-top: 10px;
      font-size: 2.8rem;
      @include mq(md) {
        font-size: 2.6rem;
      }
    }
    + .ttl-page {
      margin-top: 5px;
    }
  }
}

.main-body {
  // padding: 40px 0 96px;
}

[class^="sec-"] {
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 200px;
  @include mq(lg) {
    padding-left: 0;
  }
  @include mq(md) {
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 0;
  }
  .breadcrumb + & {
    @include mq(min-md) {
      padding-top: 100px;
    }
  }
  &:last-child {
    padding-bottom: 100px;
    @include mq(md) {
      padding-bottom: 40px;
    }
  }
  .main-header + & {
    padding-top: 40px;
  }
}