// サービス・製品トップ
#-entry_and_exit_top------------------ {}

.main-header {
  .p-entry_and_exit & {
    position: relative;
    background-color: $pale-grey;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      padding-top: percentage(105/1080);
      background: linear-gradient(to top, #9e9e9e 0%, rgba(#9e9e9e,0) 100%);
    }
    &_panel {
      overflow: hidden;
    }
    .container {
      @include mq(md) {
        margin: 0;
      }
    }
  }
  .p-visitor_and_reception & {
    background-color: #ddecef;
    &_inr {
      z-index: 10;
      background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/service_product/mvBg-visitorAndReception_pc.png);
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
      &::before,
      &::after {
        content: "";
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
      }
      &::before {
        z-index: 5;
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/service_product/mvBg-visitorAndReception_pc.png);
        background-position: left bottom;
        @include mq(md) {
          content: none;
        }
      }
      &::after {
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/service_product/mvImg-visitorAndReception_pc.png);
        background-position: right bottom;
        @include mq(md) {
          position: relative;
          height: auto;
          margin-top: -30%;
          padding-top: percentage(895/750);
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/service_product/mvBg-visitorAndReception_sp.png);
        }
      }
    }
    &_panel {
      z-index: 10;
    }
    .container {
      margin: 0;
    }
    .header-productService {
      &_img {
        right: 0;
        bottom: 0;
        display: none;
        width: auto;
        height: 100%;
      }
      &_txt {
        @include mq(md) {
          // padding-top: 0;
          padding-top: 30px;
        }
      }
      .ttl-page {
        font-size: 3.4rem;
        font-weight: 500;
        @include mq(md) {
          font-size: 2.2rem;
        }
        &_sub {
          @include mq(md) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

.header-productService {
  overflow: hidden;
  @include mq(md) {
    flex-direction: column;
    margin: 0 20px;
  }
  &::before {
    content: "";
    display: block;
    padding-top: percentage(460/920);
    @include mq(md) {
      content: none;
    }
  }
  &_img {
    position: absolute;
    z-index: 5;
    right: 0;
    bottom: -20px;
    width: percentage(561/854);
    @include mq(md) {
      position: static;
      width: auto;
      margin-top: 15%;
    }
  }
  &_txt {
    position: absolute;
    z-index: 10;
    top: percentage(70/460);
    left: 0;
    width: 100%;
    text-align: left;
    @include mq(md) {
      position: static;
      width: auto;
      padding-top: percentage(30/325);
    }
    .ttl-page {
      margin: 0;
      line-height: 1.2;
      @include mq(md) {
        font-size: 3.2rem;
      }
      &_sub {
        margin-top: 0.8em;
        // width: percentage(280/(854));
        line-height: 1.5;
        @include mq(md) {
          width: auto;
        }
      }
    }
  }
}

.sec-productServiceLead {
  background-color: #fff;
  @include mq(md) {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}

.sec-productServiceTab {
  background-color: $pale-grey;
  @include mq(md) {
    padding-top: 60px;
  }
}

.productServiceTab {
  &_tabGroup {
    display: flex;
    margin: 0;
    padding-left: 0;
    list-style: none;
  }
  &_tab {
    width: percentage(1/2);
    font-size: 2rem;
    a {
      position: relative;
      display: flex;
      min-height: 65px;
      font-weight: bold;
      text-decoration: none;
      border-radius: 10px 10px 0 0;
      background-color: $very-light-blue;
      align-items: center;
      justify-content: center;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 0%;
        height: 2px;
        background-color: $linkColor;
      }
      &:hover {
        &::after {
          width: 100%;
          transition: width 0.2s;
        }
      }
    }
    &.-active,
    &.current {
      a {
        color: $navy;
        background-color: #fff;
        &::after {
          width: 100%;
          transition: none;
          background-color: $navy;
        }
      }
    }
  }
  &_panelGroup {}
  &_panel {
    display: none;
    padding: 30px 30px;
    background-color: #fff;
    @include mq(md) {
      padding: 40px percentage(15/335);
    }
    &.-active,
    &.current {
      display: block;
    }
  }
  &_panelInr {}
  &_head {
    padding-top: 30px;
    font-size: 1.6rem;
    line-height: 1.8;
    text-align: center;
    @include mq(md) {
      padding-top: 0px;
      text-align: left;
    }
    + * {
      margin-top: 60px;
    }
    &Ttl {
      margin-bottom: 0;
      font-size: 2.8rem;
      font-weight: normal;
      line-height: 1.5;
      @include mq(md) {
        font-size: 2.4rem;
        text-align: center;
      }
    }
  }
  &_slide {
    position: relative;
    width: percentage(760/860);
    margin: 30px auto 0;
    margin-bottom: percentage(104/860);
    @include mq(md) {
      width: auto;
      margin-top: 40px;
      margin-bottom: percentage(94/305);
      text-align: center;
    }
    &List {
      @include mq(md) {
      }
      position: absolute;
      top: percentage(21/432);
      width: 100%;
      margin: 0 auto;
      padding-left: 0;
      list-style: none;
      .slick {
        &-list {
          width: percentage(562/760);
          margin: 0 auto;
        }
        &-slide {
          outline: none;
        }
        &-arrow {
          position: absolute;
          z-index: 10;
          top: 0;
          bottom: 3.5em;
          width: 40px;
          height: 40px;
          margin: auto;
          padding: 0;
          text-indent: -999em;
          border: 1px solid $very-light-blue;
          border-radius: 50%;
          background-color: transparent;
          background-color: #fff;
          background-image: url();
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 14px;
          box-shadow: 0 2px 4px 0 rgba($navy, 0.2);
          @include mq(md) {
            width: 30px;
            height: 30px;
            background-size: 12px;
          }
        }
        &-prev {
          left: -20px;
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/arw-left@2x.png);
          @include mq(md) {
            left: -5px;
          }
        }
        &-next {
          right: -20px;
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/arw-right@2x.png);
          @include mq(md) {
            right: -5px;
          }
        }
        &-disabled {
          display: none !important;
        }
      }
    }
    &Cap {
      margin-top: percentage(71/560);
      line-height: 1.5;
      @include mq(md) {
        margin-top: percentage(32/218);
        font-size: 1.4rem;
      }
    }
  }
  &_list {
    + * {
      margin-top: 30px;
      @include mq(md) {
        margin-top: 60px;
      }
    }
    &Item {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      @include mq(md) {
        display: block;
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
      }
      & + & {
        margin-top: 30px;
        @include mq(md) {
          margin-top: 40px;
        }
      }
    }
    &Img {
      width: percentage(1/2);
      @include mq(md) {
        width: auto;
      }
    }
    &Txt {
      width: percentage(400/860);
      padding-bottom: 30px;
      @include mq(md) {
        width: auto;
        margin-top: 5px;
        padding-bottom: 0;
      }
    }
    &Ttl {
      margin-bottom: 0;
      font-size: 2rem;
      @include mq(md) {
        font-size: 1.8rem;
        text-align: center;
      }
      + * {
        margin-top: 1em;
      }
    }
    &Btn {
      @include btn(blue);
      justify-content: flex-start;
      margin-top: 30px;
      @include mq(md) {
        margin-top: 20px;
        justify-content: center;
        a {
          width: 100%;
          max-width: 300px;
          font-size: 1.3rem;
        }
      }
    }
  }
  .relatedList {
    padding-bottom: 50px;
    @include mq(md) {
      padding-bottom: 20px;
    }
    &_list {
      @include mq(md) {
        margin: -10px -7.5px;
      }
    }
    &_item {
      @include mq(md) {
        padding: 10px 7.5px;
      }
    }
  }
}

.security {
  position: relative;
  overflow: hidden;
  margin-left: -20px;
  padding-left: 20px;
  background-color: $grey-blue;
  background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/service_product/bg-security_pc.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include mq(md) {
    background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/service_product/bg-security_sp.jpg);
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 300%;
    transform: translateY(-50%) rotate(30deg);
    transform-origin: left center;
    background-color: rgba(#fff,0.5);
    @include mq(md) {
      transform: translateY(-50%) rotate(210deg);
      background-color: rgba(#d0d1d2,0.8);
    }
  }
  &_inr {
    position: relative;
    z-index: 10;
    display: flex;
    padding: 54px 0;
    align-items: center;
    justify-content: space-between;
    @include mq(md) {
      display: block;
    }
  }
  &_img {
    width: percentage(360/920);
    text-align: center;
    @include mq(md) {
      width: auto;
    }
    img {
      width: 360px;
    }
  }
  &_txt {
    width: percentage(360/920);
    font-size: 1.6rem;
    @include mq(md) {
      width: auto;
      margin-top: 40px;
    }
  }
  &_ttl {
    margin: 0;
    font-size: 2.8rem;
    line-height: 1.2;
    @include mq(md) {
      font-size: 2.4rem;
    }
    + * {
      margin-top: 20px;
    }
  }
}

// サービス
#-entry_and_exit_service------------------ {}

.sec-productServiceFunc {
  .relatedList_ttl {
    // text-align: center;
  }
}
