.sec-diagnosisSelf{
  padding-bottom: 0;

  .diagnosisSelf-lead{
    font-size: 1.6rem;
    text-align: center;
    @include mq(md) {
      padding-left: 0;
    }

    &_notice{
      font-size: 1.2rem;
      text-align: center;
    }
  }
}

.sec-diagnosisSelf_flow{
  .container{
    @include mq(md) {
      margin: 0;
    }
  }
  .diagnosisSelf_flowList {
    display: block;
    &_item {
      position: relative;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      padding: percentage(40/920);
      font-size: 1.6rem;
      line-height: 1.8;
      @include mq(md) {
        display: block;
        padding: 40px 20px;
      }
      &:first-child {
        padding-top: 0;
      }
      &:nth-child(even) {
        background-color: $pale-grey;
      }
      &::before {
        content: "";
        position: absolute;
        top: 40px;
        left: percentage(40/920);
      }
      &Head {
        display: flex;
        align-items: flex-start;
        width: percentage(530/980);
        @include mq(md) {
          width: auto;
        }
        p{
          margin-top: 0;
        }
      }
      &Body {
        width: percentage(250/980);
        max-width: 250px;
        margin-top: 0;
        padding-left: 0;
        @include mq(md) {
          width: auto;
          max-width: none;
          padding: 30px 0 0 70px;
        }
      }
      &:first-child {
        .diagnosisSelf_flowList_num {
          &::before {
            content: none;
          }
        }
      }
      &:last-child {
        .diagnosisSelf_flowList_num {
          &::after {
            content: none;
          }
        }
      }
    }
    &_num {
      position: relative;
      display: flex;
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin-right: 40px;
      font-size: 2rem;
      color: #fff;
      border-radius: 50%;
      background-color: $grey-blue;
      align-items: center;
      justify-content: center;
      @include mq(md) {
        margin-right: 20px;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        display: block;
        width: 2px;
        height: 999em;
        margin: auto;
        background-color: $grey-blue;
      }
      &::before {
        bottom: 100%;
      }
      &::after {
        top: 100%;
      }
    }
  }
}
