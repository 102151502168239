@charset "utf-8";

html,
body {
  -webkit-text-size-adjust: 100%;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: $baseFont;
  font-size: 1.4rem;
  line-height: 1.8;
  word-wrap: break-word;
  color: $baseColor;
  background-color: $bgColor;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

a {
  // text-decoration: none;
  color: $linkColor;
  outline: none;
}

button {
  cursor: pointer;
  outline: none;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

picture {
  display: block;
}

table {
  border-collapse: collapse;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl {
  font-weight: normal;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}