// ニューストップ
#-news_top------------------ {}

.sec-newsTop {
}

.newsList {
  &_lead {
    font-size: 2rem;
    @include mq(md) {
      font-size: 1.8rem;
    }
    + * {
      margin-top: 60px;
    }
  }
  &_cate {
    + * {
      margin-top: 40px;
    }
    &List {
      display: flex;
      margin: 0;
      padding-left: 0;
      list-style: none;
      justify-content: space-between;
      @include mq(md) {
        flex-wrap: wrap;
        &,
        &:first-child {
          margin: -10px 0;
        }
      }
    }
    &Item {
      flex: 1;
      @include mq(md) {
        width: percentage(158/335);
        flex: none;
        margin-left: 0;
        padding: 10px 0;
        &:nth-child(odd) {
          margin-right: percentage(19/335);
        }
      }
      & + & {
        margin-left: percentage(22/920);
        @include mq(md) {
          margin-left: 0;
        }
      }
      a {
        position: relative;
        display: flex;
        height: 40px;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 0%;
          height: 1px;
          transition: width 0.3s;
          background-color: $linkColor;
        }
        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
      &.-active,
      &.current {
        a {
          background-color: $pale-grey;
          &:hover {
            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
  &_list {
    border-top: 1px solid $pale-sky-blue;
  }
  &_item {
    padding: 20px percentage(10/920);
    font-size: 1.6rem;
    border-bottom: 1px solid $pale-sky-blue;
    @include mq(md) {
      padding: 20px 0;
      font-size: 1.4rem;
    }
    &Info {
      display: flex;
      @include mq(md) {
        display: block;
      }
    }
    &Date {
      margin: 0;
      line-height: 22px;
      white-space: nowrap;
      color: $grey-blue;
    }
    &Cate {
      margin-left: 15px;
      @include mq(md) {
        margin-top: 10px;
        margin-left: 0;
      }
      ul {
        display: flex;
        margin: 0;
        margin: -5px -5px;
        padding: 0;
        list-style: none;
        flex-wrap: wrap;
      }
      li {
        padding: 5px 5px;
        a {
          display: flex;
          width: 104px;
          height: 22px;
          font-size: 1.2rem;
          transition: 0.2s;
          text-decoration: none;
          color: $linkColor;
          background-color: $pale-grey;
          align-items: center;
          justify-content: center;
          @include mq(min-md) {
            &:hover {
              color: #fff;
              background-color: $linkColor;
              // border: 1px solid $linkColor;
            }
          }
        }
      }
    }
    &Ttl {
      @include mq(md) {
        margin-top: 10px;
      }
      a {
        transition: color 0.1s;
        text-decoration: none;
        color: $baseColor;
        &:hover {
          text-decoration: underline;
          color: $linkColor;
        }
      }
    }
  }
}

// ニュース詳細
#-news_detail------------------ {}

.sec-newsDetail {
  background-color: $pale-grey;
  @include mq(md) {
    padding-top: 0;
  }
  .container {
    @include mq(md) {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.newsDetail {
  &_panel {
    padding: 64px percentage(76/920) 100px;
    background-color: #fff;
    @include mq(md) {
      padding-right: 20px;
      padding-bottom: 60px;
      padding-left: 20px;
    }
    + .btn {
      margin-top: 82px;
      @include mq(md) {
        margin-top: 40px;
      }
    }
  }
  &_head {
    margin-bottom: 40px;
    &Info {
      display: flex;
      + * {
        margin-top: 5px;
        @include mq(md) {
          margin-top: 10px;
        }
      }
    }
    &Date {
      margin: 0 20px 0 0;
    }
    &Cate {
      margin: 0;
      a {
        text-decoration: none;
      }
    }
  }
  &_ttl {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.5;
    @include mq(md) {
      font-size: 2.8rem;
    }
  }
  &_body {
    p {
      margin-top: 20px;
      margin-bottom: 20px;
      &:first-child {
        margin-top: 0;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      &:first-child {
        margin-top: 0;
      }
      + * {
        margin-top: 10px;
      }
    }
    h1 {
      margin-top: 80px;
      padding: percentage(10/768) percentage(20/768);
      font-size: 2rem;
      font-weight: bold;
      color: #fff;
      background-color: $grey-blue;
      @include mq(md) {
        font-size: 1.8rem;
      }
      + * {
        margin-top: 20px;
      }
    }
    h2 {
      margin-top: 60px;
      padding-bottom: 0.4em;
      font-size: 2rem;
      font-weight: bold;
      border-bottom: 2px solid $grey-blue;
      @include mq(md) {
        margin-top: 40px;
      }
      + * {
        margin-top: 1em;
      }
    }
    h3 {
      margin-top: 40px;
      font-size: 2rem;
      font-weight: bold;
      color:$turquoise-blue;
    }
    h4 {
      padding-left: 0.4em;
      font-size: 2rem;
      border-left: 4px solid $grey-blue;
    }
    h5 {
      font-size: 2rem;
    }
    h6 {
      font-size: 1.4rem;
      font-weight: bold;
    }
    blockquote {
      margin-right: 0;
      margin-left: 0;
      padding: percentage(20/768) percentage(30/768);
      background-color: $pale-grey;
      @include mq(md) {
        padding: percentage(20/335) percentage(20/335);
      }
    }
    ul {
      padding-left: 1.5em;
    }
    ol {
      padding-left: 1.5em;
    }
    li {
      + li {
        margin-top: 0.28em;
      }
    }
    pre {
      padding: percentage(20/768) percentage(30/768);
      font-family: monospace,serif;
      white-space: pre-wrap;
      background-color: $pale-grey;
      @include mq(md) {
        padding: percentage(20/335) percentage(20/335);
      }
    }
  }
  &_sns {
    margin-top: 64px;
  }
}

.movie {
  position:relative;
  width:100%;
  // max-width: 854px;
  &::before {
    content: "";
    display: block;
    padding-top:56.25%;
  }
  iframe{
    position:absolute;
    top:0;
    right:0;
    width:100%;
    height:100%;
  }
}

.snsList {
  &_ttl {
    font-size: 1.4rem;
    font-weight: bold;
  }
  &_list {
    display: flex;
    margin: 0;
    padding-left: 0;
    list-style: none;
    border-style: solid none none solid;
  }
  &_list,
  &_item {
    border-width: 1px;
    // border-color: #707070;
    border-color: $pale-sky-blue;
  }
  &_item {
    // width: percentage(1/3);
    height: 40px;
    border-style: none solid solid none;
    flex: 1;
    a {
      display: flex;
      width: 100%;
      height: 100%;
      text-indent: -999em;
      background-image: url();
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 26px;
      align-items: center;
      justify-content: center;
    }
    &.-Facebook a {
      background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/news/icon-facebook@2x.png);
    }
    &.-Twitter a {
      background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/news/icon-twitter@2x.png);
    }
    &.-Line a {
      background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/news/icon-line.png);
    }
    &.-Mail a {
      background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/news/icon-mail@2x.png);
    }
  }
}