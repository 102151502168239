@charset "UTF-8";
/*------------------------------------------------------*/
/*  既存ページから5551MVスタイルを移行
/*------------------------------------------------------*/
.page5551_mv {
  width: 100%;
  margin: 0 auto;

  #pl-5551 {
    .akerun_lp_template {
      &.hero2 {
        margin-top: 0;
        .hero-main {
          background-color: #c6c7cc;
          background-image: url(/wp-content/themes/akerun/_assets/images/landing/5551/main-visual-background-image2_product1.jpg);
          background-repeat: no-repeat;
          background-position: top center;

          .hero_inner {
            position: relative;
            width: 980px;
            height: 502px;
            margin: 0 auto;

            .hero_left_column {
              position: absolute;
              bottom: 0;
              left: 107px;
              width: 369px;
            }

            .hero_right_column {
              position: absolute;
              top: 65px;
              right: 0;

              .hero_text {
                font-size: 28px;
                font-weight: 700;
                line-height: 1.9;
                letter-spacing: 3px;
                color: #1580a8;
              }

              .hero_logos {
                margin-top: 37px;
                text-align: center;

                .hero_logo {
                  display: inline-block;
                  width: 148px;
                  margin-right: 20px;
                }
              }
            }

            .form-link {
              position: absolute;
              bottom: 34px;
              left: 342px;

              a {
                font-size: 22px;
                font-weight: normal;
                transition: 0.2s;
                color: white;
                background: linear-gradient(to right, #00c1cd 0%, #0092d1 100%);
  
                &:hover {
                  background: linear-gradient(#4dd4dc 0%, #4db3df 100%);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .page5551_mv {
    min-width: 100%;

    #pl-5551 {
      .akerun_lp_template {
        &.hero2 {
          .hero-main {
            background-size: cover;

            .hero_inner {
              width: 100%;
              height: auto;
              padding: 40px 4% 0;

              .hero_left_column {
                position: static;
                width: 86%;
                margin-top: 10px;
                margin-left: 10%;
              }

              .hero_right_column {
                position: static;
                width: 100%;

                .hero_text {
                  font-size: 5.5vw;
                }

                .hero_logos {
                  margin-top: 20px;
                  text-align: left;

                  .hero_logo {
                    width: 70px;
                  }
                }
              }

              .form-link {
                position: static;
                margin-top: -90px;
                padding-bottom: 30px;
                text-align: center;

                a {
                  font-size: 5vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
