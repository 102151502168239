// Akerun導入までの流れ
#-flow------------------ {}

.sec-flow {
  .container {
    @include mq(md) {
      margin: 0;
    }
  }
}

.flowList {
  $this: &;
  display: block;
  &_item {
    position: relative;
    overflow: hidden;
    padding: percentage(40/920);
    font-size: 1.6rem;
    line-height: 1.8;
    @include mq(md) {
      padding: 40px 20px;
    }
    &:first-child {
      padding-top: 0;
    }
    &:nth-child(even) {
      background-color: $pale-grey;
    }
    &::before {
      content: "";
      position: absolute;
      top: 40px;
      left: percentage(40/920);
    }
    &Head {
      display: flex;
      align-items: center;
    }
    &Body {
      margin-top: 1em;
      padding-left: 90px;
      @include mq(md) {
        padding-left: 70px;
      }
    }
    &:first-child {
      #{$this}_num {
        &::before {
          content: none;
        }
      }
    }
    &:last-child {
      #{$this}_num {
        &::after {
          content: none;
        }
      }
    }
    .btnSet {
      margin-top: 1em;
      &_list {
        margin-top: -10px;
        justify-content: flex-start;
      }
      .btn {
        @include mq(md) {
          width: fit-content;
          max-width: 100%;
        }
      }
    }
  }
  &_num {
    position: relative;
    display: flex;
    width: 50px;
    min-width: 50px;
    height: 50px;
    margin-right: 40px;
    font-size: 2rem;
    color: #fff;
    border-radius: 50%;
    background-color: $grey-blue;
    align-items: center;
    justify-content: center;
    @include mq(md) {
      margin-right: 20px;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      display: block;
      width: 2px;
      height: 999em;
      margin: auto;
      background-color: $grey-blue;
    }
    &::before {
      bottom: 100%;
    }
    &::after {
      top: 100%;
    }
  }
  &_ttl {
    margin: 0;
    font-size: 2.8rem;
    line-height: 1.5;
    @include mq(md) {
      font-size: 2.4rem;
    }
  }
  .btn {
    // @include btn(blue);
    justify-content: flex-start;
    @include mq(md) {
      flex-direction: column;
    }
    &:not(.-blue) {
      // @include btn(grad);
      // justify-content: flex-start;
    }
    a {
      width: percentage(295/750);
      width: 295px;
      min-width: 200px;
      @include mq(md) {
        min-width: auto;
      }
      + a {
        margin-left: percentage(20/750);
        @include mq(md) {
          margin: 20px 0 0;
          margin-left: 0;
        }
      }
    }
  }
}
