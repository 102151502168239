// 課題別ソリューションTOP
#-solution_top------------------ {}

.solutionTop {
  $this: &;
  &_list {
    display: flex;
    flex-wrap: wrap;
    margin: -13.5px -13.5px;
    @include mq(md) {
      display: block;
    }
  }
  &_item {
    width: percentage(1/3);
    padding: 13.5px 13.5px;
    @include mq(md) {
      width: auto;
      width: 84%;
      margin: 0 auto;
    }
    &Link {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 15px 15px;
      transition: 0.3s;
      text-decoration: none;
      color: $navy;
      border-radius: 10px;
      box-shadow: 0 0 6px 0 rgba($navy, 0.3);
      @include mq(md) {
        padding: percentage(10/335) percentage(15/335);
      }
      .-initial {
        opacity: 1;
      }
      .-hover {
        opacity: 0;
      }
      &:hover {
        @include mq(min-md) {
          color: #fff;
          background-color: $linkColor;
          .-initial {
            opacity: 0;
          }
          .-hover {
            opacity: 1;
          }
          #{$this}_itemArw {
            color: #fff;
            p {
              &::after {
                background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/arw-link_w@2x.png);
              }
            }
          }
        }
      }
    }
    &Img {
      position: relative;
      overflow: hidden;
      width: percentage(209/288);
      width: percentage(85/148);
      // width: percentage(250/288);
      margin: 0 auto;
      @include mq(md) {
        width: percentage(158/260);
      }
      &::before {
        content: "";
        display: block;
        padding-top: 100%;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @include mq(md) {
          transform: scale(1.2);
        }
      }
    }
    &Txt {
      margin-bottom: auto;
    }
    &Arw {
      margin-top: 1em;
      text-align: right;
      color: $linkColor;
      p {
        line-height: 1.5;
        &::after {
          content: "";
          display: inline-block;
          width: 1.2em;
          height: 1em;
          margin: 0.25em 0 0.25em 0.3em;
          margin-left: 0.3em;
          vertical-align: top;
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/arw-link@2x.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
      }
    }
    &Ttl {
      display: flex;
      min-height: 3em;
      margin-bottom: 0;
      font-size: 2rem;
      line-height: 1.5;
      align-items: center;
      justify-content: center;
      text-align: center;
      @include mq(md) {
        display: block;
        min-height: auto;
        font-size: 1.8rem;
      }
      + * {
        margin-top: 15px;
      }
    }
  }
}


// パーソナライズドコンテンツ
#-personalized------------------ {}

.selectLink {
  @include mq(md) {
    margin-right: -20px;
    margin-left: -20px;
  }
  + * {
    margin-top: 60px;
  }
  &_list {
    display: flex;
    margin: 0;
    margin: -10px -10px;
    padding-left: 0;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center;
    @include mq(md) {
      margin: 0;
    }
  }
  &_item {
    width: 155px;
    max-width: percentage(1/3);
    padding: 10px 10px;
    @include mq(md) {
      padding: 0;
    }
    a {
      position: relative;
      display: flex;
      min-height: 40px;
      text-decoration: none;
      align-items: center;
      justify-content: center;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 0%;
        height: 2px;
        background-color: $linkColor;
      }
      &:hover {
        &::after {
          width: 100%;
          transition: width 0.2s;
        }
      }
    }
    &.-active,
    &.current {
      a {
        background-color: $pale-grey;
        &::after {
          content: none;
        }
      }
    }
  }
}

.personalizedGrid {
  $this: &;
  // overflow: hidden;
  margin-top: 60px;
  & + & {
  }
  &_wrap {
    display: flex;
    margin: 0px -20px -40px;
    flex-wrap: wrap;
    @include mq(md) {
      margin: 0px -9.5px -40px;
    }
  }
  &_col {
    padding: 0px 20px 40px;
    @include mq(md) {
      padding: 0px 9.5px 40px;
    }
  }
  &_inr {
    position: relative;
  }
  &_img {
    img {
      width: 100%;
    }
  }
  &_txt {
    margin-top: 10px;
    font-size: 1.6rem;
    line-height: 1.8;
    .btn {
      margin-top: 1.5em;
      justify-content: flex-start;
      a {
        @include mq(md) {
          width: 160px;
          min-width: auto;
        }
      }
    }
  }
  &_ttl {
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 1.5;
    + * {
      margin-top: 10px;
    }
  }
  &.-grid1 & {
    &_txt {
      margin-top: 20px;
    }
    &_ttl {
      margin-bottom: 0;
      font-size: 2.8rem;
      line-height: 1.5;
      @include mq(md) {
        font-size: 2.4rem;
      }
      + * {
        margin-top: 20px;
        @include mq(md) {
          margin-top: 0.5em;
        }
      }
    }
  }
  &.-grid2 & {
    &_wrap {
      display: block;
    }
    &_col {
      width: auto;
      &:nth-child(odd) {
        #{$this}_inr {
          flex-direction: row-reverse;
        }
      }
      & + & {
        margin-top: 30px;
        @include mq(md) {
          margin-top: 40px;
        }
      }
    }
    &_inr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mq(md) {
        display: block;
      }
    }  
    &_img {
      width: 50%;
      @include mq(md) {
        width: auto;
      }
    }
    &_txt {
      width: percentage(400/920);
      font-size: 1.6rem;
      line-height: 1.8;
      @include mq(md) {
        width: auto;
        margin-top: 20px;
      }
    }
    &_ttl {
      margin-bottom: 0;
      font-size: 2.8rem;
      line-height: 1.5;
      @include mq(md) {
        font-size: 2.4rem;
      }
      + * {
        // margin-top: 20px;
      }
    }
    .btn {
      justify-content: flex-start;
      margin-top: 1.5em;
    }
  }
  &.-grid3 &,                          
  &.-grid4 & {
    &_img {
      width: 200px;
      max-width: 50%;
      margin: 0 auto 20px;
    }
  }
  &.-grid3 & {
    &_col {
      width: percentage(1/3);
      @include mq(md) {
        width: percentage(1/1);
      }
    }
  }
  &.-grid4 & {
    &_col {
      width: percentage(1/4);
      @include mq(md) {
        width: percentage(1/2);
      }
    }
    &_img {
      @include mq(md) {
        max-width: 70%;
      }
    }
    &_txt {
      font-size: 1.4rem;
    }
    &_ttl {
      font-size: 1.6rem;
    }
  }
}

// モジュールをボタンではなく矢印のものに変更。上記のものに上書きするように作ったので、以下のものを消せば元に戻せる。
.personalizedGrid {
  $this: &;
  &_col {
    a {
      display: block;
      text-decoration: none;
      @include mq(min-md) {
        &:hover {
          #{$this}_ttl {
            text-decoration: underline;
          }
          #{$this}_txt {
            p {
              color: $linkColor;
            }
          }
        }
      }
      #{$this}_ttl {
        &::after {
          content: "";
          display: inline-block;
          width: 1.2em;
          height: 1em;
          margin: 0.25em 0 0.25em 0.3em;
          margin-left: 0.3em;
          vertical-align: top;
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/arw-link@2x.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }  
      }
    }
  }
  &_txt {
    p {
      margin-top: 0.5em;
      transition: 0.2s;
      color: $baseColor;
    }
  }
  &_ttl {
    font-weight: bold;
    + * {
      margin-top: 0.5em;
    }
  }
}