.sec-privacy{
  background: $pale-grey;
  @include mq(md) {
    background: #fff;
  }

  .container{
    margin: 0 percentage(60/920);
    padding: percentage(40/920) percentage(60/920);
    background: #fff;
    @include mq(md) {
      margin: 0;
      padding: 0 20px;
    }
  }
  .privacyLead{
    margin-bottom: 40px;
  }
  .privacyCont{
    &_frame {
      margin-top: 80px;
      padding: percentage(40/920) percentage(70/920);
      border: 1px solid $pale-sky-blue;
      @include mq(md) {
        margin-top: 40px;
      }
    }
    &_items{
      margin-bottom: 40px;

      &:last-child{
        margin-bottom: 0;
      }
      &Ttl{
        margin: 0 0 15px;
        padding: 0 0 15px;
        font-size: 2rem;
        font-weight: bold;
        border-bottom: 2px solid #707070;

        @include mq(md) {
          margin: 0 0 10px;
          padding: 0 0 10px;
          font-size: 1.8rem;
          border-bottom: 1px solid #707070;
        }
      }
      &Txt{
        margin: 0;

        p{
          margin: 0 0 20px;
        }
      }
    }
    .countBracketsList{
      padding-left: 40px;
      list-style: none;
      counter-reset: bracketsCount;


      >li{
        position: relative;
        counter-increment: bracketsCount;
        &:before{
          content: "（ " counter(bracketsCount) " ）";
          position: absolute;
          display: inline;
          margin: 0 0 0 -45px;
        }
      }
    }

    .countTitle{
      margin: 0 0 10px;
    }

    .countNumList{
      margin: 0 0 15px;
      padding: 0 0 0 30px;
      list-style: none;
      counter-reset: numCount;
      
      @include mq(md) {
        margin: 0 0 20px;
      }

      li{
        position: relative;

        &:before{
          content: counter(numCount);
          display: inline-block;
          width: 15px;
          height: 15px;
          margin: 0 5px 0 -20px;
          font-size: 1rem;
          line-height: 14px;
          counter-increment: numCount;
          text-align: center;
          border: 1px solid $navy;
          border-radius: 50%;
        }
      }
    }

  }
}
























