// 導入事例一覧ページ
#-casestudy_top------------------ {}

.sec-caseStudyTop {
  padding-bottom: 0;
  @include mq(md) {
    padding-bottom: 40px;
  }
}

.caseStudyTop {
  text-align: center;
  &_read {
    font-size: 2rem;
    text-align: left;
    @include mq(md) {
      font-size: 1.8rem;
    }
  }
  &_client {
    max-width: 869px;
    margin: 50px auto 0;
  }
  &_ttl {
    margin-bottom: 0;
    font-size: 3.2rem;
    line-height: 1.5;
    @include mq(md) {
      font-size: 2.8rem;
    }
    + * {
      margin-top: 23px;
    }
  }
}

.caseStudySearch {
  $this: &;
  // margin-top: 40px;
  @include mq(md) {
    background-color: $pale-grey;
  }
  &_panel {
    margin: 0px -10px;
    padding: 10px 30px 30px;
    background-color: $pale-grey;
    @include mq(md) {
      margin: 0;
      padding: 40px 0px;
    }
    &Inr {
      position: relative;
      display: flex;
      align-items: flex-end;
    }
    &.-sp {
      #{$this}_btn {
        a,
        button {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 15px;
            bottom: 0;
            width: 1em;
            height: 1em;
            margin: auto;
            background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/icon-plus@2x.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
          }
        }
      }
    }
  }
  &_col {
    width: 25%;
    padding: 10px 10px;
  }
  &_ttl {
    margin-bottom: 6px;
    padding: 0 20px 0px;
    line-height: 1.2;
  }
  &_sub {
    position: absolute;
    z-index: 50;
    top: 100%;
    display: none;
    max-width: 528px;
    padding: 20px 20px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba($navy,0.3);
    &.-industry {
      .list-sort {
        &_item {
          width: percentage(1/3);
        }
      }
    }
    &.-purpose {
      .list-sort {
        &_item {
          width: percentage(1/2);
        }
      }
    }
    &.-scale {
      max-width: 200px;
      .list-sort {
        &_item {
          width: percentage(1/1);
        }
      }
    }
  }
  &_select {
    position: relative;
    display: block;
    margin: 0;
    a {
      display: flex;
      width: 100%;
      height: 40px;
      padding: 2px 15px;
      line-height: 1.2;
      text-decoration: none;
      color: $navy;
      border: 1px solid $pale-sky-blue;
      border-radius: 30px;
      outline: none;
      background-color: #fff;
      align-items: center;
      justify-content: space-between;
      appearance: none;
      &::after {
        content: "";
        top: 0;
        right: 15px;
        bottom: 0;
        min-width: 1em;
        height: 1em;
        pointer-events: none;
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/arw-select@2x.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
    &Inr {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &_btn {
    @include btn(blue);
    a,
    button {
      width: 100%;
    }
  }
  .list-sort {
    margin: -6px 0;
    line-height: 1.2;
    &_item {
      padding: 6px 0;
      &:first-child {
        width: 100%;
      }
    }
  }
}

.caseStudyResult {
  margin: 40px 0 45px;
  @include mq(md) {
    margin-bottom: 40px;
    border-bottom: 1px solid $navy;
  }
  &_inr {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid $navy;
    align-items: flex-end;
    justify-content: space-between;
    @include mq(md) {
      border-bottom-style: none;
    }
  }
  &_ttl,
  &_num {
    margin: 0;
  }
  &_ttl {
    font-size: 2.2rem;
    line-height: 1.5;
    @include mq(md) {
      font-size: 1.8rem;
    }
  }
  &_num {
    min-width: 70px;
    text-align: right;
  }
  &_error {
    font-size: 2.8rem;
    line-height: 1.5;
    text-align: center;
    @include mq(md) {
      font-size: 2rem;
    }
  }
}

.caseStudyList {
  $this: &;
  display: flex;
  flex-wrap: wrap;
  margin: -40px -20px;
  @include mq(md) {
    display: block;
    margin: -15px 0;
  }
  &_item {
    width: percentage(1/3);
    padding: 40px 20px;
    @include mq(md) {
      width: auto;
      padding: 40px 0;
    }
  }
  &_inr {
    position: relative;
  }
  &_head {
    display: block;
    a {
      display: block;
      text-decoration: none;
      color: $navy;
      @include mq(min-md) {
        &:hover {
          #{$this}_img {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
  &_body {
    display: block;
    margin-top: 7px;
  }
  &_foot {
    margin-top: 8px;
  }
  &_logo {
    height: 40px;
    margin-bottom: 5px;
    img {
      width: auto;
      height: 100%;
    }
  }
  &_img {
    overflow: hidden;
    img {
      width: 100%;
      transition: transform 0.3s ease-out;
    }
  }
  &_company {
    margin: 6px 0 0;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
  }
  &_txt {
    margin: 5px 0;
  }
  &_info {
    margin-top: 0.5em;
    line-height: 1.5;
    dt {
      float: left;
      clear: both;
      width: 3em;
      padding: 0.2em 0;
    }
    dd {
      margin-left: 3em;
      padding: 0.2em 0;
    }
    a {
      text-decoration: none;
      @include mq(min-md) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.remodal {
  margin:  0;
  padding: 0;
  transform: none;
  text-align: left;
  background-color: transparent;
  &-wrapper {
    padding: 0px percentage(20/375) 0;
  }
  &-close {
    position: absolute;
    top: -50px;
    right: 0;
    left: auto;
    width: 120px;
    height: 30px;
    font-size: 1.4rem;
    color: $baseColor;
    border-radius: 20px;
    background-color: #fff;
    &::before {
      right: 0;
      left: auto;
      width: 30px;
      height: 30px;
      font-size: 2.1rem;
      line-height: 30px;
    }
  }
  &_inr {
    position: relative;
    margin-top: 110px;
    pointer-events: all;
  }
  &_panel {
    overflow: hidden;
    padding-bottom: 70px;
    border-radius: 6px;
    background-color: #fff;
  }
  &_head {
    display: flex;
    min-height: 50px;
    padding: 2px 20px;
    color: #fff;
    background-color: $grey-blue;
    align-items: center;
    .title {
      font-size: 2rem;
      font-weight: normal;
      line-height: 1.2;
    }
  }
  &_body {
    padding: 0 percentage(10/335) 30px;
  }
  &_foot {
    position: fixed;
    right: 0;
    bottom: 0px;
    left: 0;
    padding: 0 percentage(20/375);
  }
  &_footInr {
    display: flex;
    width: 100%;
    max-width: 700px;
    height: 70px;
    margin: 0 auto;
    background-color: $pale-grey;
    align-items: center;
    justify-content: center;
  }
  .list-sort {
    &_item {
      width: 50%;
      min-height: 50px;
      border-bottom: 1px solid $pale-sky-blue;
      label {
        // display: flex;
        // align-items: center;
        display: flex;
        height: 100%;
        height: 100%;
        align-items: center;
      }
    }
  }
}

.list-sort {
  display: flex;
  margin: 0 0 0;
  padding-left: 0;
  list-style: none;
  text-align: left;
  flex-wrap: wrap;
  &_item {
    // width: 50%;
    // min-height: 50px;
    // border-bottom: 1px solid $pale-sky-blue;
    @include mq(min-md) {
      width: percentage(1/3);
    }
    input {
      display: none;
      &:checked {
        + label {
          &::before {
            border-color: $linkColor;
            background-color: $linkColor;
          }
          &::after {
            opacity: 1;
          }
        }
      }
    }
    label {
      position: relative;
      display: inline-block;
      padding-left: 21px;
      font-size: 1.2rem;
      line-height: 1.2;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
      &::before {
        content: "";
        display: block;
        width: 16px;
        min-width: 16px;
        height: 16px;
        margin-right: 0.5em;
        transition: 0.1s;
        border: 1px solid $pale-sky-blue;
        border-radius: 4px;
      }
      &::after {
        top: 0px;
        bottom: 2px;
        left: 6px;
        display: block;
        width: 4.5px;
        height: 6.5px;
        transition: opacity 0.1s;
        transform: translate(0.5em,-0.15em) rotate(45deg);
        transform: translate(7px,-2px) rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
        border-width: 2px;
        border-style: none solid solid none;
        border-color: #fff;
      }
      &:hover {
        &::before {
          border-color: $linkColor;
        }
      }
    }
  }
}

// 導入事例詳細ページ
#-casestudy_detail------------------ {}

/* .main-header {
  .p-casestudy.single & {
    height: auto;
    color: $baseColor;
    background-color: #fff;
    a {
      color: $baseColor;
    }
  }
} */

.header-casestudy {
  margin: 100px 0 32px;
  text-align: left;
  @include mq(md) {
    margin: 60px 0 60px;
  }
  &_logo {
    img {
      max-width: 200px;
      max-height: 120px;
      @include mq(md) {
        max-width: 222px;
      }
    }
  }
  &_company {
    margin: 25px 0 0;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.5;
    @include mq(md) {
      font-size: 1.4rem;
      // font-weight: normal;
    }
  }
  .ttl-page {
    margin-top: 0.3rem;
    font-size: 3.2rem;
    @include mq(md) {
      font-size: 2.8rem;
    }
  }
}

.sec-caseStudyHead {
  padding-top: 0;
  background-color: $pale-grey;
  @include mq(md) {
    padding-bottom: 0;
    &,
    .main-header + & {
      padding-top: 0;
    }
  }
  .container {
    @include mq(md) {
      margin: 0;
    }
    &-inr {
      @include mq(md) {
        // margin: 20px percentage(20/375) 0;
        margin: 20px 20px 0;
      }
    }
  }
}

.caseStudyHead {
  $this: &;
  &_img {
    + * {
      margin-top: 50px;
    }
    img {
      width: 100%;
    }
  }
  &_dept {
    margin-bottom: 0;
    font-size: 1.4rem;
    word-break: keep-all;
    color: $grey-blue;
    @include mq(md) {
      font-size: 1.2rem;
    }
    + #{$this}_name {
      margin-top: 0;
    }
  }
  &_name {
    font-size: 1.6rem;
    @include mq(md) {
      font-size: 1.4rem;
    }
  }
}

.caseStudyGoal {
  margin-top: 50px;
  padding: 28px percentage(25/768) 40px;
  background-color: #fff;
  @include mq(md) {
    margin-top: 20px;
    margin-right: -20px;
    margin-left: -20px;
    padding: 28px 20px;
  }
  &_ttl {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.5;
    @include mq(md) {
      font-size: 2.4rem;
    }
  }
  &_list {
    padding-left: 0;
    font-size: 2rem;
    line-height: 1.5;
    list-style: none;
    counter-reset: num;
  }
  &_item {
    position: relative;
    padding-left: 24px;
    & + & {
      margin-top: 12px;
      margin-top: 0.8em;
    }
    &::before {
      content: counter(num, decimal-leading-zero);
      position: absolute;
      top: 0.3em;
      left: 0;
      font-size: 1.4rem;
      font-weight: 500;
      counter-increment: num;
      color: $linkColor;
    }
  }
}

.sec-caseStudyBody {
  padding-bottom: 80px;
  background-color: $pale-grey;
  @include mq(md) {
    padding-top: 0px;
    padding-bottom: 30px;
  }
  .container {
    @include mq(md) {
      margin: 0;
    }
    &-inr {
      margin-right: 20px;
      margin-left: 20px;
    }
  }
}

.caseStudyBody {
  &_item {
    & + & {
      margin-top: 80px;
    }
  }
  &_img {
    position: relative;
    overflow: hidden;
    padding-top: percentage(408/920);
    + * {
      margin-top: 30px;
      @include mq(md) {
        margin-top: 20px;
      }
    }
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: auto;
    }
  }
  &_txt {
    font-size: 1.6rem;
    line-height: 1.8;
  }
  &_name {
    color: $turquoise-blue;
  }
}

.caseStudyQa {
  position: relative;
  + * {
    margin-top: 30px;
    @include mq(md) {
      margin-top: 20px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 30px;
    height: 100%;
    border-radius: 30px;
    background-color: $turquoise-blue;
  }
  &_q,
  &_a {
    position: relative;
    padding-left: 42px;
    .mark {
      position: absolute;
      z-index: 1;
      left: 0;
      display: flex;
      width: 30px;
      min-height: 30px;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1;
      color: #fff;
      border-radius: 30px;
      align-items: center;
      justify-content: center;
      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 70%;
        display: block;
        width: 15px;
        height: 12px;
        margin: auto;
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/casestudy/icon-balloon_a@2x.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
  }
  &_q {
    font-size: 1.6rem;
    .mark {
      z-index: 10;
      top: 0;
      height: 100%;
      background-color: #3c5058;
      &::after {
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/casestudy/icon-balloon_q@2x.png);
      }
    }
  }
  &_a {
    margin-left: 0;
    padding-top: 8px;
    padding-top: 12px;
    padding-bottom: 8px;
    font-size: 2rem;
    line-height: 1.5;
    .mark {
      z-index: 5;
      bottom: 0;
      height: 100%;
      background-color: $turquoise-blue;
    }
  }
}

.caseStudyCom {
  padding: percentage(35/768) percentage(30/768) percentage(30/768);
  background-color: #fff;
  @include mq(md) {
    padding: 35px 20px 30px;
  }
  &_ttl {
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 1.5;
    + * {
      margin-top: 10px;
    }
  }
}

.caseStudyType {
  margin-top: 32px;
  &_list {
    padding-left: 0;
    list-style: none;
    letter-spacing: -0.4em;
  }
  &_item {
    display: inline-block;
    margin-right: 32px;
    letter-spacing: normal;
    @include mq(md) {
      display: block;
      margin-right: 0;
    }
    & + & {
      @include mq(md) {
        margin-top: 1em;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    strong {
      margin-right: 10px;
      font-weight: normal;
    }
    a {
      text-decoration: none;
    }
  }
}

.sec-caseStudyRelated {
  padding-top: 100px;
  background-color: #fff;
  @include mq(md) {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

.caseStudyRelated {
}
