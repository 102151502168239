.sec-terms{
  .terms-box{
    display: flex;
    padding: 40px percentage(75/920);

    @include mq(md) {
      display: block;
      margin-bottom: 40px;
      padding: 0;

      &:last-child{
        margin-bottom: 0;
      }
    }

    &Image{
      width: percentage(160/770);
      margin-right: percentage(30/770);

      @include mq(md) {
        display: block;
        width: auto;
        margin: 0 0 15px;
      }
    }

    &Cont{
      width: percentage(580/770);
      margin-left: auto;

      @include mq(md) {
        width: auto;
      }
      &_header{}
      &_link{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        @include mq(md) {
          display: block;
          padding: percentage(30/670) percentage(30/670) 0 percentage(30/670);
        }
      }

      .terms-title{
        margin-bottom: 20px;
        font-size: 2.8rem;
        line-height: 1.4;

        @include mq(md) {
          margin-bottom: 0px;
          font-size: 2.4rem;
        }
      }
      .terms-rev{
        width: percentage(275/580);
        margin-top: 0;
        padding: 0;
        font-size: 1.2rem;
        list-style: none;

        @include mq(md) {
          width: auto;
        }
      }

      .terms-btn{
        @include btn($btnColor: blue);
        display: block;
        width: percentage(295/580);
        max-width: 295px;
        font-size: 1.2rem;
        text-align: center;

        @include mq(md) {
          width: 100%;
          max-width: none;
          margin: 0 auto 15px;
        }

        a{
          margin-bottom: 10px;
          &:not(.btn_outer){
            width: 100%;
            font-size: 1.4rem;

            &:hover {
              span{
                &:before{
                  background-position: top right;
                }
              }
            }
          }
          span{
            &:before{
              content: "";
              display: inline-block;
              width: 14px;
              height: 18px;
              margin-right: 10px;
              vertical-align: middle;
              background-image: url(/wp-content/themes/akerun/_assets/images/common/icon_pdf.svg);
              background-repeat: no-repeat;
              background-position: top left;
              background-size: cover;
            }
          }
        }
      }
    }
  }
}