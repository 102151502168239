@charset "utf-8";

.l-header {
  position: fixed;
  z-index: 999;
  width: 200px;
  height: 100%;
  @include mq(lg) {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 55px;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba($grey-blue, 0.2);
    align-items: center;
    justify-content: space-between;
  }
  &_inr {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    height: 100%;
    // padding-bottom: 30px;
    border-radius: 0 20px 20px 0;
    background-color: #fff;
    @include mq(lg) {
      height: auto;
      border-radius: 0;
      background-color: transparent;
    }
  }
  &_head {
    padding: 45px 30px 45px;
    @include mq(lg) {
      width: 140px;
      margin-left: 5px;
      padding: 0;
    }
    .logo {
      font-size: 1em;
      img {
        width: auto;
        height: 26px;
      }
    }
  }
  &_body {
    background-color: #fff;
    flex: auto;
    @include mq(lg) {
      display: none;
      // position: absolute;
      // top: 55px;
      // left: 0;
      // width: 100%;
    }
    &::before {
      // content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      background-color: #000;
    }
    &.tse-scrollable {
      // overflow: visible;
      // height: auto;
      .tse-scroll-content {
        // overflow: visible;
      }
    }
    .tse-content {
      padding-bottom: 160px;
    }
    .tse-scrollbar {
      right: auto;
      left: 0px;
      .drag-handle {
        width: 4px;
        background-color: #b7b7b7;
      }
    }
  }
  &_foot {
    position: absolute;
    z-index: 0;
    bottom: 35px;
    width: 100%;
    margin-top: auto;
    padding: 10px 0px 10px;
    @include mq(lg) {
      display: none;
    }
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      display: block;
      width: 180px;
      height: 100%;
      margin: 0 10px;
      border-radius: 25px;
      background-color: rgba($grey-blue,0.2);
    }
  }
  &_sub { // 実際に表示されるサブメニュー
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    // transform: translateX(-300px);
    left: -300px;
    box-sizing: content-box;
    width: 270px;
    height: 100%;
    padding-left: 200px;
    transition: transform 0.3s;
    background-color: rgba(#fff,0.8);
    @include mq(lg) {
      display: none;
    }
    &.-active,
    &.current {
      transition: transform 0.3s;
      transform: translateX(0);
      transform: translateX(300px);
    }
    .gNavSub {
      $this: &;
      box-sizing: border-box;
      &_list {
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        left: 200px;
        display: flex;
        &.-footSub { // お問い合わせのサブメニュー
          top: auto !important;
          bottom: 45px;
          flex-direction: column;
          .gNavSub_item {
            @include btn(grad);
            + li {
              margin-top: 16px;
              margin-bottom: 0px;
            }
            a {
              width: 100%;
            }
            @for $i from 1 through 9 {
              &:nth-last-child(#{$i}) {
                animation: gnavsub 0.5s ease #{($i - 1) / 10 + 0.2}s both;
              }
            }
          }
        }
        &:not(.-notop) {
          .gNavSub_item {
            &:not(.-top) {
              a {
                width: 160px;
                min-height: 30px;
                margin: 0 auto;
              }
            }    
          }
        }
      }
      &_item {
        a {
          box-shadow: 0px 2px 4px 1px rgba($navy, 0.2);
        }
        @for $i from 1 through 9 {
          &:nth-child(#{$i}) {
            animation: gnavsub 0.5s ease #{($i - 1) / 10 + 0.2}s both;
          }
        }
        @keyframes gnavsub {
          0% {
            transform: translateX(-20%);
            opacity: 0;
          }
          100% {
            transform: translateX(0%);
            opacity: 1;
          }
        }
      }
    }
  }
}

.gNav {
  $this: &;
  @include mq(min-lg) {
    .l-header_inr & {
      &_list {
        margin: 0;
        padding: 0;
        list-style: none;
        & + #{$this}_list {
          margin-top: 30px;
        }
        &.-info {
          #{$this}_item {
            > a {
              min-width: 30px;
              font-size: 1.2rem;
            }
          }
        }
        &.-manager {
          #{$this}_item {
            > a {
              color: $navy;
              &::after {
                background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/icon-blank_bk@2x.png);
              }
            }
          }
        }
        &.-foot {
          margin: 0;
          padding: 0;
          list-style: none;
          & + #{$this}_list {
            margin-top: 16px;
          }
          &_list {
          }
          #{$this}_item {
            @include btn(grad);
            &.-active,
            &.current {
              a.btn_outer {
                background-color: transparent;
              }
            }
            a {
              padding: 0;
              &.btn_outer {
                width: 100%;
                &:hover {
                  background-color: transparent;
                }
                .btn_inner {
                  margin-right: auto;
                  margin-left: auto;
                }
              }
            }
            + li {
              margin-top: 16px;
            }
          }
        }
      }
    }
    &_item {
      position: relative;
      > a {
        display: flex;
        min-height: 36px;
        padding: 2px 2px 2px 30px;
        font-size: 1.4rem;
        line-height: 1.2;
        text-decoration: none;
        align-items: center;
        &:hover {
        }
        &[target="_blank"], &[target="_new"] {
          &::after {
            content: "";
            display: inline-block;
            width: 1em;
            height: 1.2rem;
            margin-left: 0.5em;
            background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/icon-blank@2x.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
          }
        }
      }
      > a:hover,
      &.-active > a {
        background-color: $pale-grey;
      }
    }
    &Sub {
      &_list {
        display: none;
        flex-direction: column;
        width: 210px;
        // margin: 0 auto;
        margin: -8px auto;
        padding-left: 0px;
        list-style: none;
      }
      &_item {
        @include btn(blue);
        display: block;
        // padding: 8px 0;
        &:first-child {
          // padding-top: 0;
        }
        &:last-child {
          // padding-bottom: 0;
        }
        & + & {
          margin-top: 16px;
        }
      }
    }
  }
}

.nav-sp {
  @include mq(min-lg) {
    display: none;
  }
  &_btn {
    a {
      position: relative;
      display: block;
      width: 45px;
      height: 45px;
      text-indent: -999em;
      color: #fff;
      border-radius: 50%;
      background-color: $linkColor;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        transition: opacity 0.2s;
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/btn-spmenu@2x.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
      &::after {
        opacity: 0;
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/btn-spmenu_close@2x.png);
      }
    }
    &.-active,
    &.current {
      a {
        &::before {
          opacity: 0;
        }
        &::after {
          opacity: 1;
        }
      }
    }
  }
  &_menu {
    position: absolute;
    top: 55px;
    left: 0;
    display: none;
    overflow-y: auto;
    width: 100%;
    max-height: calc(100vh - 55px);
    // padding-top: 40px;
    background-color: #fff;
    .gNav {
      &_list {
        margin: 0;
        padding-left: 0;
        list-style: none;
        &:first-child {
          border-top: 1px solid $pale-sky-blue;
        }
        &.-download {
          border-top-style: none;
          .gNav_item {
            @include btn(grad);
            padding: 30px percentage(20/375);
            a.btn {
              &_outer {
                width: 100%;
                min-height: auto;
                padding: 0;
                .btn_inner {
                  width: 100%;
                  min-height: 50px;
                }
              }
            }
          }
        }
      }
      &_item {
        border-bottom: 1px solid $pale-sky-blue;
        a {
          position: relative;
          display: flex;
          min-height: 55px;
          padding: 5px percentage(40/375) 5px percentage(20/375);
          font-size: 1.4rem;
          font-weight: bold;
          line-height: 1.5;
          text-decoration: none;
          align-items: center;
          &[target="_blank"], &[target="_new"] {
            &::after {
              content: "";
              display: inline-block;
              width: 1em;
              height: 1.2rem;
              margin-left: 0.5em;
              background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/icon-blank@2x.png);
              background-repeat: no-repeat;
              background-position: center center;
              background-size: contain;
            }
          }
        }
        &.-parent {
          > a {
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: percentage(26/375);
              bottom: 0;
              display: block;
              width: 7px;
              height: 7px;
              margin: auto;
              transform: rotate(45deg);
              border-width: 2px;
              border-style: none solid solid none;
              border-color: $linkColor;
            }
          }
        }
        &.-active,
        &.current {
          > a {
            &::before {
              transform: rotate(225deg);
            }
          }
        }
      }
      &Sub {
        &_list {
          display: none;
          margin: 0;
          // padding: 25px 0;
          padding: 0 percentage(20/375);
          // padding-left: 0;
          list-style: none;
          // background-color: $pale-grey;
          &:not(.-notop) {
            .gNavSub_item {
              &.-top {
                a {
                  font-weight: bold;
                }
              }
            }
          }
        }
        &_item {
          a {
            min-height: 50px;
            padding: 5px percentage(20/335);
            font-weight: normal;
            border-top: 1px dashed $pale-sky-blue;
          }
        }
      }
    }
  }
}