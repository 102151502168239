@charset "utf-8";

.form-download {
  &_lead {}
  &_form {
    margin-top: 80px;
  }
}

.download{
  &__desc{
    margin-bottom: 40px;
    text-align: center;
    >*{
      font-size: 1.4rem;
      line-height: 1.5;
    }
    small{font-size: 0.85em;}
  }
  &__thumb{
    + * {
      margin-top: 40px;
    }
    ul{
      display: flex;
      // width: percentage(400/920);
      width: 400px;
      max-width: 100%;
      margin: 0 auto;
      padding-left: 0;
      justify-content: center;
    }
    li{
      list-style: none;
      &+li{margin-left: 20px;}
    }
    figure{
      margin: 0;
    }
    figcaption{
      margin-top: 10px;
      font-size: 1.2rem;
      line-height: 1.5;
      text-align: center;
    }
  }
}

.sec-downloadFlow{
  // padding-top: 100px;
  @include mq(md) { padding-top: 40px;}
}

.downloadFlow{
  text-align: center;
  &__desc{
    font-size: 1.6rem;
    @include mq(md) {
      font-size: 1.4rem;
    }
  }
  &__btn{
    .btn.-white a:not(.btn_outer){
      // display: inline-block;
      // width: 100%;
      // max-width: 260px;
      // box-shadow: 0px 2px 4px #001f2d33!important;
      min-width: 260px;
    }
  }
}

.is-sp{
  @include mq(min-md) {display: none;}
}