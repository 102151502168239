@charset "UTF-8";
/*------------------------------------------------------*/
/*  既存ページから5673のスタイルを移行
/*------------------------------------------------------*/
#lp-5673 {
  overflow-x: auto;
  min-width: 980px;

  #pl-5673 {
    overflow-x: hidden;

    .heading-bordered {
      margin-bottom: 60px;
      padding-bottom: 8px;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #1580a8;
      background: url("/wp-content/themes/akerun/_assets/images/landing/5673/img_line.png") center bottom no-repeat;
      background-size: 98px;

      .large {
        padding-left: 5px;
        font-size: 27px;
        font-weight: bold;
        color: #1580a8;
      }
    }
  }

  .akerun_lp_template {
    &.hero2 {
      overflow: hidden;
      margin-top: 0;

      .hero-main {
        background-color: #d4e0ea;
        background-image: url(/wp-content/themes/akerun/_assets/images/landing/5673/main-visual-background-image2_3.jpg);
        background-repeat: no-repeat;
        background-position: top center;

        .hero_inner {
          position: relative;
          width: 980px;
          height: 502px;
          margin: 0 auto;

          .hero_left_column {
            position: absolute;
            bottom: 0;
            left: 107px;
            width: 369px;
          }

          .hero_right_column {
            position: absolute;
            top: 65px;
            right: 0;

            .hero_text {
              font-size: 28px;
              font-weight: 700;
              line-height: 1.9;
              letter-spacing: 3px;
              color: #1580a8;
            }

            .hero_logos {
              margin-top: 37px;
              text-align: center;

              .hero_logo {
                display: inline-block;
                width: 148px;
                margin-right: 20px;
              }
            }
          }

          .form-link {
            position: absolute;
            bottom: 34px;
            left: 342px;

            a {
              font-size: 22px;
              font-weight: normal;
              transition: 0.3s;
              color: white;
              background: linear-gradient(to right, #00c1cd 0%, #0092d1 100%);

              &:hover {
                background: linear-gradient(#4dd4dc 0%, #4db3df 100%);
              }
            }
          }
        }
      }
    }

    &.introduction {
      position: relative;
      box-sizing: content-box;
      width: 730px;
      min-width: 730px;
      margin-top: 80px;
      padding: 0 114px;

      .heading {
        font-family: "NotoSansJp";
        font-size: 30px;
        font-weight: 300;
        line-height: 1.7;
        letter-spacing: 3px;
        color: #1580a8;
      }

      .text-list {
        margin-top: 30px;

        .item {
          margin-bottom: 8px;
          padding-left: 1.2em;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.75;
          background: url("/wp-content/themes/akerun/_assets/images/landing/5673/icn_check.svg")
            left center no-repeat;
          background-size: 0.8em;
        }
      }

      .image {
        position: absolute;
        top: 0;
        right: 81px;
      }

      .bottom-image {
        margin-top: 60px;
        text-align: center;
      }
    }

    &.setup {
      padding: 0 0 0 82px;
      text-align: center;

      .setup-list .item {
        display: inline-block;
        width: 221px;
        margin-left: 30px;

        &:last-child {
          width: 274px;
        }
      }
    }

    &.request {
      min-width: 980px;
      max-width: 1920px;
      margin: 80px auto;
      padding: 86px 40px 63px;
      text-align: center;
      background: url("/wp-content/themes/akerun/_assets/images/landing/5673/form-link-background.jpg")
        center 90% no-repeat;
      background-size: cover;

      .main {
        margin-bottom: 55px;

        .video,
        .texts {
          display: inline-block;
        }

        .video {
          width: 313px;
          margin-right: 35px;

          iframe {
            width: 100%;
            height: 175px;
          }
        }

        .texts {
          width: 510px;
          vertical-align: top;
        }
      }
    }

    &.case-studies {
      text-align: center;

      .case-study-list {
        margin-bottom: -55px;

        .item {
          display: inline-block;
          width: 260px;
          margin-right: 18px;
          margin-bottom: 35px;
          text-align: center;
          vertical-align: top;

          &:nth-child(3n) {
            margin-right: 0;
          }

          .title {
            margin-bottom: 10px;
            font-family: "NotoSansJp-Light";
            font-size: 14px;
            font-weight: 900;
            letter-spacing: 2px;
          }

          .company {
            position: relative;
            display: flex;
            margin-top: 10px;
            margin-bottom: 20px;
            text-align: left;
            align-items: center;

            .name {
              padding: 16px 0;
              font-size: 12px;
              font-weight: bold;
              flex: 1;

              span {
                font-size: 9px;
                line-height: 2;
              }
            }

            .image {
              line-height: 1;
            }
          }

          .description {
            font-size: 12px;
            line-height: 2;
            text-align: left;
            text-align: justify;
          }
        }
      }

      &.settings .company .name {
        padding: 0 !important;
      }
    }

    &.achievements {
      padding: 80px 0;
      text-align: center;

      .data-list {
        margin-top: 70px;

        .item {
          display: inline-block;
          margin-right: 40px;

          &:first-child {
            width: 216px;
          }

          &:nth-child(2) {
            width: 230px;
          }

          &:last-child {
            width: 251px;
            margin-right: 0;
          }
        }
      }
    }

    &.solution {
      padding-top: 80px;
      text-align: center;

      .heading {
        margin-bottom: 80px;
        padding-bottom: 80px;
        font-size: 29px;
        color: #1580a8;
        background: url("/wp-content/themes/akerun/_assets/images/landing/5673/img_line.png")
          center bottom no-repeat;
        background-size: 100px;
      }

      .solution-item {
        display: flex;
        margin-bottom: 66px;
        text-align: left;
        align-items: center;

        &.left-text {
          margin-left: 180px;

          .texts {
            width: 386px;
            padding-left: 40px;
          }

          .image {
            width: 280px;
          }
        }

        &.right-text {
          margin-left: 140px;

          .texts {
            width: 410px;
            padding-left: 40px;
          }

          .image {
            width: 330px;
          }
        }

        .texts,
        .image {
          display: inline-block;
          vertical-align: middle;
        }

        .texts {
          background: url("/wp-content/themes/akerun/_assets/images/landing/5673/icn_check.svg")
            left 10px no-repeat;
          background-size: 25px;

          .title {
            margin-bottom: 29px;
            font-size: 28px;
          }

          .description {
            font-size: 15px;
            line-height: 2.3;
          }
        }

        .image {
          margin-top: 3px;
          text-align: center;
        }
      }
    }

    &.advantage {
      padding-top: 80px;
      text-align: center;

      .heading-bordered {
        font-size: 24px;
      }

      .description {
        font-size: 15px;
        line-height: 2;
      }

      .image {
        margin-top: 40px;
      }
    }

    &.form {
      max-width: 1280px;
      margin: 80px auto;
      padding-top: 80px;
      text-align: center;

      .heading {
        margin-bottom: 80px;
        padding: 20px 0;
        font-size: 20px;
        font-weight: bold;
        color: white;
        background: #1580a8;
      }
    }

    &.try {
      min-width: 980px;
      padding-top: 80px;
      text-align: center;

      .heading {
        margin-bottom: 70px;
        font-size: 29px;
        line-height: 1.8;
        color: #1580a8;
        background-size: 100px;
      }

      .reason-container {
        margin-bottom: 150px;

        .reason {
          margin-bottom: 60px;
          font-size: 28px;
          color: #333;
        }

        .text,
        .image {
          display: inline-block;
          vertical-align: middle;
        }

        .text {
          width: 45%;
          padding-left: 33px;
          font-size: 15px;
          line-height: 2.3;
          text-align: left;
        }

        .image {
          padding-left: 40px;
          text-align: right;
        }

        .supplement {
          font-size: 13px;
        }

        .rental {
          .title {
            font-size: 14px;
          }

          .rental-list {
            margin-top: 10px;
            border-top: 1px solid #aaa;
            border-bottom: 1px solid #aaa;

            .item {
              display: inline-block;
              width: 24%;
              padding: 20px 0 4px;
              text-align: center;
              border-left: 1px solid #aaa;

              &:first-child {
                border-left: 0;
              }

              .product-image {
                display: table-cell;
                width: 25vw;
                min-width: 280px;
                height: 187px;
                margin-bottom: 20px;
                vertical-align: middle;
              }

              .name {
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    &.solvable {
      padding-top: 80px;
      text-align: center;

      .image {
        padding: 0 102px;
      }

      .product-image {
        width: 512px;
        margin: 30px auto 0;
      }

      .sub-heading {
        margin-top: 40px;
        font-size: 30px;

        span {
          font-size: 24px;
        }
      }
    }

    &.easy-to-use {
      padding-top: 80px;
      padding-bottom: 80px;

      .easy-to-use-list {
        padding: 0 120px;

        .item {
          display: inline-block;
          width: 234px;
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    &.what {
      min-width: 980px;
      padding-top: 80px;
      padding-bottom: 58px;
      text-align: center;
      background: #435564;

      .heading {
        width: 557px;
        margin: 0 auto;
      }

      .description {
        margin-top: 21px;
        margin-bottom: 40px;
        font-size: 14px;
        line-height: 1.8;
        color: white;
      }

      .sub-heading {
        margin-bottom: 40px;
        font-size: 20px;
        font-weight: bold;
        color: white;
      }

      .flow-image {
        width: 668px;
        margin: 0 auto;

        img {
          width: 308px;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .capable {
        margin-top: 37px;

        .text {
          display: inline-block;
          vertical-align: middle;
        }

        .image {
          display: inline-block;
          width: 140px;
          vertical-align: middle;
        }

        .text {
          margin-left: 30px;
          line-height: 1.8;
          text-align: left;
          color: white;
        }
      }

      .after {
        margin-top: 18px;

        .text {
          display: inline-block;
          vertical-align: middle;
        }

        .image {
          display: inline-block;
          width: 300px;
          margin-right: 23px;
          vertical-align: middle;
        }

        .text {
          margin-right: 14px;
          font-size: 20px;
          line-height: 1.9;
          text-align: left;
          color: white;
        }
      }
    }
  }

  #pg-5673-5,
  #pg-5673-9{
    padding-top: 80px;
    .section{
      margin-top: 0;
    }
  }

  #pg-5673-0,
  #pg-5673-1,
  #pg-5673-2,
  #pg-5673-3,
  #pg-5673-4,
  #pg-5673-5,
  #pg-5673-6,
  #pg-5673-7,
  #pg-5673-8,
  #pg-5673-9,
  #pg-5673-10,
  #pg-5673-12,
  #pg-5673-13,
  #pl-5673 .panel-grid-cell .so-panel {
    margin-bottom: 30px;
  }

  #pg-5673-0 .panel-grid-cell,
  #pg-5673-1 .panel-grid-cell,
  #pg-5673-2 .panel-grid-cell,
  #pg-5673-3 .panel-grid-cell,
  #pg-5673-4 .panel-grid-cell,
  #pg-5673-5 .panel-grid-cell,
  #pg-5673-6 .panel-grid-cell,
  #pg-5673-7 .panel-grid-cell,
  #pg-5673-8 .panel-grid-cell,
  #pg-5673-9 .panel-grid-cell,
  #pg-5673-10 .panel-grid-cell,
  #pg-5673-12 .panel-grid-cell,
  #pg-5673-13 .panel-grid-cell,
  #pg-5673-14 .panel-grid-cell {
    float: none;
  }

  #pl-5673 .panel-grid-cell .so-panel:last-child {
    margin-bottom: 0px;
  }

  #pg-5673-0,
  #pg-5673-1,
  #pg-5673-2,
  #pg-5673-3,
  #pg-5673-4,
  #pg-5673-5,
  #pg-5673-6,
  #pg-5673-7,
  #pg-5673-8,
  #pg-5673-9,
  #pg-5673-10,
  #pg-5673-12,
  #pg-5673-13,
  #pg-5673-14 {
    margin-right: -15px;
    margin-left: -15px;
  }

  #pg-5673-0 .panel-grid-cell,
  #pg-5673-1 .panel-grid-cell,
  #pg-5673-2 .panel-grid-cell,
  #pg-5673-3 .panel-grid-cell,
  #pg-5673-4 .panel-grid-cell,
  #pg-5673-5 .panel-grid-cell,
  #pg-5673-6 .panel-grid-cell,
  #pg-5673-7 .panel-grid-cell,
  #pg-5673-8 .panel-grid-cell,
  #pg-5673-9 .panel-grid-cell,
  #pg-5673-10 .panel-grid-cell,
  #pg-5673-12 .panel-grid-cell,
  #pg-5673-13 .panel-grid-cell,
  #pg-5673-14 .panel-grid-cell {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 768px) {
  #lp-5673 {
    overflow-x: hidden;
    min-width: 100%;

    .akerun_lp_template {

      &.hero2{
        .hero-main {
          background-size: cover;

          .hero_inner {
            width: 100%;
            height: auto;
            padding: 40px 4% 0;

            .hero_left_column {
              position: static;
              width: 86%;
              margin-top: 10px;
              margin-left: 10%;
            }

            .hero_right_column {
              position: static;
              width: 100%;

              .hero_text {
                font-size: 5.5vw;
              }

              .hero_logos {
                margin-top: 20px;
                text-align: left;

                .hero_logo {
                  width: 70px;
                }
              }
            }

            .form-link {
              position: static;
              margin-top: -90px;
              padding-bottom: 30px;
              text-align: center;

              a {
                font-size: 5vw;
              }
            }
          }
        }
      }
      &.introduction {
        box-sizing: border-box;
        width: 100%;
        min-width: auto;
        margin-right: 0;
        margin-left: 0;
        padding: 0 4%;

        .heading {
          font-size: 5.5vw;
        }

        .text-list {
          margin-right: 0;
          margin-bottom: 30px;

          .item {
            background-position: left 8px;
          }
        }

        .image {
          position: static;

          img {
            width: 100%;
          }
        }
      }
      &.setup {
        box-sizing: border-box;
        padding: 0;

        .setup-list {
          text-align: center;

          .item {
            width: 60%;
            margin-bottom: 30px;
            margin-left: 0;

            &:last-child {
              width: 77%;
              margin-right: -16%;
            }
          }
        }
      }
      &.request {
        box-sizing: border-box;
        min-width: auto;
        margin: 40px 0;
        padding: 60px 4% 50px;

        .main {
          margin-bottom: 30px;

          .youtube-wrap {
            position: relative;
            display: block;
            overflow: hidden;
            width: 100%;
            height: 0;
            margin: 0;
            padding-bottom: 56.25%;

            iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }

          .texts {
            width: 100%;
            margin-top: 20px;
          }
        }
      }

      &.case-studies {
        box-sizing: border-box;
        padding-top: 30px;

        .case-study-list .item {
          width: auto;
          margin-right: 0;
          margin-bottom: 80px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.settings .item {
          margin-bottom: 50px;
        }
      }

      &.achievements {
        padding: 40px 0;

        .data-list .item {
          width: 60% !important;
          margin-right: 0;
          margin-bottom: 60px;
        }
      }

      &.solution {
        padding-top: 40px;

        .heading {
          margin-bottom: 40px;
          padding-bottom: 40px;
          font-size: 6.5vw;
        }

        .solution-item {
          flex-direction: column;
          margin-bottom: 80px;

          &.left-text {
            margin-left: 0;
          }

          &.right-text {
            margin-left: 0;

            .texts {
              order: 1;
            }

            .image {
              order: 2;
            }
          }

          .texts,
          .image {
            width: 100% !important;
          }

          .texts {
            padding-bottom: 25px;
            background-size: 23px;

            .title {
              font-size: 22px;
            }

            .description {
              line-height: 1.7;
            }
          }
        }
      }

      &.advantage {
        padding-top: 40px;

        .image {
          width: 980px;
        }
      }

      &.form {
        padding-top: 40px;

        .heading {
          margin-bottom: 40px;
          font-size: 15px;
        }
      }

      &.try {
        width: 100%;
        min-width: auto;
        margin: 0;
        padding-top: 40px;

        .heading {
          padding: 0 4%;
          font-size: 6vw;
        }

        .sub-heading {
          font-size: 5vw;
        }

        .reason-container {
          width: auto;
          margin-bottom: 70px;
          padding: 40px 4% 0;

          &.full-width {
            padding: 40px 0 0;

            .reason {
              padding-right: 4%;
              padding-left: 4%;
            }
          }

          .reason {
            font-size: 5.5vw;
          }

          .text {
            width: auto;
            margin-top: 20px;
            padding-left: 0;
            line-height: 1.8;
          }

          .image {
            padding-left: 0;
          }

          .supplement {
            font-size: 11px;
          }

          .rental .rental-list {
            padding-top: 30px;

            .item {
              width: auto;
              margin-bottom: 40px;
              border: none;

              .product-image {
                height: auto;
                padding-bottom: 10px;
              }
            }
          }
        }
      }

      &.solvable {
        padding-top: 40px;

        .image {
          padding: 0;
        }

        .product-image {
          width: auto;
        }

        .sub-heading {
          margin-bottom: 20px;
          font-size: 6vw;
          text-align: center;

          span {
            font-size: 5.5vw;
          }
        }
      }

      &.easy-to-use {
        padding-top: 40px;
        padding-bottom: 40px;

        .easy-to-use-list {
          padding: 0;
          text-align: center;

          .item {
            width: 80%;
            margin-right: 0;
            margin-bottom: 40px;
          }
        }
      }

      &.what {
        min-width: auto;
        padding: 60px 4%;

        .heading {
          width: 300px;
        }

        .description {
          margin: 40px 20px;
          font-size: 13px;
          line-height: 1.8;
          text-align: left;
          text-align: justify;
        }

        .sub-heading {
          font-size: 4.2vw;
        }

        .flow-image {
          width: 260px;

          img {
            width: auto;
            margin-right: 0;
            margin-bottom: 23px;

            &:last-child {
              margin-bottom: 10px;
            }
          }
        }

        .capable .text {
          margin: 40px 20px 20px;
          margin-left: 0;
          font-size: 13px;
          text-align: justify;
        }

        .after {
          margin-top: 40px;

          .image {
            width: 260px;
            margin-top: 20px;
            margin-right: 0;
          }
        }
      }

      .scrollable {
        overflow-x: auto;
        width: 100%;

        .image {
          width: 980px;
        }
      }
    }
    #pg-5673-0 .panel-grid-cell,
    #pg-5673-1 .panel-grid-cell,
    #pg-5673-2 .panel-grid-cell,
    #pg-5673-3 .panel-grid-cell,
    #pg-5673-4 .panel-grid-cell,
    #pg-5673-5 .panel-grid-cell,
    #pg-5673-6 .panel-grid-cell,
    #pg-5673-7 .panel-grid-cell,
    #pg-5673-8 .panel-grid-cell,
    #pg-5673-9 .panel-grid-cell,
    #pg-5673-10 .panel-grid-cell,
    #pg-5673-12 .panel-grid-cell,
    #pg-5673-13 .panel-grid-cell,
    #pg-5673-14 .panel-grid-cell {
      float: none;
      width: auto;
    }
    #pl-5673 .panel-grid,
    #pl-5673 .panel-grid-cell {
    }
  }
}
