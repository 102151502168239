/*------------------------------------------------------*/
/*  既存ページからcssの共通部分を移行
/*------------------------------------------------------*/
.p-landing{
  div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-weight: normal;
    font-style: normal;
    vertical-align: baseline;
    border: 0;
  }
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }
  fieldset, img {
    width: auto;
    border: 0;
  }
  address, caption, cite, code, dfn, em, strong, th, var {
    font-weight: normal;
    font-style: normal;
  }
  li {
    list-style: none;
  }
  caption, th {
    text-align: left;
  }
  h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: normal;
  }
  q:before, q:after {
    content: '';
  }
  abbr, acronym {
    font-variant: normal;
    border: 0;
  }
  sup {
    vertical-align: text-top;
  }
  sub {
    vertical-align: text-bottom;
  }
  input, textarea, select {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
  input, textarea, select {
    *font-size: 100%;
  }
  legend {
    color: #000;
  }
  table {
    width: 100%;
  }
  *,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
  }

  :root {
    box-sizing: border-box;
  }

  img {
    vertical-align: bottom;
  }
  p, li, dt, dd, th, td {
    font-size: 93%;
  }
  li p, dd p, li li, dd li, th p, td p {
    font-size: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
  }
  table {
    font-size: inherit;
    font-size: 100%;
  }
  .clearfix:after,
  .cf:after {
    content: " ";
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
  }


  .panel-grid {
    margin: 0 !important;
  }
  .panel-grid-cell {
    box-sizing: border-box;
    min-height: 1em;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .header {
    position: fixed;
    z-index: 9999;
    top: -65px;
    left: 0;
    width: 100%;
    height: 65px;
    transition: all 0.2s ease-out 0s;
    &-type02{
      height: 45px;

      .icon-tel{
        width: 25px;
        margin-right:5px;
        margin-left:20px;
      }
    }
    &.new {
      z-index: 99999999;
      top: -70px;
      padding: 10px 0;

      .header-btn {
        padding-left: 30px;
        font-size: 13px;
        color: #777;
      }
      .header-btn__icon {
        width: 20px;
        height: 20px;
        margin-top: -10px;
      }
    }
    &.show {
      top: 0;
    }
  }
  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background: #fff;
    filter: alpha(opacity = 90);
  }

  .header-logo {
    position: absolute;
    left: 40px;
    width: 140px;
    height: 30px;
    padding-top: 8px;

    &.color{
      width: 84px;
      height: 45px;
    }
  }

  .header-btn {
    position: relative;
    display: inline-block;
    float: right;
    margin-right: 30px;
    padding-left: 40px;
    font-size: 17px;
    line-height: 45px;
    cursor: pointer;
    text-decoration: none;

    &__icon {
      position: absolute;
      top: 50%;
      left: 0;
      width: 25px;
      height: 25px;
      margin-top: -12px;
      font-size: 0;
      line-height: 0;
      -webkit-transition: all 0.2s ease-out 0s;
      -moz-transition: all 0.2s ease-out 0s;
      -o-transition: all 0.2s ease-out 0s;
      transition: all 0.2s ease-out 0s;
    }

    &:hover{
      .header-btn__icon {
        margin-top: -7px;
      }
    }
  }

  .footer {
    position: relative;
    overflow: hidden;
    margin-top: 50px;
    padding-top: 24px;
    padding-bottom: 20px;
    text-align: center;
    border-top: 1px solid #ececec;

    ul {
      letter-spacing: -.40em;

      li {
        display: inline-block;
        margin: 0 19px;
        vertical-align: top;
        letter-spacing: normal;

        a {
          font-size: 12px;
          line-height: 15px;
          text-decoration: underline;
          color: #000;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .copy {
      font-size: 12px;
    }
  }

  .akerun_lp_template {
    font-family: "Noto Sans Japanese",sans-serif;
    font-weight: 300;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    * {
      box-sizing: border-box;
      color: #717071;
    }

    &.style, .style, &.no-disp, .no-disp, .sp-only {
      display: none;
    }

    .pc-only {
      display: block;
    }

    .fadein {
      transition: all .5s ease;
      opacity: 0;
      &.-up {
        transform: translate(0, 10px);
      }
      &.-show {
        transform: none;
        opacity: 1;
      }
    }

    &.section {
      margin-top: 80px;
    }
    &.container, .container {
      width: 980px;
      min-width: 980px;
      margin-right: auto;
      margin-left: auto;
    }

    .link-button {
      display: inline-block;
      padding: 16px 40px;
      font-family: "NotoSansJp-Light";
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      transition: 0.3s;
      text-decoration: none;
      letter-spacing: 3px;
      color: #1594cf;
      border-radius: 100px;
      background: white;
      &:hover {
        color: white;
        background: #222;
      }
    }

    .sub-heading {
      margin-bottom: 60px;
      font-family: "NotoSansJp-Light";
      font-size: 26px;
      letter-spacing: 2px;
      color: #1580a8;

      span {
        font-size: 29px;
        line-height: 2.4;
        color: #1580a8;
      }
    }

    &.hero {
      overflow: hidden;
      margin-top: 0;
      .hero-main {
        background-color: #ebecee;
        background-repeat: no-repeat;
        background-position: top center;
      }
    }

    &.footer {
      padding: 35px 30px;
      padding-right: 30px;
      padding-left: 30px;
      .footer-link {
        display: inline-block;
        float: left;
      }
      .copy {
        display: inline-block;
        float: right;
      }
    }

    &.form {
      .form-address {
        margin-bottom: 20px;
        font-size: 1.4rem;
      }

      .form-lead{
        font-weight: 400;
        text-align: center;

        &__text{
          font-size: 20px;
          text-decoration: underline;
        }
        &__bnr{
          img{
            width: 298px;
            max-width: 652px;
            margin: 15px 20px 30px;
          }
        }
        &__notice{
          font-size: 11px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px){
  .p-landing{
    .br-sp {
      display: none;
    }
    .br-sp--add {
      display: block;
    }
    .hidden-xs {
      display: none;
    }
    img {
      max-width: 100%;
    }

    .header {
      top: -60px;
      height: 60px;

      &-type02{
        padding: 10px 0;
        .header-btn {
          padding-left: 30px;
          font-size: 13px;
          color: #777;
        }
        .header-btn__icon {
          width: 20px;
          height: 20px;
          margin-top: -10px;
        }
      }
    }
    .header-logo {
      top: 8px;
      left: 15px;
      width: 23.7%;

      &.color{
        top: 8px;
        left: 15px;
        width: 23.7%;
      }

    }
    .header-btn {
      position: relative;
      top: 3px;
      width: 50%;
      max-width: 180px;
      margin-right: 15px;
      font-size: 12px;
      line-height: 17px;
      text-align: left;
    }
    .akerun_lp_template {
      .sp-only {
        display: block;
      }
      .pc-only {
        display: none;
        display: none;
      }

      &.container, .container {
        min-width: auto;
      }

      &.container {
        width: 92%;
        margin-right: 4%;
        margin-left: 4%;
      }

      .link-button {
        padding: 14px 30px;
        font-size: 5vw;
      }

      .heading-bordered {
        margin-bottom: 40px;
        font-size: 22px;
      }

      .sub-heading {
        font-size: 22px;
        text-align: left;
      }

      &.footer {
        margin-top: 40px;

        .footer-link {
          float: none;
          margin-bottom: 20px;
        }
        .copy {
          float: none;
        }
      }

      &.form{
        .form-lead {
          width: 86.6%;
          margin: 0 auto;
        }
      }
    }
  }
}