.sec-404 {
  background-color: $pale-grey;
}

.notFound {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  &_panel {
    width: 500px;
    max-width: 100%;
    text-align: center;
  }
  &_img {
    width: 200px;
    max-width: percentage(256/335);
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  &_msg {
    margin: 20px 0 40px;
    font-size: 2.8rem;
    line-height: 1.5;
    text-align: center;
    @include mq(md) {
      font-size: 1.8rem;
    }
  }
  .btn {
    a {
      width: 295px;
    }
  }
}