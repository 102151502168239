.p-estimate {
  .stackable{
    >.row{
      &:first-child {
        margin-bottom: 40px;
        .ql-editor {
          @include mq(md) {
            line-height: 1.8;
            text-align: left;
          }
          p {
            margin: 0;
          }
        }
      }
    }
  }
}

// 設置ドア環境
[data-vv-name="door"] {
  margin-top: 10px;
  &.fields{
    display: flex;
    flex-wrap: wrap;
    .field{
      width: 25%;
      margin-bottom: 20px;
      @include mq(md) {
        width: 50%;
      }
    }
  }
}

.sec-estimateLink {
  .btnSet {
    .btn {
      width: 290px;
      @include mq(md) {
        width: auto;
      }
      a {
      }
    }
  }
}
