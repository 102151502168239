@charset "utf-8";

.l-footer {
  padding: 40px 0 52px 200px;
  line-height: 1.5;
  color: #fff;
  background-color: $navy;
  @include mq(lg) {
    padding-left: 0;
  }
  @include mq(md) {
    padding: 40px 0 60px;
  }
  .container {
  }
  a {
    text-decoration: none;
    color: #fff;
    @include mq(min-md) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.ftHead {
  display: flex;
  justify-content: space-between;
  + .ftBody {
    margin-top: 40px;
  }
  p {
    margin: 0;
  }
  &_home {
    width: 40px;
    height: 40px;
    a {
      position: relative;
      display: block;
      height: 100%;
      transition: 0.2s;
      text-indent: -999em;
      border: 1px solid #fff;
      border-radius: 50%;
      @include mq(min-md) {
        &:hover {
          background-color: #fff;
          &::after {
            opacity: 0;
          }
        }
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 14px;
        height: 14px;
        margin: auto;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
      &::before {
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/icon-home_hover@2x.png);
      }
      &::after {
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/icon-home@2x.png);
      }
    }
  }
  &_login {
    height: 40px;
    a {
      position: relative;
      display: block;
      display: flex;
      width: 160px;
      height: 100%;
      transition: 0.2s;
      text-align: center;
      border-radius: 20px;
      background-color: #535869;
      align-items: center;
      justify-content: center;
      &::after {
        content: "";
        display: inline-block;
        width: 1.2rem;
        height: 1em;
        margin-left: 0.3em;
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/icon-blank_w@2x.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
      &:hover {
        text-decoration: none;
        color: $navy;
        background-color: darken(#535869,7%);
        background-color: #fff;
        &::after {
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/icon-blank_bk@2x.png);
        }
      }
    }
  }
}

.ftBody {
  $this: &;
  display: flex;
  justify-content: space-between;
  @include mq(md) {
    flex-direction: column;
    border-bottom: 1px solid $grey-blue;
  }
  + .ftInfo {
    margin-top: 40px;
  }
  &_col {
    & + & {
      // margin-left: 5%;
    }
  }
  &_ttl {
    margin-bottom: 0em;
    font-weight: bold;
    @include mq(md) {
      position: relative;
      border-top: 1px solid $grey-blue;
    }
    a {
      @include mq(md) {
        display: flex;
        height: 55px;
        padding: 0.2em percentage(10.5/335);
        align-items: center;
      }
      &[href=""] {
        @include mq(min-md) {
          pointer-events: none;
        }
      }
      &[target="_blank"], &[target="_new"] {
        &::after {
          content: "";
          display: inline-block;
          width: 1em;
          height: 1em;
          margin-left: 0.5em;
          background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/icon-blank_w@2x.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
      }
    }
    &.-parent {
      @include mq(md) {
        position: relative;
        cursor: pointer;
      }
      .arw {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        text-indent: -999em;
        @include mq(md) {
          display: block;
          // background-color: rgba(#fff,0.2);
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 10px;
          bottom: 0;
          display: block;
          width: 7px;
          height: 7px;
          margin: auto;
          transform: rotate(45deg);
          border-width: 2px;
          border-style: none solid solid none;
          border-color: #fff;
        }
      }
      &.-active,
      &.current {
        .arw {
          &::before {
            transform: rotate(225deg);
          }
        }
      }
    }
    + #{$this}_ttl {
      margin-top: 3em;
      @include mq(md) {
        margin-top: 0;
      }
    }
    + #{$this}_list {
      margin-top: 0;
    }
  }
  &_list {
    margin-bottom: 0;
    padding-left: 0;
    font-size: 1.2rem;
    list-style: none;
    @include mq(min-md) {
      display: block !important;
    }
    @include mq(md) {
      display: none;
      margin: 0 percentage(10/335);
      // padding: 25px 0;
      // background-color: #3c5058;
    }
    + #{$this}_ttl {
      margin-top: 3em;
      @include mq(md) {
        margin-top: 0;
      }
    }
  }
  &_item {
    margin: 0.65em 0;
    @include mq(md) {
      margin: 0;
    }
    a {
      @include mq(md) {
        display: flex;
        min-height: 55px;
        padding: 5px percentage(10/315);
        font-size: 1.4rem;
        align-items: center;
        border-top: 1px dashed rgba($grey-blue,0.5);
      }
    }
    &.-sp {
      a {
        font-weight: bold;
      }
    }
    &.-top {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
}

.ftInfo {
  $this: &;
  display: flex;
  align-items: flex-end;
  @include mq(md) {
    display: block;
  }
  &_ttl {
    font-size: 1.4rem;
    font-weight: bold;
  }
  &_app {
    margin-right: 60px;
    @include mq(md) {
      margin-right: 0;
    }
    &List {
      display: flex;
      padding-left: 0;
      list-style: none;
      justify-content: space-between;
      @include mq(md) {
        justify-content: flex-start;
      }
      &:last-child {
        margin: -15px -15px;
        @include mq(md) {
          margin: -15px -10px;
        }
      }
    }
    &Item {
      padding: 15px 15px;
      @include mq(md) {
        padding: 15px 10px;
      }
      a {
        display: block;
      }
      &.-apple {
        a {
          vertical-align: bottom;
        }
      }
      &.-google {
        a {
          margin: -10px;
          img {
            width: 155px;
          }
        }
      }
    }
  }
  &_other {
    display: flex;
    min-height: 40px;
    margin-left: auto;
    font-size: 1.2rem;
    justify-content: space-between;
    align-items: center;
    @include mq(md) {
      margin: 60px 0 40px;
    }
    &List {
      margin: 0;
      padding-left: 0;
      list-style: none;
      @include mq(md) {
        flex: auto;
      }
      & + & {
        margin-left: 30px;
      }
      &:first-child {
        @include mq(md) {
          #{$this}_otherItem {
            white-space: nowrap;
          }
        }
      }
    }
    &Item {
      display: block;
      margin: 0em 0 0;
      & + & {
        @include mq(md) {
          margin-top: 2.6em;
        }
      }
      a {
        &[target="_blank"], &[target="_new"] {
          &::after {
            content: "";
            display: inline-block;
            width: 1em;
            height: 1em;
            margin-left: 0.5em;
            background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/icon-blank_w@2x.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
          }
        }
      }
    }
  }
  &_pagetop {
    margin-left: 30px;
    @include mq(md) {
      margin-left: 0;
    }
    a {
      position: relative;
      display: block;
      width: 40px;
      height: 40px;
      text-indent: -999em;
      border: 1px solid #fff;
      border-radius: 50%;
      @include mq(md) {
        margin: 0 auto;
      }
      @include mq(min-md) {
        &:hover {
          &::before {
            bottom: 10px;
          }
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 14px;
        height: 14px;
        margin: auto;
        transition: 0.3s;
        background-image: url(/static/dist/wp-content/themes/akerun/_assets/images/common/btn-pagetop@2x.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
  }
}

.ftFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  @include mq(md) {
    display: block;
    margin-top: 40px;
    text-align: center;
  }
  &_logo {
    margin: 0 20px 0 0;
    a {
      display: inline-block;
      width: 151px;
    }
  }
  &_cr {
    margin: 0;
    font-size: 1.2rem;
    @include mq(md) {
      margin-top: 20px;
    }
  }
}